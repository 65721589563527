<template>
	<header-block title="Keep Connected"></header-block>
	<CustomerLoadWait :customer="bankInfo" />
	
		<div v-if="bankInfo && relinkNeeded && attentionNeeded.length == 0 && !finished" class="simplePage">
			<h1>Refresh Account Permissions</h1>
			<v-icon class="smallHeroImg" icon="mdi-bank" />
			<p>To continue providing you with up-to-date information, we need you to refresh the permissions for your connected bank accounts.</p>
			<div class="left">
				<AccountsWidget title="Pending Refresh" :accounts="accounts" :formatter="formatter" :hideSeeAll="true" :hideBalance="true" :unique="true"/>
			</div>
			<div class="mt-4">
				<MGButton v-if="!busy" text="Begin" @click="relinkAccounts"/>
				<v-progress-circular v-if="busy" indeterminate size="30" width="3" />
				<p class="mt-3 small">We will try to refresh automatically. However, some banks need you to grant permission.</p>
			</div>
		</div>
	
		<div v-if="bankInfo && attentionNeeded.length > 0 && !finished" class="simplePage">
			<h1>Refresh Account Permissions</h1>
			<v-icon class="smallHeroImg" icon="mdi-bank" />
			<p>The following accounts need your explicit permission.  For each bank, you will be redirected to your banking app to allow this.</p>
			<div class="left">
				<AccountsWidget title="Requires Attention" :accounts="accounts" :formatter="formatter" :hideSeeAll="true" :hideBalance="true" :unique="true"/>
			</div>
			<div class="mt-4">
				<MGButton v-if="!busy" :text="attentionPerformed == 0 ? 'Start' : 'Next'" @click="explicitBegin"/>
				<v-progress-circular v-if="busy" indeterminate size="30" width="3" />
			</div>
		</div>
	
		<div v-if="bankInfo && !relinkNeeded" class="simplePage">
			<h1>All your accounts are working correctly!</h1>
			<v-icon class="smallHeroImg" icon="mdi-bank-check" />
			<p class="mt-4">Nothing to see here.  Go grab a coffee!</p>
			<MGButton text="Finish" @click="$router.back()"/>
		</div>
	
		<div v-if="finished" class="simplePage">
			<h1>All Done!</h1>
			<v-icon class="smallHeroImg" icon="mdi-bank-check" />
			<p class="mt-4">We've successfully refreshed all your account connections. You can now view your latest information.</p>
			<MGButton text="Finish" @click="this._clearBankingInfo(); $router.back()"/>
		</div>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';
import AccountsWidget from './elements/AccountsWidget.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, MGButton, AccountsWidget},
	data() {
		return {
			relinkNeeded: false,
			accounts: [],
			attentionNeeded: [],
			attentionPerformed: 0,
			busy: false,
			finished: false,
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				this._clearBankingInfo();
				this._getBankingInfo(() => {
					this.bankInfo.accounts.forEach(a => {
						if (a.consentExpired) {
							this.relinkNeeded = true;
						}
						this.accounts.push(a);
					});
				});
			});
		},
		relinkAccounts: function() {
			this.busy = true;
			this._reLinkAllBankAccounts((res) => {
				console.log("ReLinking accounts", res);
				this.accounts = [];
				var allOK = true;
				res.results.forEach(r => {
					if (r.success && !r.completed) {
						allOK = false;
						console.log("Adding to todo list", r);
						var account = this.bankInfo.accounts.find(a => a.bankDisplayName == r.bankDisplayName);
						if (account != null) {
							console.log("Found account", account);
							this.accounts.push(account);
							this.attentionNeeded.push(r);
						}
					}
				});
				this._clearBankingInfo();
				console.log("AllOK", allOK);
				if (allOK) {
					console.log("All accounts are OK");
					this.finished = true;
				}
				this.busy = false;
			});
		},
		explicitBegin: function() {
			console.log("Explicit begin", this.attentionNeeded);
			this.busy = true;
			var todo = this.attentionNeeded[0];
			window.location = todo.actionUrl;
			this.attentionPerformed++;
			setTimeout(() => {
				this.attentionNeeded.shift();
				this.busy = false;
				if (this.attentionNeeded.length == 0) {
					this.finished = true;
				}
			}, 2000);
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.smallHeroImg {
	font-size: 70px;
	margin-top: 0px;
}

</style>
