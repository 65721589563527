<template>
	<header-block title="Moves" border="1"></header-block>
	<CustomerLoadWait :customer="pendingMoves" />
	
	<transition>
		<div v-if="pendingMoves" class="content">

			<h1>My Moves</h1>

			<v-row class="mt-0">
				<v-col>
					<MovesWidget v-if="pendingMoves" :moves="pendingMoves" :lite="false" />
				</v-col>
			</v-row>

			<div class="anchor-bottom">
				<BarArrowButton title="Recent moves" :large="true" href="/#/completedmoves"></BarArrowButton>
			</div>

		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import BarArrowButton from './elements/BarArrowButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MovesWidget from './elements/MovesWidget.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, MovesWidget, BarArrowButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getPendingMoves();
		},
		action: function(){
			window.location.href = this.planTask.actionUrl;
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h1 {
	font-size: 24px;
	margin-bottom: 0px;
}

h2 {
	font-size: 16px;
	margin-bottom: 10px;
}

p {
	font-size: 15px;
}

.content {
	padding-top: 30px;
}

.actioned {
	padding: 20px;
	border-radius: 16px;
	background: linear-gradient(64.16deg, rgba(119, 164, 167, 0.2) 18.08%, rgba(170, 206, 205, 0.2) 85.4%);
	.completedCheck {
		float: right;
		margin-top: -16px;
		margin-right: -7px;
	}
}

.mg-button {
	width: 100% !important;
}


</style>
