<template>
	<header-block title="Calculators"></header-block>
	<CustomerLoadWait :customer="marketplace && employer" />
	<transition>
		<div class="simplePage" v-if="products && employer">

			<div>
				<div :class="'hubItemWrapper'" v-for="product in calculators" :key="product.id">
					<CategoryButton :image="product.thumbnail" :title="product.name" :href="product.websiteUrl" barColor="#e9e7f2" />	
				</div>
			</div>
			<div style="clear:both"></div>
			
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import CategoryButton from './elements/CategoryButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, CategoryButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				this._getEmployer(this.customer.employerID);
				this._getProducts();


			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.ass {
	barColor:#e9e7f2
}

</style>
