<template>
	<div class="container" v-if="!planPage || planAmount">
		<v-row class="subCategory" @click="handleNav()">
			<v-col cols="2" class="iconCol">
				<img v-if="!income && iconSrc" :src="iconSrc" width="30" height="30" class="icon" :style="{ background: bgColour }"/>
				<div v-else class="icon-placeholder" :style="{ background: bgColour }"></div>
			</v-col>
			<v-col cols="4" :class="!planAmount ? 'noPlanCol' : ''">
				<span class="subCategoryHeadline">{{ group.name }}</span><br/>
				<span v-if="!income && planAmount" class="planSpan">Plan: {{ formatter.format(planAmount) }}</span>
				<span v-if="income">Income</span> 
			</v-col>
			<v-col cols="6" :class="'right ' + (!planAmount ? 'noPlanCol' : '')">
				<span v-if="income" class="moneyIn subCategoryHeadline">{{ formatter.format(Math.abs(group.amountIn)) }}</span>
				<span v-if="!income" class="subCategoryHeadline">{{ formatter.format(Math.round(Math.abs(group.amountOut))) }}</span><br/>
				<span v-if="!income && planAmount">{{ planRemainingAmount }}</span> 
			</v-col>
		</v-row>
		<v-row>
			<v-col class="progressCol">
				<v-progress-linear v-if="!income && planAmount"
					:height="progressHeight || 10"
					:model-value="animatedProgress"
					color="primary"
					rounded
				></v-progress-linear>
			</v-col>
		</v-row>
	</div>
	
</template>

<script>

export default {
	name: "SpendingAnalysisSubCategory",
	props: ['formatter', 'group', 'income', 'plans', 'bgColour', 'txtColour', 'isCollapsed', 'planPage', 'progressHeight'],
	emit: ['uncatagorisedTransactionsClicked', 'planClicked', 'categoryClicked'],
	data() {
		return{
			animatedProgress: 0,
		};
	},
	computed: {
		iconSrc() {
			if (!this.group.transactionClassification.iconPath) return null;
			return require(`@/assets/spendCategories/${ this.group.transactionClassification.iconPath.charAt(0).toUpperCase() + this.group.transactionClassification.iconPath.substring(1) }`);
		},
		planAmount() {
			if (!this.plans) return null;
			const plan = this.plans.plans.find(plan => plan.classification.id === this.group.transactionClassification.id);
			if (!plan) return null;
			return plan.plan.amount;
		},
		planRemainingAmount() {
			var label = "Remaining: ";
			const amount = this.planAmount - Math.abs(this.group.amountOut);
			if (amount < 0) label = "Overspent: ";
			return label + this.formatter.format(Math.round(Math.abs(this.planAmount - Math.abs(this.group.amountOut))));
		},
		planProgressPercent() {
			return Math.min(100, Math.max(0, Math.round((Math.abs(this.group.amountOut) / this.planAmount) * 100)));
		}
	},
	watch: {
		isCollapsed(isCollapsed) {
			if (!isCollapsed) this.onLoad();
		},
		planProgressPercent(newVal) {
			this.animateProgress(newVal);
		}
    },
	methods: {
		onLoad() {
			this.animateProgress(this.planProgressPercent);
		},
		animateProgress(targetValue) {
			this.animatedProgress = 0;
			const increment = targetValue / 50;
			const interval = setInterval(() => {
				if (this.animatedProgress < targetValue) {
					this.animatedProgress += increment;
				} else {
					this.animatedProgress = targetValue;
					clearInterval(interval);
				}
			}, 10);
		},
		handleNav() {
			if (this.planPage) {
				this.$emit('planClicked', this.group.transactionClassification);
			}
			else {
				if (this.group.transactionClassification.key === 'Uncategorized') {
					this.$emit('uncatagorisedTransactionsClicked', this.group.transactionClassification);
				}
				else {
					this.$emit('categoryClicked', this.group.transactionClassification);
				}
			}
		}
	},
	mounted: function() { this.onLoad(); }
}

</script>


<style scoped>

.container {
	background-color: white;
	margin-left: -12px;
	margin-right: -12px;
	padding-left: 12px;
	padding-right: 12px;
	padding-bottom: 12px;
}

.subCategoryHeadline {
	font-size: 13px;
	margin-top:2px;
	margin-bottom: -35px;
	white-space: nowrap;
}

.subCategory {
	padding-top: 18px;
	font-size: 14px;

	.v-col{
		font-size: 12px !important;
	}
	
}

.subCategoryPlan {
	font-size: 12px;
	margin-bottom: -25px;
}

.noPlanCol {
	padding-top: 22px;
}

.planSpan{
	white-space: nowrap;
}

.iconCol {
	padding-top: 19px;
}

.progressCol {
	padding-top: 0px;
	padding-bottom: 0px;
}

.icon {
	border-radius: 50%;
}

.icon-placeholder {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #B4D8D7;
    display: inline-block;
}

:deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.moneyIn {
	background-color: #23af6433;
	color: #23AF64;
	padding: 3px;
	border-radius: 3px;
	margin-top: 15px;
}

</style>