<template>
	<header-block title="" border="0"></header-block>
	<CustomerLoadWait :customer="planTask" />
	<div class="header" v-if="planTask">
		<img :src="planTask.icon ?? require('@/assets/round1.png')" />
	</div>
	<transition>
		<div v-if="planTask" class="content">

			<h1>{{planTask.title}}</h1>
			<h2>{{planTask.tagLine}}</h2>
			<p>
				{{planTask.description}}
			</p>

			<div class="anchor-bottom">
				<div class="actioned">
					<v-checkbox class="completedCheck" @change="setCompleted" v-model="planTask.completed" />
					<h2>{{ planTask.completedTitle }}</h2>
					<p>
						{{ planTask.completedDescription }}
					</p>
				</div>
				<v-btn class="mg-button fullWidth" @click="action">{{ planTask.actionTitle }}</v-btn>
			</div>

		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getPlanTask(this.$route.params.id);
		},
		action: function(){
			window.location.href = this.planTask.actionUrl;
		},
		setCompleted: function(){
			this._setPlanTaskCompleted(this.planTask.id, this.planTask.completed, () => {
				//this._getPlanTask(this.planTask.id);
			});
		},
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h1 {
	font-size: 24px;
	margin-bottom: 20px;
	font-weight: normal;
}

h2 {
	font-size: 16px;
	margin-bottom: 10px;
}

p {
	font-size: 15px;
}

.header {
	background-color: var(--background-color-alt);
	height: 160px;
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -18px;
	z---index: 200 !important;
	text-align: center;
	position: relative;
	img {
		height: 120px;
	}
}

.content {
	padding-top: 30px;
}

.actioned {
	padding: 20px;
	border-radius: 16px;
	background: linear-gradient(64.16deg, rgba(119, 164, 167, 0.2) 18.08%, rgba(170, 206, 205, 0.2) 85.4%);
	.completedCheck {
		float: right;
		margin-top: -16px;
		margin-right: -7px;
	}
}

.mg-button {
	width: 100% !important;
}


</style>
