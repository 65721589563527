<template>

	<v-row class="menuBar">
		<v-col class="">
			<div @click="$router.replace('/aiop')">
				<img :src="require('@/assets/menu/Money' + (this.$route.meta.menuItem=='money' ? 'On' : '') + '.svg')"/>
			</div>
		</v-col>
		<v-col class="">
			<div @click="$router.replace('/guides')">
				<img :src="require('@/assets/menu/Guide' + (this.$route.meta.menuItem=='guide' ? 'On' : '') + '.svg')"/>
			</div>
		</v-col>
		<v-col class="">
			<div @click="$router.replace('/')">
				<img :src="require('@/assets/menu/Home' + (this.$route.meta.menuItem=='home' ? 'On' : '') + '.svg')"/>
			</div>
		</v-col>
		<v-col class="">
			<div @click="$router.replace('/activity')">
				<img :src="require('@//assets/menu/Activity' + (this.$route.meta.menuItem=='activity' ? 'On' : '') + '.svg')"/>
			</div>
		</v-col>
		<v-col class="">
			<div @click="$router.replace('/you')">
				<img :src="require('@/assets/menu/You' + (this.$route.meta.menuItem=='you' ? 'On' : '') + '.svg')"/>
			</div>
		</v-col>
	</v-row>

</template>

<script>
export default {
	name: "MenuBar",
	props: [''],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.menuBar {
	height: 78px;
	position: fixed;
	bottom: 0px;
	left: 0px;
	width: 100%;
	margin-left: 0px;
	margin-right: 0px;
	background-color: var(--background-color);
	z-index: 2000;
	text-align: center;
	.v-col{
		padding: 0px;
		padding-top: 12px;
	}
}

</style>