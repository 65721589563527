<template>
	<header-block title="Search retail cards" :border="image ? 0 : null" :bgcolor="image ? '#00000000' : 'white'" :color="image ? 'white' : null"></header-block>
	<CustomerLoadWait :customer="availableVouchers && vouchers" :cardBlocks="!filter" />

	<transition>
		<div class="mt-8" v-if="availableVouchers">
			<VoucherList :showAll="true" :vouchers="availableVouchers" :search="true" />
		</div>
	</transition>

	<div style="clear:both; "></div>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import VoucherList from './elements/VoucherList.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, VoucherList},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getAvailableVouchers();
			this._getCustomerVouchers();
		},
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.header {
	padding-top: 138px;
	background-size: cover;
	background-position: bottom;
	margin: -20px -30px 0px -30px;
	margin-top: -138px;
	color: white;
	p--adding-bottom: 200px;
	height: 300px;
	padding: 30px;
	padding-top: 200px;
	color: white;
	h1{
		font-size: 28px;
		margin-bottom: 15px;
	}
	margin-bottom: 40px;
}
.title-col {
	padding-top: 0px;
	padding-bottom: 20px;
}
.chip-col {
	text-align: right;
}

</style>
