<template>
	
		<HeaderBlock bgcolor="#00000000" border="0" />
		<CustomerLoadWait :customer="customer" />
	
		<div class="simplePage" v-if="customer">
			<h1>Money Guided works with your bank accounts</h1>
			<img class="smallHeroImg" src="../assets/Frame.png"/>
			<p>Linking your bank accounts and credit cards to Money Guided gives you a clear and accurate picture of your financial situation without the hassle of manually tracking transactions.</p>
			<div @click="$refs.drawer.openDrawer()">Understand Our Security</div>
			<div class="anchor-bottom">
				<a :href="this._getOpenBankingLinkUrl(this.customer.id)" target="_blank"><MGButton @click="continueNext" text="Let's do it" alt="true" :small="false" /></a>
				<div class="mt-2" @click="continueNext">Skip for now</div>
			</div>
		</div>
		
		<DrawerPanel v-if="customer" ref="drawer" closedHeight="0px" :openBackgroundColor="'white'" bottom="0px" openHeight="80%" title="Our Security">
			
			<div class="simplePage" style="color: black; overflow-y: scroll;">
				<v-icon icon="mdi-bank-check" class="smallHeroImg"/>
				<p>Money Guided's open banking empowers you with seamless financial management, leveraging bank-grade security protocols to ensure your data's utmost protection. With rigorous regulatory oversight, encrypted connections, and strong customer authentication, your financial information is safeguarded, giving you the confidence to enjoy the benefits of streamlined, automated financial insights.</p>
				<a :href="this._getOpenBankingLinkUrl(this.customer.id)" target="_blank"><MGButton @click="continueNext" text="Let's do it" alt="true" :small="false" /></a>
				<div class="mt-2" @click="continueNext">Skip for now</div>
			</div>
		</DrawerPanel>
	
</template>

<script>
import Services from '../mixins/Services';
import DrawerPanel from './elements/DrawerPanel.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, MGButton, DrawerPanel, CustomerLoadWait},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
		},
		continueNext: function() {
			this.$router.replace({path: '/'});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
.simplePage {
	color: white;
}

.smallHeroImg {
	width: 80px;
	font-size: 100px;
	color: var(--text-color)
}

</style>
