<template>
	<header-block title="" border="0" bgcolor="transparent"></header-block>
	
	<div class="simplePage">
		<h1>You're in!<br/>Welcome to Money Guided</h1>

		<img class="heroImg" src="../assets/verified.png" />

		<p>Before we go any further, let's get to know you.</p>
		<div class="anchor-bottom">
			<MGButton text="Continue" @click="$router.push({path: '/personaldetails'})" :arrow="true"/>
		</div>
		
	</div>

</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {MGButton, HeaderBlock},
	data() {
		return {
			employer: null,
			interval: null
		}
	},
	
	methods: {
		onLoad: async function() {
			
		}
	},
	mounted: function () {
		this.onLoad();
	}
}
</script>

<style scoped>



</style>
