<template>
	<BarArrowButton :large="true" :title="title" :href="href" :hideArrow="hideArrow" :style="alt ? 'background-color: var(--background-color-alt)' : ''">
		<div class="barArrowButtonSlotContent">
			<v-row>
				<v-col v-if="iconPath" cols="2" class="iconCol">
					<img :src="require('@/assets/transactions/' + iconPath.toLowerCase().replace('.svg', '.png'))" class="icon" :style="{ background: bgColour }"/>
				</v-col>
				<v-col>
					<span>{{ planLabel }}</span>
				</v-col>
			</v-row>
			<v-row  v-if="plan && plan > 0">
				<v-col class="progressCol">
					<v-progress-linear
						:class="plan > spent ? 'progress' : 'progressExceeded'"
						height="7"
						:model-value="animatedProgress"
						color="primary"
						rounded 
					></v-progress-linear>
				</v-col>
			</v-row>
		</div>
	</BarArrowButton>
</template>

<script>
import BarArrowButton from './BarArrowButton.vue';

export default {
	name: "PlanInfoPanel",
	props: ['title','href','plan', 'spent', 'formatter', 'hideArrow', 'iconPath', 'bgColour', 'alt' ],
	components: {BarArrowButton},
	data() {
		return{
			animatedProgress: 0,
		};
	},
	computed: {
		planProgressPercent() {
			return Math.min(100, Math.max(0, Math.round((this.spent / this.plan) * 100)));
		},
		planLabel() {
			if (!this.plan || this.plan <= 0) return "Set up your first spending plan today!";
			var difference = this.plan - this.spent;
			var label = " left";
			if (difference < 0)
				label = " over";
			var text = "Plan: " + this.formatter.format(this.plan) + " | " + this.formatter.format(Math.round(Math.abs(difference))) + label;
			return text;
		}
	},
	methods: {
		onLoad() {
			this.animateProgress(this.planProgressPercent);
		},
		animateProgress(targetValue) {
			this.animatedProgress = 0;
			const increment = targetValue / 50;
			const interval = setInterval(() => {
				if (this.animatedProgress < targetValue) {
					this.animatedProgress += increment;
				} else {
					this.animatedProgress = targetValue;
					clearInterval(interval);
				}
			}, 10);
		},
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.progress :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.progressExceeded :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #FFB6B6 3.1%, #9EA2D0 95.08%);
}

.progressCol {
	padding-top: 0px;
	padding-bottom: 0px;
}

.barArrowButtonSlotContent {
	padding: 20px;
	padding-top: 15px;
}

.icon {
	border-radius: 50%;
	width: 30px;
	height: 30px;
}

</style>