<template>
	<header-block :title="investmentHeader" bgcolor="white" border="0"></header-block>
	<CustomerLoadWait :customer="customer && policyTypes && investmentProviders"/>
	<div class="progress-container">
		<v-progress-linear :model-value="progressValue" rounded></v-progress-linear>
	</div>
	<transition>
		<div v-if="customer && policyTypes && investmentProviders && (investmentAccount || !this.$route.params.id)">
			<!-- {{ sectionsAvailableCount(sections[0].fields[0].value) }} -->
			<p class="centered small">Don't forget you can whatsapp us your documents and we will add you investments for you!</p>
			<div class="personalDetails">
				<InputSections ref="inputSections" :sections="sections" theme="light"/>
				<div v-if="validationText" class="well">
					<p>{{ validationText }}</p>
				</div>

				<div class="anchor-bottom submitControls">
					<v-row>
						<v-col cols="5" v-if="currentPageNumber > 0"><MGButton small="true" :outline="true" @click="_inputControl_GoBack()" text="Back" /></v-col>
						<v-col><MGButton :small="currentPageNumber > 0" :alt="true" text="Next" :arrow="true" @click="_inputControl_GoNext(addInvestment, _preValidate)"/></v-col>
					</v-row>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Helpers from '../mixins/Helpers';
import Services from '../mixins/Services';
import AddInvestmentPageSections from './AddInvestmentPageSections';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services, Helpers, AddInvestmentPageSections],
	components: {HeaderBlock, InputSections, MGButton, CustomerLoadWait},
	data() {
		return {
			validationText: null,
			sectionNo: 0,
			currentPageNumber: 0,
			enableWhatsApp: false,
			allowBack: true,
		}
	},
	computed: {
		investmentHeader() {
			var verb = this.$route.params.id ? 'Edit' : 'Add';
			if (!this.sections[0].fields[0].value) {
				return verb + " investment details";
			}
			var title = verb + " " + this.sections[0].fields[0].value + " Investment details"
			return title;
		},		
		progressValue() {
			var insuranceType = this._findFieldValue('Type');
			var totalPages = this.sectionsAvailableCount(insuranceType);
			if (totalPages === 0) return 0;
			return ((this.currentPageNumber + 1) / totalPages) * 100;
		},
	},
	methods: {
		onLoad: async function() {
			if (this.$route.params.id) {
				this._getInvestmentAccount(this.$route.params.id, (policy) => {
					this.populateFields(policy);
				});
			}
			this._getCustomer();
			this._getInvestmentProviders((res) => {
				var providerField = this._findField('Provider');
				providerField.items = res.map(provider => {
					return provider.name.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
			this._getInvestmentTypes((res) => {
				res = res.filter(item => item.name === "Investment");
				var typeField = this._findField('Type');
				typeField.items = res.map(investmentType => {
					return investmentType.subType.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
		},
		populateFields(policy) {
			const fieldMappings = {
				'Type':  policy.accountType.subType,
				'Provider': policy.provider.name,
				'Account number': policy.accountNumber,
				'Allowance used this year': policy.taxYearAllowanceUsed,
				'Estimated Value': policy.balance,
				'Ownership': policy.jointStatus === 2 ? 'Joint' : 'Sole',
			};

			this._loadFieldsFromObject(fieldMappings);
		},
		addInvestment() {
			const accountType = {
				name: "Investment",
				subType: this._findFieldValue('Type') || null,
			};
			const provider = {
				name: this._findFieldValue('Provider') || null,
			};

			const investment = ({
				accountType: accountType,
				accountNumber: this._findFieldValue('Account number') || null,
				taxYearAllowanceUsed: this._findFieldValue('Allowance used this year') || 0,
				balance: this._findFieldValue('Estimated Value') || null,
				provider: provider,
				jointStatus: this._findFieldValue('Ownership') === 'Joint' ? 2 : this._findFieldValue('Ownership') === 'Sole' ? 1 : null,
			});

			this._removeNullProperties(investment);

			if (this.$route.params.id) {
				investment.id = this.$route.params.id;
			}

			const handleResponse = (res) => {
				if (res) {
					if (this.$route.params.id) {
						this._getInvestmentAccount(this.$route.params.id, /*onSuccess*/ null, /*refreshCache*/ true);
					}
					this._getInvestmentInfo(/*onSuccess*/ null, /*refreshCache*/ true);
					this.$router.push({ path: '/policysuccess', query: { type: 'investment' } });
				}
			};

			if (!this.$route.params.id) {
				this._addInvestment(investment, handleResponse);
			} else {
				this._updateInvestment(investment, handleResponse);
			}
		},
		sectionsAvailableCount(insuranceType) {
			var sectionsNoRule = this.sections.filter(section => !section.rule);
			var sectionsMatchingRule = this.sections.filter(section => section.rule && section.rule.includes(insuranceType));
			return sectionsNoRule.length + sectionsMatchingRule.length;
		},
	},
	
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>


:deep(.check .v-label) {
	color: white !important;
}

.well {
	text-align: center;
	background-color: #3328470D;
}

.submitControls {
	position: absolute;
	bottom:20px;
	width: calc(100vw - 60px);
}

.progress-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

:deep(.v-progress-linear__background) {
  background-color: var(--border-color) !important;
}
</style>
