export default {

	data() {
		return {
			sections: 
			[
				{
					fields: [
						{
							id: 'pensionType',
							validationText: 'Please choose a pension type',
							label: 'Type',
							type: 'dropdown',
							items: this.investmentTypes,
							value: null,
							required: true
						}
					]
				},
				{
					rule: 'pensionType: Personal, Defined Contribution',
					fields: [
						{
							validationText: 'Please select a provider',
							label: 'Provider',
							type: 'dropdown',
							items: this.investmentProviders,
							value: null,
							required: true
						}
					]
				},
				{
					rule: 'pensionType: Defined Contribution, Final Salary',
					fields: [
						{
							validationText: 'Please enter the employer name',
							label: 'Employer',
							type: 'text',
							value: null,
							required: true
						}
					]
				},
				{
					fields: [
						{
							validationText: 'Please enter the account number',
							label: 'Account number',
							type: 'text',
							items: this.accountNumber,
							value: null,
							required: false
						},
						{
							validationText: 'Please enter the estimated value',
							label: 'Estimated Value',
							type: 'currency',
							value: null,
							required: true
						},
						{
							validationText: 'Please enter your montly contribution',
							label: 'Personal monthly contribution',
							type: 'currency',
							value: null,
							required: true
						},
						{
							label: 'Retirement age',
							type: 'number',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'pensionType: Defined Contribution',
					fields: [
						{
							validationText: 'Please select wheter this is a current or previous employer',
							label: 'Current employer scheme',
							type: 'dropdown',
							items: ['Yes', 'No'],
							value: null,
							required: true
						}	
					]
				}
			]
		}
	},
}