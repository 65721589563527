<template>
	<header-block title="Guide to your finances"></header-block>
	<CustomerLoadWait :customer="planSection" />

	<transition>
		<div v-if="planSection">
			<h1>{{planSection.title}}</h1>
			<h2>{{ planSection.tagLine }}</h2>
			<p>
				{{ planSection.description }}
			</p>

			<div class="tasks-wrapper">
				<div v-for="task in planSection.tasks" :key="task.id" class="task-wrapper">
					<v-row class="task-row" @click="$router.push('/Guides/Sections/Tasks/' + task.id)">
						<v-col class="v-col-1 dot-col"><img :src="task.completed ? require('@/assets/green-dot.png') : require('@/assets/orange-dot.png')"/></v-col>
						<v-col>
							<v-row>
								<v-col class="titleCol">
									{{ task.title }}<br />
									<span>{{ task.completed ? 'Completed' : 'Not completed' }}</span>
								</v-col>
							</v-row>
						</v-col>
						<v-col class="v-col-1 arrow-col">
							<img class="arrow" src="../assets/arrow-right.png">
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock},
	data() {
		return {
			guideSection: {
				tasks: [ 
					{id: "1234", title: "What is your locus of control?", completed: true},
					{id: "1235", title: "Lorem ipsum dolor sit", completed: false},
					{id: "1236", title: "Lorem ipsum dolor sit", completed: true},
				]
			}
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
			this._getPlanSection(this.$route.params.id);
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h1 {
	font-weight: normal;
	line-height: 1.2;
	margin-bottom: 15px;
}

.dot-col {
	padding-top: 25px !important;
	img {
		height: 11px;
	}
}

.arrow-col {
	text-align: right;
	padding-top: 23px !important;
	img {
		height: 8px;
	}
}

.task-row {
	padding-top: 10px !important;
	padding-bottom: 20px !important;
	margin-top: 0px;
	border-top: 1px solid var(--border-color);
	span {
		font-size: 12px;
		font-weight: lighter;
	}
}

.tasks-wrapper {
	margin-top: 30px;
}

.task-wrapper:last-child .task-row {
	border-bottom: 1px solid var(--border-color);
}

.titleCol {
	font-size: 14px;
	font-weight: bold;
	span {
		font-size: 12px;
	}
}

</style>
