<template>

	<v-row class="headerBlock" :style="'height: ' + headerHeight + 'px; padding-top: ' + headerPadding + 'px; background-color: ' + (bgcolor ?? '#FBF7F4dd') + '; border-bottom: ' + (border ?? '1') + 'px solid var(--border-color);'">
		<v-col class="v-col-2 left-col">
			<div v-if="allowBack &&!escapeRoute" class="mg-circle-image-button" @click="$router.back()"><v-icon icon="mdi-arrow-left"/></div>
		</v-col>
		<v-col class="title" :style="'color: ' + (color ?? 'black')">{{title}}<slot /></v-col>
		<v-col class="v-col-2 right-col">
			<div v-if="mgChat && !escapeRoute" class="mg-chat-button"><ChatMgHeaderButton :prompt="mgChat" /></div>
			<div v-if="escapeRoute" class="mg-circle-image-button" @click="goEscape"><v-icon icon="mdi-close"/></div>
			<div v-if="edit && !del" class="mg-circle-image-button" @click="$router.push(edit)"><v-icon icon="mdi-pencil"/></div>
			<div v-if="del && !edit" class="mg-circle-image-button" @click="showDelete = true;"><v-icon icon="mdi-delete"/></div>
			<v-menu v-if="del && edit">
				<template v-slot:activator="{ props }">
					<div v-bind="props" class="mg-circle-image-button"><v-icon icon="mdi-menu"/></div>
				</template>
				<v-list>
					<v-list-item>
						<div v-bind="props" class="mg-circle-image-button" @click="$router.push(edit)"><v-icon icon="mdi-pencil"/></div>
						<div v-bind="props" class="mg-circle-image-button" @click="showDelete = true;"><v-icon icon="mdi-delete"/></div>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-col>
	</v-row>

	<v-dialog max-width="500" v-if="showDelete" v-model="showDelete">
		<v-card>
			<v-card-title>Delete</v-card-title>
			<v-card-text>Are you sure you want to delete {{title}}?</v-card-text>
			<v-card-actions>
				<v-btn @click="showDelete = false">Cancel</v-btn>
				<v-btn @click="$emit('delete')" color="red">Delete</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
import ChatMgHeaderButton from './ChatMgHeaderButton.vue';

export default {
	name: "HeaderBlock",
	props: ['title', 'color', 'bgcolor', 'border', 'edit', 'del'],
	emits: ['delete'],
	components: { ChatMgHeaderButton },
	data() {
		return{
			escapeRoute: null,
			allowBack: false,
			showDelete: false,
			mgChat: false
		}
	},
	computed: {
		safeTop() {
			return parseInt(this.$cookies.get('st') ?? 0);
		},
		headerHeight() {
			return 56 + this.safeTop;
		},
		headerPadding() {
			return 4 + this.safeTop;
		}
	},
	methods: {
		onLoad() {
			this.mgChat = this.$route.meta.aiChat;
			this.escapeRoute = this.$route.meta.escapeRoute;
			if (this.escapeRoute == null || this.escapeRoute == undefined) {
				this.escapeRoute = this.$route.query.escapeRoute;
			}
			this.allowBack = this.$route.meta.allowBack ?? true;
		},
		goEscape() {
			if (this.escapeRoute == '<') this.$router.go(-1);
			else
			this.$router.push({ path: '/' + this.escapeRoute, replace: true });
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.headerBlock {
	height:56px;
	padding-top: 4px;
	b--order-top: 1px solid red;
	border-bottom: 1px solid var(--border-color);
	margin-bottom: 20px;
	margin-left: -30px;
	margin-right: -30px;

	position:sticky;
    top:0;
    width:100vw;
    z-index:100;
	/* background-color: var(--background-color-alt); */
}

.title {
	font-size: 15px;
	text-align: center;
}

.right-col {
	text-align: right;
}

.mg-circle-image-button {
	background-color: #ffffff77;
	/* background-color: var(--text-color); */
	border-radius: 50% !important;
	height: 33px !important;
	width: 33px !important;
	padding-top: 7px !important;
	text-align: center;
	margin-top: -5px;
	font-size: 12px;
}

.mg-chat-button{
	border-radius: 50% !important;
	height: 40px !important;
	width: 40px !important;
	margin-top: -5px;
}

</style>