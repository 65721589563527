import Helpers from '../mixins/Helpers';

export default {
	mixins: [Helpers],
	data() {
		return {
			appVersion: "2.0.0",
			safeTop: 0,
			useCache: true,
			api: null,
			configUri: null,
			customer: null,
			employer: null,
			bankInfo: null,
			bankAccount: null,
			insuranceInfo: null,
			insuranceProviders: null,
			investmentProviders: null,
			mortgageInfo: null,
			investmentInfo: null,
			investmentAccount: null,
			articles: null,
			article: null,
			disabledFeatures: [],
			enabledFeatures: [],
			spendingAnalysis: null,
			spendingPlans: [],
			spendingPlansFull: [],
			availableVouchers: null,
			availableVoucher: null,
			vouchers: null,
			voucher: null,
			categories: [],
			creditTypes: [],
			creditProviders: [],
			plans: null,
			plan: null,
			planSection: null,
			planTask: null,
			products: [],
			marketplace: [],
			benefits: [],
			calculators: [],
			payment: null,
			policyTypes: [],
			savingGoals: null,
			investmentTypes: [],
			financialFitness: null,
			config: null,
			token: null,
			completedMoves: null,
			pendingMoves: null,
			customerMove: null,
			transaction: null,
			suggestedClassification: null,
			transactions: [],
			questionnaire: null,
			policy: null,
			creditAccount: null,
			formatter: new Intl.NumberFormat('en-GB', {
				style: 'currency',
				currency: 'GBP',
				trailingZeroDisplay: 'stripIfInteger',
			}),
			dateFormatPretty: new Intl.DateTimeFormat('en-GB', {
				day: '2-digit',
				month: 'short',
				year: 'numeric'
			}),
			timeOfDay: new Date().getHours() < 12 ? 'Morning' : new Date().getHours() < 18 ? 'Afternoon' : 'Evening',
			today: new Date().toLocaleDateString('en-GB', {
				day: '2-digit',
				month: 'short'
			}),
			yesterday: new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString('en-GB', {
				day: '2-digit',
				month: 'short'
			}),
			menuShown: this.$route.meta.bottomMenu,
			bottomMenuHeight: 76,
			drawerBottom: this.$route.meta.bottomMenu ? 46 : -23,
		}
	},

	created: function() {
		this.api = this.$route.meta.api;
		this.configUri = this.$route.meta.configUri;
		if (this.$route.query.st) {
			this.$cookies.set('st', this.$route.query.st, '1m');
			this.safeTop = this.$route.query.st;
		}
		if (this.$route.meta.authenticated == undefined || this.$route.meta.authenticated) {
			if (this.$route.query.u) {
				this.$cookies.set('user', this.$route.query.u, '1m');
				this.$cookies.set('pass', this.$route.query.p, '1m');
				this._login(this.$route.query.u, this.$route.query.p);
			}
			else if (this.$cookies.get('token')) {
				this.token = this.$cookies.get('token');
				this.$http.defaults.headers.common = { "Authorization": "bearer " + this.token};
			}
			else if (this.$cookies.get('user')) {
				this._login(this.$cookies.get('user'), this.$cookies.get('pass'));
			}
			else if (this.$route.query.t) {
				this.token = this.$route.query.t;
				this.$cookies.set('token', this.token, '30min');
				this.$http.defaults.headers.common = { "Authorization": "bearer " + this.token};
			}
			else{
				this.$router.push({ path: '/Login' });
			}
		}
	},

	methods: {

		_openV1: function(path, returnPath = null) {
			if (!returnPath) { returnPath = '#' }
			//console.log(returnPath, 'returnPath');
			//console.log(window.location.origin, 'origin');
			window.location.href = "/MG_NAV_" + path + "_";
			setTimeout(() => {
				window.location = window.location.origin + '/#/' + returnPath;
			}, 500);
		},

		_signalV1: function(op, val, val2, val3, returnPath = null) {
			if (!returnPath) { returnPath = '#' }
			//console.log(returnPath, 'returnPath');
			//console.log(window.location.origin, 'origin');
			window.location.href = "/MG_SIG_" + op + "_" + val + "_" + val2 + "_" + val3 + "_";
			setTimeout(() => {
				window.location = window.location.origin + '/#/' + returnPath;
			}, 500);
		},

		_GET: function(url, onSuccess, useCache = false, forceSetCache = false) {
			if (useCache) {
				var fromCache = this._getFromCache(url);
				if (fromCache) { 
					if (onSuccess) {
						onSuccess(fromCache);
					}
					return;
				}
			}
			this.$http.get(url)
				.then((result) => {
					if (useCache || forceSetCache) { this._cache(url, result.data); }
					if (onSuccess) {
						onSuccess(result.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._GET(url, onSuccess);
					});
				}
			});
		},

		_getFromCache: function(url) {
			if (!this.useCache) { return null; }
			var cache = localStorage.getItem(url);
			if (!cache) { return null; }
			cache = JSON.parse(cache);
			if (cache.expires > new Date()) {
				return cache.data;
			}
			return null;
		},

		_cache: function(url, data) {
			if (!this.useCache) { return; }
			try {
				localStorage.setItem(url, JSON.stringify({
					data: data,
					expires: new Date().setMinutes(new Date().getMinutes() + 5)
				}));
			}
			catch (e) {
				console.log(e, 'Caching error');
				localStorage.clear();
			}
		},

		_enableWhatsApp: function () {
			var request = {
				"title": "welcome_to_mg",
				"whatsApp": true,
				"whatsAppTemplate": true,
				"phoneNumber": this.customer.person.mobilePhone
			};
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Notifications", request)
				.then(() => {
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._enableWhatsApp();
					});
				}
			});
		},

		_getInterestRateType: function(interestRateType) {
			if (interestRateType == null) { return null; }
			if (interestRateType == 0) return "Fixed";
			if (interestRateType == 1) return "Standard Variable";
			if (interestRateType == 2) return "Discounted Tracker";
			return "Unknown";
		},

		_calculateMonthsRemaining: function(endDate) {
			const now = new Date();
			const end = new Date(endDate);
			const years = end.getFullYear() - now.getFullYear();
			const months = end.getMonth() - now.getMonth();
			return years * 12 + months;
		},

		_calculateDateProgressPercentage: function(start, end) {
			const now = new Date();
			const startDate = new Date(start);
			const endDate = new Date(end);
			const total = endDate - startDate;
			const elapsed = now - startDate;
			return Math.round((elapsed / total) * 100);
		},

		_savePreferences: function (onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Preferences", this.customer.preferences)
				.then((result) => {
					this.customer = result.data;
					if (onSuccess) {
						onSuccess();
					}
				})
				.catch((result) => {
					//console.log(result, 'ERROR CAUGHT');
					if (result.response.status == 401) {
						this._refreshLogin(() => {
							this._savePreferences(onSuccess);
						});
					}
				});
		},

		_versionAssertion: function() {
			if (this.config.vueAppVersion != this.appVersion) {
				if (!this.$cookies.get('block_version_reload')) {
					//console.log("Version mismatch. App version: " + this.appVersion + ", Config version: " + this.config.version);
					this.$cookies.set('block_version_reload', null, '5min');
					window.location.reload();
				}
			}
		},

		_getAppConfig: function(onSuccess = null) {
			var orig = this.$http.defaults.headers.common;
			this.$http.defaults.headers.common = null;
			this.$http.get("https://s3.eu-west-2.amazonaws.com/config.moneyguided.com/" + this.configUri + "_config.json")
				.then((result) => {
					this.config = result.data;
					//console.log(this.config, "App config");
					this._versionAssertion();
					if (onSuccess) {
						onSuccess();
					}
				})
				.catch((result) => {
					//console.log(result, 'ERROR CAUGHT');
					if (result.response.status == 401) {
						this._refreshLogin(() => {
							this._getAppConfig(onSuccess);
						});
					}
				});
			this.$http.defaults.headers.common = orig;
		},

		_getCustomer: function(onSuccess = null) {
			if (!this.customer) {
				this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000?getFacts=true", (result) => {
					this.customer = result;
					if (onSuccess) {
						onSuccess();
					}
				}, /* useCache: */ true);
			} else {
				if (onSuccess) {
					onSuccess();
				}
			}
		},

		_registerCustomer: function(customer, password, accessToken, onSuccess = null) {
			this.$http.post(this.api + '/Customers?createUser=true&password=' + password + '&verifyEmail=true' + (accessToken != null ? '&licenseToken=' + accessToken : ''), customer)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				console.log(result, 'ERROR CAUGHT');
			});
		},

		_verifyEmail: function(email, code, onSuccess = null) {
			var req = {
				email: email,
				code: code
			}
			this.$http.post(this.api + '/AccountConfirmations', req)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				console.log(result, 'ERROR CAUGHT');
			});
		},

		_verifyToken: function(token, onSuccess = null) {
			this.$http.get(this.api + "/Licenses/" + token)
				.then((result) => {
					if (onSuccess) {
						onSuccess(result.data);
					}
				}
			)
			.catch(() => {
				onSuccess(null);
			});
		},

		_getEmployer: function(employerId, onSuccess = null) {
			if (!this.employer) {
				this._GET(this.api + "/Employers/" + employerId, (result) => {
					this.employer = result;
					if (onSuccess) {
						onSuccess();
					}
				}, /* useCache: */ true);
			} else {
				if (onSuccess) {
					onSuccess();
				}
			}
		},

		// _getPaymentFrequencies: function(onSuccess = null) {
		// 	this._GET(this.api + "/PaymentFrequencies", (result) => {
		// 		if (onSuccess) {
		// 			onSuccess(result);
		// 		}
		// 	}, /* useCache: */ true);
		// },

		_setupSpendingCycle: function(customerId, cycleId, day, autoAdjust, onSuccess = null) {
			var body = {
				id:"00000000-0000-0000-0000-000000000000",
				frequency:{
					id: cycleId,
					type: 0,
					workingDayBefore: autoAdjust,
					workingDayAfter: false
				},
				dayOfWeek: 0,
				day: day,
				month:0
			};
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Spending/Cycles", body)
			.then(() => {
				if (onSuccess) {
					onSuccess();
				}
			})
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._setupSpendingCycle(customerId, cycleId, day, autoAdjust, onSuccess);
					});
				}
			});
		},

		_getEmployerLogo: function(employerId, onSuccess = null) {
			this._GET(this.api + "/Employers/" + employerId + '/logo', (result) => {
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},

		// _getFeatures: function(onSuccess = null) {
		// 	this._getEmployer(this.customer.employerID, () => {
		// 		this._parseFeatures();
		// 		if (onSuccess) {
		// 			onSuccess();
		// 		}
		// 	})
		// 	.catch((result) => {
		// //console.log(result, 'ERROR CAUGHT');		
		// if (result.response.status == 401) {
		// 			this._refreshLogin(() => {
		// 				this._getFeatures(onSuccess);
		// 			});
		// 		}
		// 	});
		// },

		// _parseFeatures: function() {
		// 	this.disabledFeatures = [];
		// 	this.enabledFeatures = [];
		// 	for (var x = 0; x < this.employer.features.length; x++) {
		// 		if (!this.employer.features[x].enabled) {
		// 			this.disabledFeatures.push(this.employer.features[x].feature.name);
		// 		} else {
		// 			this.enabledFeatures.push(this.employer.features[x].feature.name);
		// 		}
		// 	}
		// 	//console.log(this.disabledFeatures);
		// 	//console.log(this.enabledFeatures);
		// },

		_validateEmployer: function(email, onSuccess = null) {
			var req = { email: email };
			this.$http.post(this.api + "/EmployerValidationRequests", req)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._validateEmployer(email, onSuccess);
					});
				}
			});
		},

		_emailIsVerified: function(verificationId, onSuccess = null) {
			this._GET(this.api + "/VerifiedEmails/" + verificationId, (result) => {
				//console.log(result, 'Email verification result');
				if (result) 
				{
					if (onSuccess) {
						onSuccess();
					}
				}
			});
		},

		_getProducts: function(employerId, onSuccess = null) {
			this.products = [];
			if (employerId) { this.benefits = []; }
			this.calculators = [];
			this.marketplace = [];
			this._GET(this.api + "/Products/" + (employerId ? "?employerId=" + employerId : ""), (result) => {
				this.products = result;
				for (var x = 0; x < this.products.length; x++) {
					if (this.products[x].thumbnail.startsWith("Logos/")) {
						this.products[x].thumbnail = this.products[x].thumbnail.replace('Logos/', 'https://s3.eu-west-2.amazonaws.com/content.sysodev.link/Logos/')
					}
					this.products[x].img = this.products[x].thumbnail;
					if (employerId != null && this.products[x].tags.includes("employeeBenefit")) {
						this.benefits.push(this.products[x]);
					}
					if (this.products[x].tags.includes("calculator")) {
						this.calculators.push(this.products[x]);
					}
					if (this.products[x].tags.includes("marketplace")) {
						this.marketplace.push(this.products[x]);
					}
				}
				//console.log(this.products);
				//console.log(this.benefits);
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ true);
		},

		_getProduct: function(productId, onSuccess = null) {
			this._GET(this.api + "/Products/" + productId, (result) => {
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},

		_getCompletedMoves: function(onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CompletedMoves", (result) => {
				this.completedMoves = result;
				if (onSuccess) {
					onSuccess(result);
				}
			});
		},

		_getPendingMoves: function() {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Moves", (result) => {
					this.pendingMoves = result;
				}
			);
		},

		_getCustomerMove: function(moveId, onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Moves/" + moveId, (result) => {
					this.customerMove = result;
					if (onSuccess) {
						onSuccess(result);
					}
				}
			);
		},

		_markCustomerMoveAsSeen: function(moveId, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Moves/" + moveId + "/MarkAsSeenRequests", null)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._markCustomerMoveAsSeen(moveId, onSuccess);
					});
				}
			});
		},

		_markCustomerMoveAsActioned: function(moveId, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Moves/" + moveId + "/MarkAsActionedRequests", null)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._markCustomerMoveAsActioned(moveId, onSuccess);
					});
				}
			});
		},

		_getBankingInfo: function(onSuccess = null, refreshCache = false, daysHistory = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/Info?lite=false" + (daysHistory ? "&daysHistory=" + daysHistory : ""), (result) => {
				this.bankInfo = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},
		_clearBankingInfo: function() {
			localStorage.removeItem(this.api + '/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/Info?lite=false');
		},

		_reLinkAllBankAccounts: function(onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/ExtendAllConnectionsRequests", null)
				.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._reLinkAllBankAccounts(onSuccess);
					});
				}
			});
		},

		_getRecurringPayments: function(onSuccess = null, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/RecurringPayments", (result) => {
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getTransactionClassifications: function(onSuccess = null) {
			this._GET(this.api + "/TransactionClassifications?lite=true&hierarchical=true", (result) => {
				this.transactionClassifications = result;
				if (onSuccess) {
					onSuccess(this.transactionClassifications);
				}
			}, /* useCache: */ true);
		},

		_updateBulkTransactionClassifications: function(transactions, selectedClassification, onSuccess = null) {
			var transactionIDs = transactions.value.map(transaction => transaction.id);
			var body = {
				transactionIDs: transactionIDs,
				newClassificationID: selectedClassification.id
			};
			this.$http.post(this.api + "/Customers/Transactions/BulkUntagged/BulkTagRequests", body)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updateBulkTransactionClassifications(transactions, onSuccess);
					});
				}
			});
		},

		_updateTransactionClassification: function(transaction, onSuccess = null) {
			this.$http.put(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/Transactions/" + transaction.id, transaction)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updateTransactionClassification(transaction, onSuccess);
					});
				}
			});
		},

		_updateTransactionClassificationMerchantRule: function(transaction, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/CustomerMerchantRules", transaction)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updateTransactionClassificationMerchantRule(transaction, onSuccess);
					});
				}
			});
		},

		_addSpendingPlan: function(plan, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Spending/Plans", plan)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addSpendingPlan(plan, onSuccess);
					});
				}
			});
		},

		_deleteSpendingPlan: function(id, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Spending/Plans/" + id)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._deleteSpendingPlan(id, onSuccess);
					});
				}
			});
		},

		_listBanks: function(onSuccess = null) {
			this._GET(this.api + "/Banks", (result) => {
				if (onSuccess) {
					onSuccess(result);
				}
			});
		},

		_linkBankAccount: function(customerId) {
			var url = this._getOpenBankingLinkUrl(customerId);
			window.location = url;
		},

		_getOpenBankingLinkUrl: function(customerId) {
			return 'https://auth.truelayer.com/?response_type=code&client_id=moneyguided-cdfa82&scope=info%20accounts%20balance%20cards%20transactions%20direct_debits%20standing_orders%20offline_access&redirect_uri=' + this.api + '/BankingGrants&providers=uk-ob-all%20uk-oauth-all&state=' + customerId;
		},

		_addOfflineBankAccount: function(account, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts", account)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addOfflineBankAccount(account, onSuccess);
					});
				}
			});
		},

		_deleteBankAccount: function(id, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/" + id)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._deleteBankAccount(id, onSuccess);
					});
				}
			});
		},

		_updateOfflineBankAccount: function(account, onSuccess = null) {
			this.$http.put(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/" + account.id, account)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updateOfflineBankAccount(account, onSuccess);
					});
				}
			});
		},

		_addPolicy: function(policy, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Policies", policy)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addPolicy(policy, onSuccess);
					});
				}
			});
		},

		_updatePolicy: function(policy, onSuccess = null) {
			this.$http.put(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Policies/" + policy.id, policy)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updatePolicy(policy, onSuccess);
					});
				}
			});
		},

		_addInvestment: function(investment, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/InvestmentHubAccounts", investment)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addInvestment(investment, onSuccess);
					});
				}
			});
		},

		_updateInvestment: function(investment, onSuccess = null) {
			this.$http.put(this.api + "/Customers/00000000-0000-0000-0000-000000000000/InvestmentHubAccounts/" + investment.id, investment)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updateInvestment(investment, onSuccess);
					});
				}
			});
		},

		_addCreditAccount: function(credit, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts", credit)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addCreditAccount(credit, onSuccess);
					});
				}
			});
		},

		_updateCreditAccount: function(credit, onSuccess = null) {
			this.$http.put(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/" + credit.id, credit)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updateCreditAccount(credit, onSuccess);
					});
				}
			});
		},

		_getCreditAccount: function(id, onSuccess = null, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/" + id, (result) => {
				this.creditAccount = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getCreditInfo: function(onSuccess = null, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts", (result) => {
				this.creditInfo = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_deleteCreditAccount: function(id, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/" + id)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._deleteCreditAccount(id, onSuccess);
					});
				}
			});
		},

		_addMortgageAccount: function(mortgage, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/Mortgages", mortgage)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addMortgageAccount(mortgage, onSuccess);
					});
				}
			});
		},

		_deleteMortgageAccount: function(id, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/Mortgages/" + id)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._deleteMortgageAccount(id, onSuccess);
					});
				}
			});
		},

		_updateMortgageAccount: function(mortgage, onSuccess = null) {
			//console.log('update mortgage', mortgage);
			this.$http.put(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/Mortgages/" + mortgage.id, mortgage)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._updateMortgageAccount(mortgage, onSuccess);
					});
				}
			});
		},

		_getPolicyTypes: function(onSuccess = null) {
			this._GET(this.api + "/PolicyTypes", (result) => {
				this.policyTypes = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},

		_getInvestmentTypes: function(onSuccess = null) {
			this._GET(this.api + "/InvestmentTypes", (result) => {
				this.investmentTypes = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},

		_getCreditTypes: function(onSuccess = null) {
			this._GET(this.api + "/CreditTypes", (result) => {
				this.creditTypes = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},

		_getCreditProviders: function(onSuccess = null) {
			this._GET(this.api + "/CreditProviders", (result) => {
				this.creditProviders = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},

		_lookupAddress: function(postCode, houseNumber, onSuccess = null) {
			var req = {
				postCode: postCode,
				house: houseNumber
			}
			var headers = this.$http.defaults.headers.common;
			this.$http.defaults.headers.common = { "Authorization": "c8f9f85f-2d53-46ba-a2b3-8cef87c7ab93"};
			this.$http.post(this.api + "/Addresses/AddressLookupRequests", req)
			.then((response) => {
					this.$http.defaults.headers.common = headers;
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch(() => {
				this.$http.defaults.headers.common = headers;
				if (onSuccess) {
					var emptyAddress = {
						addresses: [],
						success: true,
						count: 0
					};
					onSuccess(emptyAddress);
				}
			});
			this.$http.defaults.headers.common = headers;
		},

		_getBankAccount: function(id, onSuccess = null, lite = false, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/" + id + "?lite=" + lite + "&groupTransactions=true&limitTransactions=true", (result) => {
				this.bankAccount = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getTransaction: function(id, onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/Transactions/" + id, (result) => {
				this.transaction = result;
				if (onSuccess) {
					onSuccess(this.transaction);
				}
			});
		},

		_getTransactions: function(classificationId, from, to, onSuccess = null,  includeChildrenClassifications = false, refreshCache = false, cleareCache = false) {
			var uri = this.api + "/Customers/00000000-0000-0000-0000-000000000000/BankAccounts/Transactions?classificationId=" + classificationId + "&from=" + from + "&to=" + to + "&includeChildrenClassifications=" + includeChildrenClassifications;
			if (cleareCache) {
				localStorage.removeItem(uri);
				return;
			}
			this._GET(uri, (result) => {
				this.transactions = result;
				//console.log(this.transactions, 'Transactions');
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getSuggestedClassification: function(merchant, onSuccess = null, refreshCache = false) {
			var uri = this.api + "/Customers/Transactions/Vendors/ClassificationSuggestions?merchant=" + merchant;
			this._GET(uri, (result) => {
				this.suggestedClassification = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getSpendingAnalysis: function(onSuccess = null, refreshCache = false, cleareCache = false) {
			const fromDate = this._getBeginningOfPreviousYear();
			const toDate = new Date();
			toDate.setHours(0, 0, 0, 0); // Set time to midnight
			const fromDateString = encodeURIComponent(fromDate.toISOString());
			const toDateString = encodeURIComponent(toDate.toISOString());
			const url = this.api + `/Customers/00000000-0000-0000-0000-000000000000/Spending/Analysis?from=${fromDateString}&to=${toDateString}`;
			if (cleareCache) {
				localStorage.removeItem(url);
				return;
			}
			this._GET(url, (result) => {
				this.spendingAnalysis = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getSpendingPlansLite: function(onSuccess = null, refreshCache = false, clearCache = false) {
			var uri = this.api + "/Customers/00000000-0000-0000-0000-000000000000/Spending/Plans?lite=true";
			if (clearCache) {
				localStorage.removeItem(uri);
				return;
			}
			this._GET(uri, (result) => {
				this.spendingPlans = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getSpendingPlansFull: function(onSuccess = null, refreshCache = false, clearCache = false) {
			var uri = this.api + "/Customers/00000000-0000-0000-0000-000000000000/Spending/Plans?lite=false";
			if (clearCache) {
				localStorage.removeItem(uri);
				return;
			}
			this._GET(uri, (result) => {
				this.spendingPlansFull = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getSavingGoals: function(onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/SavingsGoals", (result) => {
				this.savingGoals = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},
		_getSavingGoal: function(id, onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/SavingsGoals/" + id, (result) => {
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},
		_clearSavingGoals: function() {
			localStorage.removeItem(this.api + '/Customers/00000000-0000-0000-0000-000000000000/SavingsGoals');
		},

		_deleteSavingGoal: function(id, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/SavingsGoals/" + id)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._deleteSavingGoal(id, onSuccess);
					});
				}
			});
		},

		_quoteSavingGoal: function(goal, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/SavingsGoals/BankAccounts/" + goal.bankAccountID + "/Quotes", goal)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._quoteSavingsGoal(goal, onSuccess);
					});
				}
			});
		},

		_createSavingGoal: function(goal, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/SavingsGoals/", goal)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._quoteSavingsGoal(goal, onSuccess);
					});
				}
			});
		},

		_getInsuranceInfo: function(onSuccess = null, refreshCache = false) {
			var uri = this.api + "/Customers/00000000-0000-0000-0000-000000000000/Policies";
			if (refreshCache) {
				localStorage.removeItem(uri);
				return;
			}
			this._GET(uri, (result) => {
				this.insuranceInfo = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getInsuranceProviders: function(onSuccess = null) {
			this._GET(this.api + "/InsuranceProviders", (result) => {
				this.insuranceProviders = result;
				if (onSuccess) {
					onSuccess(result);
				}
			});
		},

		_getInvestmentProviders: function(onSuccess = null) {
			this._GET(this.api + "/InvestmentProviders", (result) => {
				this.investmentProviders = result;
				if (onSuccess) {
					onSuccess(result);
				}
			});
		},

		_getMortgageInfo: function(onSuccess = null, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/Mortgages", (result) => {
				this.mortgageInfo = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getMortgage: function(policyId, onSuccess = null, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CreditAccounts/Mortgages/" + policyId, (result) => {
				this.policy = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getInvestmentAccount: function(id, onSuccess = null, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/InvestmentHubAccounts/" + id, (result) => {
				this.investmentAccount = result;
				//console.log(this.investmentAccount, 'Investment Account');
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_deleteInvestmentAccount: function(id, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/InvestmentHubAccounts/" + id)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				console.log(result, 'ERROR CAUGHT');
			});
		},

		_getInvestmentInfo: function(onSuccess = null, refreshCache = false, cleareCache = false) {
			var uri = this.api + "/Customers/00000000-0000-0000-0000-000000000000/InvestmentHubAccounts";
			if (cleareCache) {
				localStorage.removeItem(uri);
				return;
			}
			this._GET(uri, (result) => {
				this.investmentInfo = result;
				//console.log(this.investmentInfo);
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_getCmsArticles: function(tags = null, onSuccess = null) {
			this._GET(this.api + "/CMS/Articles?tags=" + (tags ?? ''), (result) => {
				this.articles = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ true);
		},

		_getCmsArticleByKey: function(key, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CMS/Articles/SearchRequests?key=" + key, null)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data.length > 0 ? response.data[0].article : null);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getCmsArticleByKey(key, onSuccess);
					});
				}
			});
		},

		_getCmsArticle: function(id, onSuccess = null) {
			if (id.split('-').length != 5) {
				return this._getCmsArticleByKey(id, (result) => {
					this.article = result;
					if (onSuccess) {
						onSuccess();
					}
				});
			}
			else {
				this._GET(this.api + "/CMS/Articles/" + id, (result) => {
					this.article = result;
					if (onSuccess) {
						onSuccess();
					}
				}, /* useCache: */ true);
			}
		},

		_getQuestionnaire: function(id, onSuccess = null) {
			this._GET(this.api + "/Questionnaires/" + id, (result) => {
				this.questionnaire = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ true);
		},

		_getQuestionnaireByKey: function(key, onSuccess = null) {
			this.$http.post(this.api + "/Questionnaires/Searches/" + key, null)
			.then((response) => {
					if (onSuccess) {
						//console.log(response.data, 'Questionnaireby key');
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._getQuestionnaireByKey(key, onSuccess);
					});
				}
			});
		},

		_getQuestionnaireAnswers: function(id, onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CustomerAnswers", (result) => {
				this.questionnaireAnswers = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ false);
		},

		_saveQuestionnaireAnswer: function(question, onSuccess = null) {
			const requestBody = [];
			if (!question.question.selectedAnswer) {
				return;
			}
			question.question.selectedAnswer.forEach(selectedAnswer => {
				//console.log(selectedAnswer, 'selectedAnswer');
                var answer = {
                    "questionID": question.questionId,
                };
                if (selectedAnswer.answerId) {
                    answer.answerID = selectedAnswer.answerId;
                }
                if (selectedAnswer.amount != undefined && selectedAnswer.amount != null) {
                    answer.freeAnswer = String(selectedAnswer.amount);
                }
                requestBody.push(answer);
            });
			if (requestBody.length == 0) {
				return;
			}
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/CustomerAnswers", requestBody)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._saveQuestionnaire(question, onSuccess);
					});
				}
			});
		},

		_generateFinacialFitness: function(onSuccess = null, clearCache = false) {
			var uri = this.api + "/Customers/00000000-0000-0000-0000-000000000000/FinancialFitness/GenerateRequests";
			if (clearCache) {
				localStorage.removeItem(uri);
				return;
			}
			this.$http.post(uri, null)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._generateFinacialFitness(onSuccess);
					});
				}
			});
		},

		_getLatestFinancialFitness: function(onSuccess = null) {
			this._getFinancialFitness((l) => {
				this.financialFitness = l[0];
				if (onSuccess) {
					onSuccess(l[0]);
				}
			});
		},

		_getFinancialFitness: function(onSuccess = null, clearCache = false) {
			var uri = this.api + "/Customers/00000000-0000-0000-0000-000000000000/FinancialFitness";
			if (clearCache) {
				localStorage.removeItem(uri);
				return;
			}
			this._GET(uri, (result) => {
				this.financialFitnessHistory = result;
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ true);
		},

		_getPlans: function(onSuccess = null) {
			this._GET(this.api + "/Plans", (result) => {
				this.plans = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ false);
		},

		_getPlan: function(id, onSuccess = null) {
			this._GET(this.api + "/Plans/" + id, (result) => {
				this.plan = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ false);
		},

		_getPlanSection: function(id, onSuccess = null) {
			this._GET(this.api + "/Plans/Sections/" + id, (result) => {
				this.planSection = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ false);
		},

		_getPlanTask: function(id, onSuccess = null) {
			this._GET(this.api + "/Plans/Sections/Tasks/" + id, (result) => {
				this.planTask = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ false);
		},

		_setPlanTaskCompleted: function(taskId, completed, onSuccess = null) {
			var request = { "completed": completed };
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Plans/Sections/Tasks/" + taskId + "/SetStatusRequests", request)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._setPlanTaskCompleted(taskId, completed, onSuccess);
					});
				}
			});
		},

		_getAvailableVouchers: function(onSuccess = null) {
			this._GET(this.api + "/Discounts", (result) => {
				this.availableVouchers = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ true);
		},

		_getAvailableVoucher: function(id, onSuccess = null) {
			this._GET(this.api + "/Discounts/" + id, (result) => {
				this.availableVoucher = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ true);
		},

		_getCategories: function(onSuccess = null) {
			this._GET(this.api + "/Discounts/Categories", (result) => {
				this.categories = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ true);
		},

		_getCustomerVouchers: function(onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers", (result) => {
				this.vouchers = result;
				if (onSuccess) {
					onSuccess();
				}
			});
		},

		_getCustomerVoucher: function(id, onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers/" + id, (result) => {
				this.voucher = result;
				if (onSuccess) {
					onSuccess();
				}
			});
		},

		_getPayment: function(paymentId, onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Payments?providerPaymentId=" + paymentId, (result) => {
				this.payment = result;
				if (onSuccess) {
					onSuccess();
				}
			});
		},

		_createPayment: function(paymentRequest, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Payments", paymentRequest)
			.then((response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._createPayment(paymentRequest, onSuccess);
					});
				}
			});
		},

		_orderVoucher: function(slug, amount, paymentId, onSuccess = null) {
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers?brandSlug=" + slug + "&amount=" + amount + "&paymentId=" + paymentId, null) 
				.then((response) => {
					if (onSuccess) {
						onSuccess(response);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._orderVoucher(slug, amount, paymentId, onSuccess);
					});
				}
			});
		},

		_deleteVoucherTransaction: function(voucherId, transactionId, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers/" + voucherId + "/Transactions/" + transactionId)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._deleteVoucherTransaction(voucherId, transactionId, onSuccess);
					});
				}
			});
		},
		
		_addVoucherTransaction: function(id, amount, onSuccess = null) {
			const requestBody = {
				amount: amount
			};
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Vouchers/" + id + "/Transactions", requestBody)
				.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._addVoucherTransaction(id, amount, onSuccess);
					});
				}
			});
		},

		_getPolicy: function(policyId, onSuccess = null, refreshCache = false) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Policies/" + policyId, (result) => {
				this.policy = result;
				//console.log('Policy', this.policy);
				if (onSuccess) {
					onSuccess(result);
				}
			}, /* useCache: */ !refreshCache, /* forceSetCache: */ refreshCache);
		},

		_deletePolicy: function(policyId, onSuccess = null) {
			this.$http.delete(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Policies/" + policyId)
			.then(() => {
					if (onSuccess) {
						onSuccess();
					}
				}
			);
		},

		_getDocumentsByHubId: function(hubItemId, onSuccess = null) {
			this._GET(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Documents/HubItems/" + hubItemId, (result) => {
				this.documents = result;
				if (onSuccess) {
					onSuccess();
				}
			}, /* useCache: */ true);
		},

		_sendChatMessage: function(message, onSuccess = null) {
			this.$http.post(this.api + "/Chats/ChatRequests?customerID=" + this.customer.id, {
				"body": message
			})
				.then((result) => {
					if (onSuccess) {
						onSuccess(result.data);
					}
				}
			)
			.catch((result) => {
				//console.log(result, 'ERROR CAUGHT');
				if (result.response.status == 401) {
					this._refreshLogin(() => {
						this._sendChatMessage(message, onSuccess);
					});
				}
			});
		},

		_login: function(email, password, onSuccess = null) {
			this.$http.post(this.api + "/Sessions", {
				"userName": email,
				"password": password
			})
				.then((result) => {
					this.token = result.data.session.token;
					this.$cookies.set('token', result.data.session.token, '30min');
					this.$http.defaults.headers.common = { "Authorization": "bearer " + this.token};
					if (onSuccess) {
						onSuccess();
					}
				}
			);
		},

		_refreshLogin: function(onSuccess = null) {
			if (!this.$cookies.get('user') || !this.$cookies.get('pass')) {
				window.location.href = "/MG_POP.html";
			}
			var email = this.$cookies.get('user');
			var password = this.$cookies.get('pass');
			this._login(email, password, onSuccess);
		},

	}
  }