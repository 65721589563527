<template>
	<header-block title="" border="0"></header-block>
	<CustomerLoadWait :customer="customer" />
	<transition>
		<div class="simplePage">
			<h1>Notifications</h1>
			<p>Receive personalised notifications which can save you time and money.</p>
			<img src="../assets/ChatMgButton.png" />
			<div v-if="customer">
				<p>As well as notifications generated when we spot something that could be important to you, you can enable daily balance notifications to help you monitor your spending.</p>
				<p>
					<v-switch color="#332847" class="switch" @change="savePrefs" hide-details="auto" v-model="customer.preferences.dailyCurrentBalanceNotifications" label="Also send daily current balance"></v-switch>
					<v-switch color="#332847" class="switch" @change="savePrefs" hide-details="auto" v-model="customer.preferences.dailyCreditBalanceNotifications" label="Also send daily credit balance"></v-switch>
				</p>
			</div>
			<div class="">
				<MGButton text="Enable" @click="enableNotifications"></MGButton>
				<div class="mt-3" @click="$router.push('/personalisation')">No thanks. Skip for now</div>
			</div>
			<div class="" style="clear:both; padding-bottom: 50px;" />
		</div>
		
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, MGButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				if (this.customer.preferences == null) {
					this.customer.preferences = { dailyCurrentBalanceNotifications: false, dailyCreditBalanceNotifications: false };
				}
			});
		},
		enableNotifications: function() {
			this._signalV1("enableNotifications", "", "", "", "personalisation");
		},
		savePrefs: function() {
			this._savePreferences(() => {
				if (this.customer.preferences == null) {
					this.customer.preferences = { dailyCurrentBalanceNotifications: false, dailyCreditBalanceNotifications: false };
				}
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

</style>
