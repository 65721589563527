<template>
	<header-block :title="employer ? employer.name + ' Benefits' : 'Benefits'"></header-block>
	<CustomerLoadWait :customer="products && employer" />
	<transition>
		<div class="simplePage" v-if="products && employer">
			<h1>{{ employer.name }} Benefits</h1>
			<p>{{ employer.benefitsDescription }}</p>
			<img v-if="employer.logoUri && employer.logoUri != ''" class="heroImg mt-2" :src="employer.logoUri" />
			<div v-if="!employer.logoUri || employer.logoUri == ''" class="mt-5" />
			<div class="benefits left">
				<BarArrowButton class="mt-3" :large="true" v-for="product in products" :key="product.id" :icon="product.thumbnail" :title="product.name" :href="product.actionButtonText ? '/#/Products/' + product.id : product.websiteUrl" :moreRounded="false" />	
			</div>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import BarArrowButton from './elements/BarArrowButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, BarArrowButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				this._getEmployer(this.customer.employerID);
				this._getProducts(this.customer.employerID);


			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

</style>
