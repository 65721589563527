<template>
	
	<div class="addressLookup">
		<v-row v-if="!results">
			<v-col>
				<v-text-field
					v-model="postCode"
					type="text"
					:label="searching ? 'Searching....' : 'Enter postcode'"
					variant="solo"
					:flat="true"
					sin--gle-line
					:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
					:append-inner-icon="postCode && postCode.replace(/\s/g, '').length >= 4 ? 'mdi-magnify' : ''"
					@click:append-inner="lookupAddress"
				></v-text-field>
			</v-col>
		</v-row>
		<v-select
			v-if="results"
			v-model="modelValue"
			si-ngle-line
			variant="solo"
			:flat="true"
			append-inner-icon="mdi-close-circle"
			@click:append-inner="results = null"
			:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			:items="results.map(m => ({ title: m.line1, value: m }))"
			item-value="value"
			:label="results && results.length + (results.length === 1 ? ' address found' : ' addresses found')"
			item-text="address"
		></v-select>
	</div>
	
</template>

<script>
import Services from '../../mixins/Services';
export default {
	components: {},
	mixins: [Services],
	name: "addressLookup",
	props: ['value', 'theme', 'showNumber'],
	emits: ['update:modelValue'],
	data() {
		return{
			postCode: '',
			number: null,
			pageTheme: this.theme,
			results: null,
			address:null,
			searching: false
		}
	},
	computed: {
		modelValue: {
			get() {
				return this.address;
			},
			set(value) {
				this.address = value;
				this.$emit('update:modelValue', this.address);
			}
		}
	},
	methods: {
		onLoad() {
			if (!this.pageTheme) { this.pageTheme = 'dark'; }
			if (this.value) {
				this.postCode = this.value.postCode;
				this.lookupAddress();
				this.$watch('results', (newVal) => {
					if (newVal) {
						if (this.value && this.value.line1) {
							const foundAddress = this.results.find(address => address.line1 === this.value.line1);
							if (foundAddress) {
								this.modelValue = foundAddress;
							}
							else {
								this.modelValue = null;
							}
						}
					}
				});
			}
		},
		lookupAddress() {
			if (!this.postCode || this.postCode.length < 4) { return; }
			this.searching = true
			this._lookupAddress(this.postCode, this.number, (res) => {
				this.searching = false;
				this.results = res.addresses;
			});
			
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.addressLookup {

}

.v-row {
	padding: 0px;
	margin:0px;
}

.v-col {
	padding: 0px;
	margin:0px;
}

.buttonCol {
	text-align: right;
	padding-top: 3px;
	margin-left: 5px;
}



</style>