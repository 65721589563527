<template>
	<header-block bgcolor="#FCE5D4ff" title="Retail Cards"></header-block>
	<CustomerLoadWait :customer="customer && vouchers" :cardBlocks="true" :withTopCard="true" />
	<div v-if="customer && vouchers">

		<div class="balanceHeader">
			<span>Total balance</span>
			<p>{{ formatter.format(balance.toFixed(2)) }}</p>
			<div class="info-header">
				<v-row>
					<v-col class="balance-col">
						<p class="balance-title">Saved to spend</p>
						<div>{{ formatter.format(vouchers.currentSavings) }}</div>
					</v-col>
					<v-col class="balance-col">
						<p class="balance-title">All time saving</p>
						<span>{{ formatter.format(vouchers.allTimeSavings) }}</span>
					</v-col>
				</v-row>	
				<v-row class="button-row">
					<v-col class="button-col">
						<BarArrowButton :icon="require('@/assets/logos/Vouchers.png')" title="Shop all savings" href="/#/vouchers" />
					</v-col>
				</v-row>
			</div>
		</div>

		<!-- Parent div to allow odd/even css selectors -->
		<div>
			<div :class="'hubItemWrapper'" v-for="a in activeVouchers" :key="a.id">
				<AccountButton :href="'/#/myvouchers/' + a.id" :title="a.discount.merchant.name" :icon="a.discount.cardUrl" :balance="formatter.format(a.currentBalance)"></AccountButton>
			</div>
			<div :class="'hubItemWrapper'" v-if="activeVouchers.length == 0">
				<AccountButton href="/#/vouchers" :isAddButton="true"></AccountButton>
			</div>
		</div>


		<div style="clear:both; margin-bottom: 60px"></div>

		<!-- Archived Cards Drawer -->

		<DrawerPanel v-if="archivedCount > 0" bottom="44px" title="Spent Cards" backgroundColor="white" openBackgroundColor="#FBF7F4">
			<div class="usedVouchers">
				<div class="hubItemWrapper" v-for="a in archivedVouchers" :key="a.id">
					<AccountButton :href="'/#/myvouchers/' + a.id" :title="a.discount.merchant.name" :icon="a.discount.cardUrl" :balance="formatter.format(a.currentBalance)" :empty="true"></AccountButton>
				</div>
			</div>	
		</DrawerPanel>

		
	</div>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import AccountButton from './elements/AccountButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import BarArrowButton from './elements/BarArrowButton.vue';
import DrawerPanel from './elements/DrawerPanel.vue';

export default {
	mixins: [Services],
	components: { CustomerLoadWait, AccountButton, HeaderBlock, BarArrowButton, DrawerPanel },
	data() {
		return {
			balance: 0,
			showArchived: false
		}
	},
	computed: {
		archivedVouchers() {
			return this.vouchers?.vouchers.filter(v => v.currentBalance <= 0) || [];
		},
		activeVouchers() {
			return this.vouchers?.vouchers.filter(v => v.currentBalance > 0) || [];
		},
		archivedCount() {
			return this.archivedVouchers.length;
		}
	},
	methods: {
		getCustomer: function(){
			this._getCustomer(() => {
				
			});
		},
		onLoad: async function() {
			this.getCustomer();
			this._getCustomerVouchers(() =>
			{
				this.balance = this.vouchers.vouchers.reduce((a, b) => a + b.currentBalance, 0);
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.title {
	font-size: 14px;
	font-weight: bold;
}

.info-header {
	background-color: #ffffff44;
	border-radius: 16px;
	margin:30px;
	padding: 10px;
	margin-top: 0px;
}

.button-row {
	padding:0px;
}
.button-col {
	padding-top:0px;
}

.balance-col {
	padding-left: 20px;
	font-size: 16px;
	text-align: left;
}

.balance-title {
	font-size: 12px !important;
	margin-bottom: 0px;
	font-weight: lighter;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.usedVouchers {
	padding-left: 10px;
	padding-right: 10px;
}

.topTabButton
{
	margin-top: 0px;
}

.topMenuCol {
	padding-left: 5px;
	padding-right: 5px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 15px;
}

.balanceHeader {
	background: linear-gradient(202deg, #FCE5D4 49.85%, #E0C5C8 100%);
	clear:both;
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -138px;
	margin-bottom: -80px;
	color: var(--text-color);
	font-size: 15px;
	text-align: center;
	padding-top: 150px;
	padding-bottom: 75px;
	p{
		font-size: 48px;
		font-family: 'Gotham-Heavy';
		margin-top: 0px;
	};
	.small {
		font-size: 10px;
		margin-top: -40px;
	}
}

</style>
