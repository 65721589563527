<template>
	<HeaderBlock title="You" />
	<CustomerLoadWait :customer="customer" />
	<transition>
		<div v-if="customer">
			<v-row v-if="!customer.person.whatsAppEnabled">
				<v-col>
					<AdButton title="Whatsapp" href="/#/EnableWhatsapp" subtitle="Enable Whatsapp to chat with us about your finances &amp; send in your documents for automatic processing!" 
					:topicon="require('@/assets/Whatsapp.webp')" :bottomrighticon="require('@/assets/go.png')" />
				</v-col>
			</v-row>
			<!-- <v-row>
				<v-col>
					<InfoPanel :blur="true" :icon="require('@/assets/coins.png')" title="Cashback" subtitle="Money back on spending" valueTitle="May 2024" value="£32.50" />
				</v-col>
			</v-row> -->
			<transition>
				<v-row v-if="financialFitness">
					<v-col>
						<InfoPanel class="shadowed" href="/#/FinancialFitness" valueHref="/#/FinancialFitnessHistory" :icon="require('@/assets/financialFitnessIcon.png')" title="My Financial Fitness" subtitle="Take the survey" valueTitle="Score" :value="financialFitness.score" />
					</v-col>
				</v-row>
			</transition>
			
			<transition>
			<v-row v-if="!financialFitness">
				<v-col>
					<InfoPanel href="/#/FinancialFitness" valueHref="/#/FinancialFitness" :icon="require('@/assets/financialFitnessIcon.png')" title="My Financial Fitness" subtitle="Let's calculate your baseline" valueTitle="Score" value="-" />
				</v-col>
			</v-row>
			</transition>

			<transition>
			<v-row v-if="thumbs.length > 0">
				<v-col>
					<ScrollThumbBlock href="/#/Vouchers" title="Featured Discounts" subtitle="Save money every day" :thumbs="thumbs" :rightimage="require('@/assets/arrow-right.png')"/>
				</v-col>
			</v-row>
			</transition>

			<transition>
			<v-row v-if="this.customer && this.customer.person.addresses[0]">
				<v-col>
					<AdButton title="Switch" :href="'https://moneyguided.aptap.co.uk?p1=' + this.customer.person.email + '&p2=' + this.customer.person.addresses[0].postCode" subtitle="Move to a better Broadband, SIM, Mobile or Energy deal" 
					:topicon="require('@/assets/switch.png')" :bottomrighticon="require('@/assets/go.png')" />
				</v-col>
				<!-- <v-col>
					<AdButton href="MG_POP.html?view=appscreen-Home-Resources-Marketplace" title="Marketplace" subtitle="Financial services" 
					:topicon="require('@/assets/switch.png')" :bottomrighticon="require('@/assets/family.png')" />
				</v-col> -->
			</v-row>
			</transition>

			<transition>
			<v-row v-if="benefits">
				<v-col>
					<ScrollThumbBlock href="/#/Benefits" :title="employer ? employer.name + ' Benefits': 'Employee Benefits'" subtitle="View your employee benefits" :thumbs="benefits" :rightimage="require('@/assets/arrow-right.png')"/>
				</v-col>
			</v-row>
			</transition>

			<transition>
			<v-row v-if="marketplace">
				<v-col>
					<ScrollThumbBlock href="/#/Marketplace" title="Marketplace" subtitle="Explorer deals with our partners" :thumbs="marketplace" :rightimage="require('@/assets/arrow-right.png')"/>
				</v-col>
			</v-row>
			</transition>
			
			<!-- <v-row>
				<v-col><PayRiseCalculator :api="api"/></v-col>
			</v-row> -->
			<v-row>
				<!-- <v-col>
					<CountBlock :href="!cripple ? '/#/CompletedMoves' : null" title="Moves completed" :value="customer.facts['M_COMPLETED'] ?? '0'"></CountBlock>
				</v-col> -->
				<!-- <v-col>
					<CountBlock href="/#/Guides" title="Guides you've read" :value="customer.facts['CMS_READ'] ?? '0'" :subvalue="'of ' + customer.facts['CMS_UNREAD']"></CountBlock>
				</v-col> -->
			</v-row>
			<!-- <v-row>
				<v-col>
					<CountBlock :href="!cripple ? '/#/aiop' : null" title="Accounts" :value="customer.facts['B_ACCOUNTS'] ?? '0'"></CountBlock>
				</v-col>
				<v-col>
					<CountBlock href="MG_POP.html?view=appscreen-Home-Spending" title="Transactions tagged" :value="customer.facts['B_TAGGEDTRANSACTIONS'] ?? '0'"></CountBlock>
				</v-col>		
			</v-row> -->
			<v-row >
				<v-col>
					<AdButton href="/#/CompletedMoves" :topicon="require('@/assets/bullet-tick.png')" :bottomrighticon="require('@/assets/go.png')" subtitle="Review all your completed Moves" title="Completed Moves"></AdButton>
				</v-col>
			</v-row>
			
		</div>
	</transition>
	
</template>

<script>
import Services from '../mixins/Services';
import InfoPanel from './elements/InfoPanel.vue';
import ScrollThumbBlock from './elements/ScrollThumbBlock.vue';
import AdButton from './elements/AdButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import Helpers from '../mixins/Helpers';

export default {
	mixins: [Services, Helpers],
	components: {HeaderBlock, InfoPanel, ScrollThumbBlock, AdButton, CustomerLoadWait},
	data() {
		return {
			cripple: true,
			showComingSoon: false,
			whatsappPressed: false,
			thumbs: []
		}
	},
	methods: {
		getCustomer: function(){
			this._getAppConfig();
			this._getCustomer(() => {
				this._getEmployer(this.customer.employerID);
				this._getCustomerVouchers();
				this._getLatestFinancialFitness();
				//this._getFeatures();
				this._getProducts(this.customer.employerID);
				this._getProducts();
				this._getAvailableVouchers(() => {
					this.thumbs = [];
					this.availableVouchers.forEach(v => {
						if (v.featured) {
							this.thumbs.push({img: v.merchant.logoUrl, url: '/#/vouchers/' + v.id});
						}
					});
					this.thumbs = this.thumbs.sort(() => Math.random() - 0.5);
				});
			});
		},
		onLoad: async function() {
			this.getCustomer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>



</style>
