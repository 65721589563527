<template>
	
	<div class="well">
			
		<div v-if="title">
			<h2>{{ subTitle }}</h2>
			<v-avatar v-if="avatarLetter" class="avatar">{{ avatarLetter }}</v-avatar>
			<h1>{{ title }}</h1>
		</div>

		<v-row v-for="d in sortedData" :key="d.label" @click="handleClick(d)">
			<v-col>
				{{ d.label }} <span class="supplement" v-if="d.supplement">{{ d.supplement }}</span><br/>
				<span>{{ d.value }}</span><br/>
				<v-row class="progressRow" v-if="d.progress">
					<v-col>
						<v-progress-linear :model-value="d.progress" rounded></v-progress-linear>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

	</div>
	
</template>

<script>
export default {
	name: "WellPanel",
	props: ['title', 'subTitle', 'icon', 'avatarLetter', 'data'],
	emits: ['merchantClicked'],
	data() {
		return{
			something: null
		}
	},
	computed: {
		somethingElse() {
			return null;
		},
		sortedData() {
            return this.data.slice().sort((a, b) => a.order - b.order);
        }
	},
	methods: {
		onLoad() {
		},
		handleClick(d) {
			this.$emit('merchantClicked', d);
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

h2 {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 0px;
	color: var(--grey-text);
}

h1{
	margin-top: 0px;
	font-size: 24px;
	font-weight: bold;
	margin-bottom: 30px;
}

.avatar {
	width: 50px;
	height: 50px;
	float: right;
	background-color: var(--background-color-alt2);
	margin-top: -20px;
	color: white;
	font-size: 24px;
}

.well {
	
	color: var(--text-color);
	padding: 20px;
	background-color: white;
	border-radius: 8px;

	.v-row {
		padding-left: 15px;
		padding-right: 15px;
	}

	.v-col{
		padding: 0px;
		padding-bottom: 12px;
		padding-top: 12px;
		font-size: 12px;
		span {
			font-size: 14px;
			font-weight: bold;
		}
		.supplement {
			font-size: 10px;
			color: var(--grey-text);
		}
		border-bottom: 1px solid var(--border-color);
	}

	.v-row:last-child .v-col {
		border-bottom: 0px;
	}

	.progressRow {
		padding-top: 10px;
	}

	:deep(.v-progress-linear__background) {
		background-color: var(--border-color) !important;
	}

}

</style>