<template>
	<header-block :title="timeOfDay + (customer ? (', ' + customer.person.firstName) : '')"></header-block>
	<CustomerLoadWait :customer="customer && bankInfo" />
	<transition>
		<v-row v-if="relinkNeeded">
			<v-col>
				<AdButton class="linkAccounts" title="Keep Your Accounts Connected" href="/#/Accounts/All/Relink"
					subtitle="To keep your account data flowing smoothly, we need you to refresh the permissions for some of your connected bank accounts. This only takes a moment."
					:topicon="require('@/assets/coins.png')" :bottomrighticon="require('@/assets/go.png')"
				/>
			</v-col>
		</v-row>
	</transition>
	<transition>
		<v-row v-if="bankInfo && bankInfo.accounts.length > 0">
			<v-col>
				<AnalysisWidget titleLabel="Spending Analysis" :totals="selectedTotals" :formatter="formatter" :title="true" :daysRemaining="spendingAnalysis ? spendingAnalysis.daysTillCycleResets : 0" @click="navigateToSpendingAnalysis()" />
			</v-col>
		</v-row>
	</transition>
	<transition>
		<v-row v-if="bankInfo && bankInfo.accounts.length > 0 && bankInfo.untaggedTransactionsCurrentCycleCount > 0">
			<v-col>
				<InfoPanel
					:href="'/#/Transaction/Bulk?from=' + bankInfo.currentCycleStartDate + '&to=' + bankInfo.today + '&includeNegative=true&includePositive=true'"
					:valueHref="'/#/Transaction/Bulk?from=' + bankInfo.currentCycleStartDate + '&to=' + bankInfo.today + '&includeNegative=true&includePositive=true'"
					:icon="require('@/assets/coins2.png')"
					title="Tag Transactions"
					subtitle="You have transactions to tag in your current pay cycle"
					valueTitle="Count"
					:value="bankInfo.untaggedTransactionsCurrentCycleCount"
				/>
			</v-col>
		</v-row>
	</transition>
	<transition>
		<v-row v-if="bankInfo && bankInfo.accounts.length > 0">
			<v-col>
				<AccountsWidget :accounts="transactionAccounts.length > 0 ? transactionAccounts : creditCards" :formatter="formatter"/>
			</v-col>
		</v-row>
	</transition>
	<transition>
		<v-row class="financialFitnessRow" v-if="financialFitness && financialFitness.length == 0">
			<v-col>
				<InfoPanel href="/#/FinancialFitness" valueHref="/#/FinancialFitnessHistory" :icon="require('@/assets/financialFitnessIcon.png')" title="My Financial Fitness" subtitle="Let's calculate your baseline" valueTitle="Score" value="--" />
			</v-col>
		</v-row>
	</transition>
	<transition>
		<v-row class="financialFitnessRow" v-if="bankInfo && bankInfo.accounts.length == 0">
			<v-col>
				<AdButton class="linkAccounts" title="Link Your Bank Accounts" href="/#/aiop?add=true"
					subtitle="Money Guided is designed to work with insights into your personal finances. Link your bank accounts to get started!"
					:topicon="require('@/assets/coins.png')" :bottomrighticon="require('@/assets/go.png')"
				/>
			</v-col>
		</v-row>
	</transition>
	<transition>
		<v-row v-if="thumbs.length > 0">
			<v-col>
				<ScrollThumbBlock href="/#/Vouchers" title="Featured Discounts" subtitle="Save money every day" :thumbs="thumbs" :rightimage="require('@/assets/arrow-right.png')"/>
			</v-col>
		</v-row>
	</transition>
	<!-- <transition>
		<BarArrowButton href="/#/aiop?show=true" class="mt-6" v-if="bankInfo && bankInfo.recentTransactions && bankInfo.recentTransactions.length > 0" :large="true" title="Recent">
			<TransactionsList class="mt-4" :search="false" :showDate="false" :transactions="bankInfo.recentTransactions.slice(0,1)" :formatter="formatter" :today="today" :yesterday="yesterday" />
		</BarArrowButton>
	</transition> -->
	<!-- <transition>
		<v-row v-if="marketplace" class="mt-3">
			<v-col>
				<ScrollThumbBlock href="/#/Marketplace" title="Marketplace" subtitle="Explorer deals with our partners" :thumbs="marketplace" :rightimage="require('@/assets/arrow-right.png')"/>
			</v-col>
		</v-row>
	</transition> -->
	<transition>
		<div v-if="pendingMoves && pendingMoves.length > 0" class="moves-title-row mt-3">
			<v-row class="title-row">
				<v-col class="title-col">Moves</v-col>
				<v-col class="right" style="padding-top: 20px;"><a href="/#/Moves">See All</a></v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="moves-widget-col">
					<MovesWidget v-if="pendingMoves" :moves="pendingMoves" :lite="true" />
				</v-col>
			</v-row>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import AccountsWidget from './elements/AccountsWidget.vue';
import AnalysisWidget from './elements/AnalysisWidget.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MovesWidget from './elements/MovesWidget.vue';
import AdButton from './elements/AdButton.vue';
import InfoPanel from './elements/InfoPanel.vue';
import Helpers from '../mixins/Helpers';
import SpendingAnalysis from '../mixins/SpendingAnalysis';
//import TransactionsList from './elements/TransactionsList.vue';
//import BarArrowButton from './elements/BarArrowButton.vue';
import ScrollThumbBlock from './elements/ScrollThumbBlock.vue';

export default {
	mixins: [Services, Helpers, SpendingAnalysis],
	components: {CustomerLoadWait, HeaderBlock, AnalysisWidget, AccountsWidget, MovesWidget,
		AdButton, InfoPanel, ScrollThumbBlock},
	data() {
		return {
			selectedTotals: null,
			relinkNeeded: false,
			thumbs:[]
		}
	},
	computed: {
		transactionAccounts() {
			var ret = [];
			if(this.bankInfo == null) {
				return ret;
			}
			this.bankInfo.accounts.forEach(a => {
				if (a.accountType.prettyName.toLowerCase() == "current") {
					ret.push(a);
				}
			});
			return ret;
		},
		creditCards() {
			var ret = [];
			if(this.bankInfo == null) {
				return ret;
			}
			this.bankInfo.accounts.forEach(a => {
				if (a.accountType.prettyName.toLowerCase() == "credit") {
					ret.push(a);
				}
			});
			return ret;
		},
	},
	methods: {

		onLoad: async function() {
			this._getAppConfig();
			this._getCustomer(() => {
				this._getLatestFinancialFitness();
				this._getBankingInfo(() => {
					this.bankInfo.accounts.forEach(a => {
						if (a.consentExpired) {
							this.relinkNeeded = true;
						}
					});
				});
				this._getPendingMoves();
				this._getSpendingAnalysis(() => (
					this.selectedTotals = this._getLatestMonthsSpendingTotals()
				));
				this._getAvailableVouchers(() => {
					this.thumbs = [];
					this.availableVouchers.forEach(v => {
						if (v.featured) {
							this.thumbs.push({img: v.merchant.logoUrl, url: '/#/vouchers/' + v.id});
						}
					});
					this.thumbs = this.thumbs.sort(() => Math.random() - 0.5);
				});
				this._getProducts(this.customer.employerID);
				this._getProducts();
			});
		},

		navigateToSpendingAnalysis() {
			this.$router.push('/SpendingAnalysis');
			//this._openV1('/Moves/Spending');
		}

	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.title-col {
	font-size: 20px;
	padding-bottom: 0px;
}

.financialFitnessRow {

}

.linkAccounts {
	padding-top: 10px;
}

.moves-title-row
{
	margin-top: 10px;
	padding-top: 10px;
	.v-col {
		padding-bottom: 5px;
		a {
			text-decoration: underline;
		}
	}
}

.moves-widget-col {
	padding-top: 0px;
	height: 275px;
}

</style>
