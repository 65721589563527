import Helpers from './Helpers';

export default {
	mixins: [Helpers],
	data() {
		return {
		}
	},

	created: function() {
	},

	methods: {

		_getClassificationNameByClassiciationId: function(id) {
			const classification = this._findClassificationById(id, this.classifications);
			if (classification) {
				const name = classification.mgClassification.mgSubClassification || classification.mgClassification.fullName;
				return name.length > 15 ? name.substring(0, 10) + '...' : name;
			}
			return null;
		},

		_getIconByClassiciationId: function(id) {
			const classification = this._findClassificationById(id, this.classifications, true);
			if (classification) {
				return classification.mgClassification.iconPath;
			}
			return null;
		},

		_updateSingleTransaction: function(transaction){
			var customerClassification = transaction.customerClassification;
			this.transaction.effectiveClassification = customerClassification;
			this._updateTransactionClassification(this.transaction, () => {
				this.refresh();
			});
		},

		_createRule: function(transaction) {
			var payload = {
				alwaysExcludeFromAnalysis: transaction.excludeFromAnalysis,
				merchantName: transaction.merchantName
			};
			if (transaction.customerClassification) {
				payload.chosenTransactionClassification = {
					mgClassification: transaction.customerClassification.mgClassification,
					mgSubClassification: transaction.customerClassification.mgSubClassification,
					grandParentClassification: transaction.customerClassification.grandParentClassification,
			}}
			this._updateTransactionClassificationMerchantRule(payload, () => {
				this.refresh();
			});
		},

		_onTagUpdated: function(transaction) {
			this.updateClassification(transaction);
			this.selectedClassificationId = null;
			this.selectedClassification = null;
			this.suggestedClassification = null;
			this._scrollToTop();
		},

		_getBankAccountByAccountId: function(id) {
			var bank = this.bankInfo.accounts.find(ba => ba.id === id);
			return bank;
		},

		_scrollToBottom: function() {
			const contentHeight = document.getElementById('body').clientHeight;
			const windowHeight = window.innerHeight;
			const scrollableHeight = contentHeight - windowHeight;
			window.scrollTo({ top: scrollableHeight, behavior: 'smooth' });
		},

		_scrollToTop: function() {
			window.scrollTo({ top: 0, behavior: 'smooth' });
		}
	}
}