<template>
	<div class="card" :style="'background-color: #' + (account.bank.hexColour2 ? account.bank.hexColour2.substring(2) : '000000')">
		<v-row class="nameRow">
			<v-col class="v-col-9 nameCol">
				<p>{{account.bank.prettyName ? account.bank.prettyName : account.bank.name}}</p>
				<span>{{account.displayName}}</span>
			</v-col>
			<v-col class="right"><img class="bankLogo" :src="account.bank.logoPath ? require('@/assets/logos/' + account.bank.logoPath): account.bank.logoUri"/></v-col>
		</v-row>
		<v-row class="mt-0">
			<v-col class="balanceCol">{{ formatter.format(Math.round(account.balance))}}</v-col>
			<v-col class="balanceCol right">{{ formatter.format(Math.round(account.overdraftFacility))}}</v-col>
		</v-row>
		<v-row class="mt-1 mb-4">
			<v-col class="labelCol">Current balance</v-col>
			<v-col class="labelCol right">{{ account.accountType.prettyName == 'Current' ? 'Overdraft' : 'Credit' }} limit</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>**** {{ account.accountNumber ? account.accountNumber.substring(4) : '' }}</v-col>
		</v-row>
		<v-row></v-row>
	</div>
</template>

<script>
export default {
	name: "BankCard",
	props: ['account', 'formatter'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.card {
	color:var(--light-text-color); 
	padding: 25px; 
	height: 20vh; 
	min-height: 200px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.263);
}

.nameCol
{
	p{
		font-size: 15px;
		margin-bottom: 0px;
	};
	span{
		font-size: 12px;
	}
}

.balanceCol
{
	font-size: 28px;
	padding-bottom: 0px;
}

.labelCol
{
	padding-bottom: 0px;
	padding-top:0px;
	font-size: 12px;
}

.bankLogo
{
	width: 40px;
}

</style>