<template>
		<HeaderBlock bgcolor="#00000000" border="0" />
		<div class="simplePage" v-if="!about">
			<h1>You're all set!</h1>
			<p>Before we dive in, we'd like to understand your financial goals better.</p>
			<img class="heroImg" src="../assets/Personalisation.png"/>

			<div>
				<p><v-icon icon="mdi-check" /> Take control of your finances</p>
				<p><v-icon icon="mdi-check" /> Develop a more positive relationship with your money</p>
				<p><v-icon icon="mdi-check" /> Tailored insights and guidance</p>
			</div>
			<p></p>
			<div class="anchor-bottom">
				<MGButton :alt="false" text="continue" :arrow="true" @click="startSurvey"/>
			</div>
		</div>
</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, MGButton},
	data() {
		return {
			about: false
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
		},
		startSurvey: function() {
			this._getQuestionnaireByKey('GOALS', (q) => {
				console.log(q, 'Found this questionnaire');
				this.$router.push({path: '/Questionnaire/' + q.id, query: {theme: 'dark', allowBack: false}});
			});	
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
/* 
.simplePage {
	text-align: center;
	.v-col{
		padding: 0px;
	}
	.well {
		margin-top: 45px;
		padding: 25px;
		background-color: #f5f3f5;
		border-radius: 24px;
		h1{
			font-size: 16px;
		}
	}
	.bulletItem {
		text-align: left;
	}
	.largeImg {
		width: 180px;
		margin-bottom: 15px;
		margin-top: 15px;
	}
	h1 {
		font-size: 24px;
		margin-top: 8px;
		margin-bottom: 15px;
		text-align: center;
	}
	p {
		margin-top: 15px;
		margin-bottom: 15px;
		font-size: 14px;
		text-align: center;
	}
	.info {
		text-align: left;
		padding: 15px;
		background-color: var(--background-color-alt);
		border-radius: 6px;
		h1{
			font-weight: bold;
			font-size: 12px;
		}
		p {
			font-size: 12px;
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
	.tick {
		text-align: right;
		float: right;
	}
	.arrow {
		margin-top: 20px;
		width: 12px;
		margin-left: 10px;
	}
	.partnership{
		text-align: center;
		img {
			margin: 0px;
			margin-left: 5px;
			margin-bottom: -5px;
			width: 64px;
		}
	} 
}*/

</style>
