import Helpers from './Helpers';

export default {
	mixins: [Helpers],
	data() {
		return {
		}
	},

	created: function() {
	},

	methods: {

		_createPlan(plan) {
			this.selectedPlan = plan;
			if (plan.plan) {
				this._setFieldValue('Plan amount', plan.plan.amount);
			}
			this.managePlan = true;
		},

		_updatePlan(plan, planPage = true) {
			var newAmount = this._findFieldValue('Plan amount') || null
			var newSpendingPlan = {
				"mgClassification": {
					"id": plan.classification.id,
					"key": plan.classification.key,
					"mgClassification": plan.classification.mgClassification,
					"mgSubClassification": plan.classification.mgSubClassification,
					"grandParentClassification": plan.classification.grandParentClassification,
					"iconPath": plan.classification.iconPath
				},
				"amount": newAmount
			};

			this._getSpendingPlansLite(null, /* refreshCache */ false, /* clearCache*/ true);
			this._getSpendingPlansFull(null, /* refreshCache */ false, /* clearCache*/ true);
			this._addSpendingPlan(newSpendingPlan, () => {
				this._getSpendingPlansFull(() => {
					this._extractLitePlansFromFullPlans(); 
					this._setupCategories();
					this._updateSelectedTotals();
					if (planPage) {
						this.getPlanTotals();
						this.animateEveryDaySpendingProgress(this.everyDaySpendingPlanProgressPercent);
						this.animateFuturePlansProgress(this.futurePlansProgressPercent);
						this.animateCommittedCostsProgress(this.committedCostsProgressPercent);
						this.categoriesPanel = this.spendingPlansFull.plans.filter(plan => plan.classification.grandParentClassification === this.currentCategoryId);
					}
					this.ready = true;	
				}, true);
			});

			this.managePlan = false;
			if (this.closeDrawerNext) {
				this.$refs.transactionDrawer.closeDrawer();
				this.closeDrawerNext = false;
			}
			this._setFieldValue('Plan amount', 0)
		},

		_extractLitePlansFromFullPlans() { 
			this.spendingPlans = {
				plans: this.spendingPlansFull.plans
					.filter(plan => plan.plan !== null)
					.map(plan => {
						return {
							classification: plan.classification,
							plan: plan.plan
						};
					})
			};
		},

		_deletePlan(plan, planPage = true) {
			this._getSpendingPlansLite(null, /* refreshCache */ false, /* clearCache*/ true);
			this._getSpendingPlansFull(null, /* refreshCache */ false, /* clearCache*/ true);
			this._deleteSpendingPlan(plan.plan.id, () => {
				this._getSpendingPlansFull(() => {
					this._extractLitePlansFromFullPlans();
					this._setupCategories();
					this._updateSelectedTotals();
					if (planPage) {
						this.getPlanTotals();
						this.animateEveryDaySpendingProgress(this.everyDaySpendingPlanProgressPercent);
						this.animateFuturePlansProgress(this.futurePlansProgressPercent);
						this.animateCommittedCostsProgress(this.committedCostsProgressPercent);
						this.categoriesPanel = this.spendingPlansFull.plans.filter(plan => plan.classification.grandParentClassification === this.currentCategoryId);
					}
					this.ready = true;	
				}, true);
			});
			this.managePlan = false;
			if (this.closeDrawerNext) {
				this.$refs.transactionDrawer.closeDrawer();
				this.closeDrawerNext = false;
			}
			this._setFieldValue('Plan amount', 0)
		},

	}
}