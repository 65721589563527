export default {

	data() {
		return {
			sections: 
			[
				{
					fields: [
						{
							validationText: 'Please choose a credit type',
							label: 'Type',
							type: 'dropdown',
							items: this.creditTypes,
							value: null,
							required: true
						},
						{
							validationText: 'Please select a provider',
							label: 'Provider',
							type: 'dropdown',
							items: this.creditProviders,
							value: null,
							required: true
						}	,
						{
							validationText: 'Please enter the account number',
							label: 'Account number',
							type: 'text',
							items: this.accountNumber,
							value: null,
							required: true
						},						
						{
							validationText: 'Please select ownership type',
							label: 'Ownership',
							type: 'select',
							items: ['Sole', 'Joint'],
							required: true
						},
					]
				},
				{
					fields: [
						{
							validationText: 'Please enter the start date',
							label: 'Start date',
							type: 'datetext',
							value: null,
							required: true,
							past: true
						},
						{
							validationText: 'Please enter the term',
							label: 'Term (months)',
							type: 'number',
							value: null,
							required: true
						},
						{
							validationText: 'Please enter the balance',
							label: 'Balance',
							type: 'currency',
							value: null,
							required: true
						},
					]
				}
			]
		}
	},
}