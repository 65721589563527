<template>
	
	<div class="inputSections" :class="pageTheme">
		<h1 v-if="section.title">{{ section.title }}</h1>
		<p v-if="section.subtitle" v-html="section.subtitle"></p>
		<div v-for="f in section.fields" :key="f">
			<v-text-field v-if="['password'].includes(f.type.toLowerCase())"
				v-model="f.value"
				:type="showPasswords ? 'text' : 'password'"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:append-icon="showPasswords ? 'mdi-eye' : 'mdi-eye-off'"
				@click:append="showPasswords = !showPasswords"
				:clearable="!determineDisabled(f)"
				singl-e-line
				@keydown="handleKeydown(f, $event)"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-text-field>
			<v-text-field v-if="['number', 'text', 'email'].includes(f.type.toLowerCase())"
				v-model="f.value"
				:type="f.type"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				singl-e-line
				@keydown="handleKeydown(f, $event)"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-text-field>
			<v-text-field v-if="['accountnumber'].includes(f.type.toLowerCase())"
				v-model="f.value"
				type="number"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				singl-e-line
				@keydown="handleKeydown(f, $event)"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-text-field>
			<v-text-field v-if="['currency'].includes(f.type.toLowerCase())"
				v-model="f.value"
				type="number"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				prefix="£"
				placeholder="0.00"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				s-ingle-line
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-text-field>
			<v-text-field v-if="['percent'].includes(f.type.toLowerCase())"
				v-model="f.value"
				type="number"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				suffix="%"
				placeholder="0"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				si-ngle-line
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-text-field>
			<v-select v-if="f.type == 'select'"
				v-model="f.value"
				:items="f.items"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				si-ngle-line
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			>
			</v-select>
			<v-autocomplete v-if="f.type == 'dropdown'"
				v-model="f.value"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				:items="f.items"
				si-ngle-line
				:label="f.label + ' ' + (f.required ? '*' : '')"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-autocomplete>
			<v-date-input v-if="f.type == 'date'"
				v-model="f.value"
				variant="solo"
				prepend-icon=""
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				sin-gle-line
				placeholder="DD/MM/YYYY"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-date-input>
			<v-text-field v-if="['datetext'].includes(f.type.toLowerCase())"
				v-model="f.value"
				:type="f.type"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				sin-gle-line
				@keyup="handleKeyup(f, $event)"
				@keydown="handleKeydown(f, $event)"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-text-field>
			<v-text-field v-if="['sortcode'].includes(f.type.toLowerCase())"
				v-model="f.value"
				:type="f.type"
				:label="f.label + ' ' + (f.required ? '*' : '')"
				variant="solo"
				:disabled="determineDisabled(f)"
				:flat="true"
				:clearable="!determineDisabled(f)"
				sin-gle-line
				@keyup="handleKeyup(f, $event)"
				@keydown="handleKeydown(f, $event)"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'"
			></v-text-field>
			<div v-if="['radio'].includes(f.type.toLowerCase())">
				<p>{{ f.label }}:</p>
				<v-radio-group v-model="f.value" inline>
					<v-radio v-for="i in f.items" :key="i" :label="i" :value="i"></v-radio>
				</v-radio-group>
			</div>
			<v-checkbox class="check" v-if="f.type == 'check'"
				v-model="f.value"
				:label="f.label"
				:bg-color="pageTheme == 'dark' ? '#ffffffaa' : '#3328470D'" 
			/>
			<AddressLookup v-if="f.type == 'address'" 
				:theme="pageTheme" 
				:showNumber="false" 
				:value="f.value"
				v-model="f.value"
			/>
			<div class="small centered" v-if="f.link"><a :href="f.link">{{ f.linkLabel }}</a></div>
			<p v-if="f.subtitle" class="small">{{ f.subtitle }}</p>
		</div>
	</div>
	
</template>

<script>

import { VDateInput } from 'vuetify/labs/VDateInput'
import AddressLookup from './AddressLookup.vue';

export default {
	name: "InputSections",
	components: { VDateInput, AddressLookup },
	props: ['formatter', 'sections', 'theme', 'editMode'],
	data() {
		return{
			sectionNo: 0,
			lastSectionNo: 0,
			pageTheme: this.theme,
			showPasswords: false
		}
	},
	computed: {
		section() {
			return this.sections[this.sectionNo];
		}
	},
	methods: {
		onLoad() {
			if (!this.pageTheme) { this.pageTheme = 'dark'; }
		},

		next(sectionNumber) {
			this.sectionNo = sectionNumber;
		},

		back(sectionNumber)	{
			this.sectionNo = sectionNumber;
		},
		handleKeyup(field, event) {
			const type = field.type.toLowerCase();
			if (type === 'datetext' && event.key !== 'Backspace' && field.value && (field.value.length == 2 || field.value.length == 5)) {
				field.value += '/';
			} else if (type === 'sortcode' && event.key !== 'Backspace' && field.value && (field.value.length == 2 || field.value.length == 5)) {
				field.value += '-';
			}
			// // validate on keyup if the keydown hasn't done it (as on devices like iOS, keydown doesn't prevent invalid characters)
			// if (['datetext', 'sortcode', 'currency', 'percent', 'accountnumber'].includes(type)) {
			// 	if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && (type !== 'currency' && type !== 'percent' || event.key !== '.')) {
			// 		event.preventDefault();
			// 		field.value = field.value.slice(0, -1);
			// 	}
			// 	if ((type === 'datetext' && field.value?.length > 10) || (type === 'sortcode' && field.value?.length > 8) || (type === 'accountnumber' && field.value?.length > 8)) {
			// 		if (event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
			// 			event.preventDefault();
			// 			field.value = field.value.slice(0, -1);					
			// 		}
			// 	}
			// 	if (type === 'currency' || type === 'percent') {
			// 		const value = field.value || '';
			// 		const decimalIndex = value.indexOf('.');
			// 		if (decimalIndex !== -1 && value.length - decimalIndex - 1 > 2 && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
			// 			event.preventDefault();
			// 			field.value = field.value.slice(0, -1);
			// 		}
			// 	}
			// }

		},
		handleKeydown(field, event) {
			const type = field.type.toLowerCase();
			if (['datetext', 'sortcode', 'currency', 'percent', 'accountnumber'].includes(type)) {
				if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight' && (type !== 'currency' && type !== 'percent' || event.key !== '.')) {
					event.preventDefault();
				}
				if ((type === 'datetext' && field.value?.length >= 10) || (type === 'sortcode' && field.value?.length >= 8) || (type === 'accountnumber' && field.value?.length >= 8)) {
					if (event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
						event.preventDefault();
					}
				}
				if (type === 'currency' || type === 'percent') {
					const value = field.value || '';
					const decimalIndex = value.indexOf('.');
					if (decimalIndex !== -1 && value.length - decimalIndex - 1 >= 2 && event.key !== 'Backspace' && event.key !== 'Tab' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
						event.preventDefault();
					}
				}
			}
		},
		determineDisabled(field) {
			if (field.readOnly && this.editMode) {
				return true;
			}
			return false;
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

h1 {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 20px;
}

p {
	margin-bottom: 15px;
}


</style>