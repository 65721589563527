<template>
	<div class="accounts-widget">
		<v-row>
			<v-col class="title-col">
				{{ title ? title : 'My Accounts' }}
			</v-col>
			<v-col cols="5" class="see-all-col" v-if="!hideSeeAll">
				<a href="#/aiop">See All</a>
			</v-col>
		</v-row>
		<div class="restricted-height">
			<v-row v-for="a in accounts_" :key="a.id" class="mt-5" @click="$router.push('account/' + a.id)">
				<v-col class="v-col-2 icon-col">
					<img class="icon" :style="'background-color: #' + (a.bank.hexColour2 ? a.bank.hexColour2.substring(2) : '332847')" :src="a.bank.logoPath ? (require('@/assets/logos/' + a.bank.logoPath)) : require('@/assets/round1.png')" />
				</v-col>
				<v-col class="account-name-col">
					{{ a.bank.prettyName ?? a.bank.name }}<br/>
					<span>{{ a.accountType.prettyName }}</span>
				</v-col>
				<v-col v-if="!hideBalance" class="account-balance-col">
					{{ formatter.format(Math.round(a.balance)) }}
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>

export default {
	name: "AccountsWidget",
	components: { },
	props: ['accounts', 'formatter', 'title', 'hideSeeAll', 'hideBalance', 'unique'],
	data() {
		return{
			accounts_: []
		}
	},
	methods: {
		onLoad() {
			if (this.unique) {
				var providers = [];
				this.accounts.forEach(a => {
					if (!providers.find(p => p == a.bankDisplayName)) {
						providers.push(a.bankDisplayName);
						this.accounts_.push(a);
					}
				});
			} 
			else {
				this.accounts_ = this.accounts;
			}
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.accounts-widget {
	border-radius: 8px;
	padding: 18px;
	padding-bottom: 30px;
	border-color: var(--border-color);
	border: 1px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
}

.r--estricted-height {
	max-height: 97px;
	overflow-y: scroll;
	overflow-x: hidden;
}

.title-col {
	font-size: 20px;
}

.see-all-col {
	text-align: right;
	padding-left: 0px;
	padding-top: 15px;
}

.arrow {
	width: 18px;
}

.icon-col {
	padding-top: 2px;
	padding-bottom: 0px;
}

.see-all-col {
	padding-top: 20px;
	a {
		text-decoration: underline;
	}
}

.icon {
	width: 30px;
	b--ackground: linear-gradient(230deg, #E7ECF7 16.24%, #EAEDFF 84.93%);
	border-radius: 50%;
}

.account-name-col {
	font-size: 12px;
	padding-top: 0px;
	padding-bottom: 0px;
	span {
		font-size: 10px;
	}
}

.account-balance-col {
	font-size: 12px;
	text-align: right;
	padding-top: 8px;
	padding-bottom: 0px;
}


</style>