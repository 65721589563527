<template>
	<header-block title="" border="0" bgcolor="transparent"></header-block>
	<CustomerLoadWait :customer="customer || error"/>
	<transition>
		<div v-if="customer" class="simplePage">
			<h1>Verify your email</h1>
			<img class="heroImg" src="../assets/email.png" />
			<p class="onlyTallScreen">Please check the email we have sent to {{ registerUser.email }}</p>
			<p>Please enter the 6 digit code you were emailed. (Don't forget to check your Junk/Spam folders.)</p>
			<v-otp-input class="codeField"
				v-model="code"
				type="number"
				:label="xxxxxx"
				variant="solo"
				:flat="true"
				single-line
				bg-color="#ffffffaa"
			></v-otp-input>
			<div class="anchor-bottom centered">
				<MGButton v-if="!loggingIn" text="Verify" :alt="true" @click="verifyCode" :arrow="true"/>
				<v-progress-circular v-if="loggingIn" size="30" width="3" indeterminate />
			</div>
		</div>
	</transition>
	<transition>
		<div v-if="regError" class="simplePage">
			<h1>Sorry, we ran into a problem</h1>
			<img class="heroImg" src="../assets/tip.png" />
			<p>We are sorry, something went wrong, please contact support.  The technical detail was: <br/><br/><code>{{ regError }}</code></p>
			<div class="anchor-bottom">
				<MGButton text="Try again" :alt="true" @click="$router.push({path: '/personaldetails'})" :arrow="true"/>
			</div>
		</div>
	</transition>
	<v-dialog max-width="500" v-if="pinError" v-model="pinError">
		<v-card>
			<v-card-title>Invalid Code</v-card-title>
			<v-card-text>Are you sure you entered the code correctly?</v-card-text>
			<v-card-actions>
				<v-btn @click="pinError = false">Try Again</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {MGButton, HeaderBlock, CustomerLoadWait},
	data() {
		return {
			registerUser: null,
			regError: false,
			pinError: false,
			code: null,
			loggingIn: false,
			error: false,
		}
	},
	
	methods: {
		onLoad: async function() {
			this.registerUser = localStorage.getItem('registerUser');
			if (!this.registerUser) {
				this.$router.push({path: '/getstarted'});
			}
			this.registerUser = JSON.parse(this.registerUser);
			// Ensure phone number is in the format +447429386911
			if (!this.registerUser.phone.startsWith('+44')) {
				this.registerUser.phone = '+44' + this.registerUser.phone.replace(/^0/, '');
			}
			console.log(this.registerUser);
			var address = {
				postCode: this.registerUser.postCode
			}
			var customer = {
				person: {
					firstName: this.registerUser.firstName,
					lastName: this.registerUser.lastName,
					mobilePhone: this.registerUser.phone,
					email: this.registerUser.email,
					dob: this.registerUser.dob,
					addresses: [address]
				},
				employerID: this.registerUser.employerId,
			}
			console.log(customer, 'Customer to register');
			let token = this.$cookies.get('token');
			if (token) {
				customer.token = token;
			}
			this._registerCustomer(customer, this.registerUser.password, token, (r) => {
				this.$cookies.remove('token');
				console.log(r, 'Register customer result');
				if (r.success == true)
				{
					this.customer = r.customer;
					this.$cookies.set('user', this.registerUser.email, '1m');
					this.$cookies.set('pass', this.registerUser.password, '1m');
					localStorage.removeItem('registerUser');
				}
				else {
					console.log(r.message);
					this.regError = r.message;
				}
			})
		},
		verifyCode: function() {
			this._verifyEmail(this.customer.person.email, this.code, (r) => {
				if (r.success == true)
				{
					this._login(this.registerUser.email, this.registerUser.password, () => {
						this.loggingIn = true;
						// If they chose to enable whatsapp on the phone number screen, do it 
						// now as we now have the customer object
						if (this.registerUser.enableWhatsApp) {
							this._enableWhatsApp();
						}
						this.$router.push({path: '/setpin'});
					});
				}
				else {
					console.log(r.message);
					this.pinError = true;
					this.code = null;
				}
			})
		}
	},
	mounted: function () {
		this.onLoad();
	}
}
</script>

<style scoped>

.simplePage {
	color: white;
}

.codeField {
	margin-right: auto;
	margin-left: auto;
}

</style>
