<template>
	<HeaderBlock title="Financial Fitness Survey" />
	
	<transition>
		<div class="centered mt-10">
			<FinancialFitnessScore :mini="true"
				:score="financialFitness.score" 
				:dayToDay="financialFitness.dayToDay" 
				:future="financialFitness.future" 
				:security="financialFitness.security" 
				:started="financialFitness.started" 
			/>
			<p class="mt-4">Thanks for taking the financial fitness survey. Powered by Money Helper. Your overall score is above and the breakdown of your progress is below.</p>
			<FinancialFitnessBars 
				:dayToDay="financialFitness.dayToDay" 
				:future="financialFitness.future" 
				:security="financialFitness.security" 
				:started="financialFitness.started" 
			/>
		</div>
	</transition>
	<div class="anchor-bottom centered">
		<v-btn class="mg-button" @click="reTakeSurvey">Retake the Survey</v-btn>
		<v-btn class="mt3 linkBtn" @click="viewHistory" variant="text">View my previous scores</v-btn>
		<v-divider class="mt-3" />
		<v-row class="footerInfo">
			<v-col cols="4"><img src="../assets/moneyHelper.png" /></v-col>
			<v-col>Helping people to improve their financial wellbeing and build a better, more confident future.</v-col>
		</v-row>
	</div>
</template>

<script>

import Services from '../mixins/Services';
import FinancialFitnessBars from './elements/FinancialFitnessBars.vue';
import FinancialFitnessScore from './elements/FinancialFitnessScore.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, FinancialFitnessScore, FinancialFitnessBars},
	data() {
		return {
			financialFitness: { score: 0, started: 0, dayToDay: 0, security: 0, future: 0 },
			about: false,
			interval: null,
			loaded: false
		}
	},
	methods: {
		onLoad: async function() {
			this._generateFinacialFitness(null, /* clearCache */ true);
			this._generateFinacialFitness((fitness) => {
				console.log('here');
				if (this.interval) { clearInterval(this.interval); }
					this.financialFitness = null;
					this.financialFitness = fitness;
					this.loaded = true;
			});
			if (!this.loaded) {
				this.interval = setInterval(() => {
					const min = 0;
					const max = 3;
					this.financialFitness.score += Math.round(Math.random() * (max - min) + min);
					this.financialFitness.started += Math.round(Math.random() * (max - min) + min);
					this.financialFitness.dayToDay += Math.round(Math.random() * (max - min) + min);
					this.financialFitness.security += Math.round(Math.random() * (max - min) + min);
					this.financialFitness.future += Math.round(Math.random() * (max - min) + min);
					if (this.financialFitness.score > 100) { this.financialFitness.score = 0; }
					if (this.financialFitness.started > 100) { this.financialFitness.started = 0; }
					if (this.financialFitness.dayToDay > 100) { this.financialFitness.dayToDay = 0; }
					if (this.financialFitness.security > 100) { this.financialFitness.security = 0; }
					if (this.financialFitness.future > 100) { this.financialFitness.future = 0; }
				}, 100);
			}
		},
		reTakeSurvey: function() {
			this.$router.push({path: '/FinancialFitness'});
		},
		viewHistory: function() {
			this.$router.push({path: '/FinancialFitnessHistory'});
		}
	},
	mounted: function () {
		this.onLoad();
	},
	unmounted: function () {
		if (this.interval) { clearInterval(this.interval); }
	}
}
</script>

<style scoped>

.footerInfo {
	font-size: 12px;
	text-align: left;
	margin-top: 10px;
	img{
		width: 100px;
		margin-top: 5px;
	}
}

.linkBtn {
	color: var(--text-color);
	text-transform: none !important;
	font-size: 12px;
}



</style>
