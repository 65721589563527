<template>
	<header-block title="" border="0" bgcolor="transparent"></header-block>

	<div class="simplePage">
		<p>Welcome to </p>
		<h1>{{ employerName }}</h1>
		<p>Great news, {{ employerName }} is one of our supported employers.</p>
		<img v-if="logo && logo != ''" class="smallHeroImg" :src="logo" />
		<img v-if="!logo || logo == ''" class="smallHeroImg" src="https://s3.eu-west-2.amazonaws.com/content.sysodev.link/1a813d88-19f8-4cc8-9ac2-c7b3ba308c74.svg" />
		<p>An email has been sent to your work account.  Please open the email and click on the verification link. (Don't forget to check your Junk/Spam folders.)</p>
		<div class="anchor-bottom">
			<a href="mailto:"><MGButton text="Open Email" /></a>
			<div class="mt-3" @click="$router.back()">I didn't receive an email</div>
		</div>
	</div>

</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {MGButton, HeaderBlock },
	data() {
		return {
			employerName: null,
			logo:null,
			interval: null
		}
	},
	
	methods: {
		onLoad: async function() {
			this.employerId = this.$cookies.get('employerId');
			this.employerName = this.$route.params.employer;
			this._getEmployerLogo(this.employerId, (logo) => {
				this.logo = logo;
			});
			this.interval = setInterval(() => {
				this.awaitValidation();
			}, 3000);
		},
		awaitValidation() {
			var emailVerificationId = this.$route.params.id;
			this._emailIsVerified(emailVerificationId, () => {
				if (this.interval) { clearInterval(this.interval); }
				this.$router.push({path: '/eligibilityverified'});
			});
		},
	},
	mounted: function () {
		this.onLoad();
	},
	unmounted: function () {
		if (this.interval) { clearInterval(this.interval); }
	}
}
</script>

<style scoped>



</style>
