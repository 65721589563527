export default {

	data() {
		return {
			sections: 
			[
				{
					fields: [
						{
							validationText: 'Please select ownership type',
							label: 'Ownership',
							type: 'select',
							items: ['Sole', 'Joint'],
							required: true
						},
						{
							validationText: 'Please select a provider',
							label: 'Provider',
							type: 'dropdown',
							items: this.creditProviders,
							value: null,
							required: true
						},
						{
							validationText: 'Please enter the account number',
							label: 'Account number',
							type: 'text',
							items: this.accountNumber,
							value: null,
							required: true
						},
						{
							validationText: 'Please enter the balance',
							label: 'Balance',
							type: 'currency',
							value: null,
							required: true
						}
					]
				},
				{
					fields: [
						{
							validationText: 'Please enter monthly repayment',
							label: 'Monthly Repayment',
							type: 'currency',
							value: null,
							required: true,
						},
						{
							validationText: 'Please enter the date opened',
							label: 'Date opened',
							type: 'datetext',
							value: null,
							required: true,
							past: true
						},
						{
							validationText: 'Please enter the term in years',
							label: 'Term (Years)',
							type: 'number',
							value: null,
							required: true,
						}
					]
				},
				{
					fields: [
						{
							id: 'rateType',
							validationText: 'Please choose the rate type',
							label: 'Type',
							type: 'dropdown',
							items: ['Fixed', 'Standard Variable', 'Discount Tracker'],
							required: true
						}
					]
				},
				{
					rule: 'rateType: Fixed, Discount Tracker',
					fields: [
						{
							validationText: 'Please enter the deal end date',
							label: 'Deal end date',
							type: 'datetext',
							value: null,
							required: true,
							future: true
						},
						{
							validationText: 'Please enter the deal rate',
							label: 'Deal rate',
							type: 'percent',
							value: null,
							required: true,
						},
						{
							validationText: 'Please enter the interst rate when deal ends',
							label: 'Interest rate when deal ends',
							type: 'percent',
							value: null,
							required: true,
						}
					]
				},
				{
					rule: 'rateType: Standard Variable',
					fields: [
						{
							validationText: 'Please enter interest rate',
							label: 'Interest rate',
							type: 'percent',
							required: true,
							value: null
						},
					]
				},
				{
					fields: [
						{
							validationText: 'Please select the address',
							label: 'Address',
							type: 'address',
							value: null,
							required: true
						}
					]
				}
			]
		}
	},
}