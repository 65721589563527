<template>
	<div :class="'move-panel'" @click="$router.push('/Moves/' + customerMove.id)">
		<v-row>
			<v-col class="icon-col">
				<img :src="move.thumbnail"/>
			</v-col>
			<v-col class="right">
				<v-chip v-if="customerMove.isPending" variant="outlined" size="small">Not started</v-chip>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="title-col">
				<div class="title">{{ customerMove.title }}</div>
				<div v-if="!lite" class="tag-line">{{ move.tagLine }}</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col><v-chip v-if="!customerMove.isPending" variant="outlined" size="small">Completed</v-chip> <v-chip v-if="!lite" class="ml-1 feature-pill" variant="outlined" size="small">{{ move.featureTags }}</v-chip></v-col>
		</v-row>
		<v-row v-if="!lite">
			<v-col>
				<div class="description">{{ customerMove.description }}</div>
			</v-col>
		</v-row>
		<v-row class="bottom-panel mt-1">
			<v-col class="v-col-1 timeCol">
				<img :src="require('@/assets/clock.png')">
			</v-col>
			<v-col>
				{{ move.timeToComplete }} minutes
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "MovePanel",
	props: ['move', 'customerMove', 'lite'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

body {
	background-color: var(--alt-color) !important;
}

.move-panel {
	border-radius: 8px;
	padding: 18px;
	p--adding-bottom: 0px;
	border-color: var(--border-color);
	border: 1px solid var(--border-color);
	background-color: var(--background-color);
	width: 100%;
}

.title-col {
	padding-bottom: 0px;
}

.title {
	font-size: 16px;
	min-height: 60px;
}

.tag-line {
	font-size: 14px;
	opacity: 0.5;
}

.icon-col {
	padding-bottom:0px;
}

.icon-col img {
	height: 40px;
}

.description {
	font-size: 13px;
	font-weight: normal;
	margin-bottom: 20px;
}

.feature-pill {
	background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%);
	border:1px;
}

.bottom-panel {
	padding-top: 5px;
	margin-right: 0px;
	margin-left: 0px;
	border-top: 1px solid var(--border-color);
	.v-col {
		padding-left: 0px;
		padding-right: 0px;
	}
}

.v-align-middle {
	vertical-align: text-top !important;
}

</style>