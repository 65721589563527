<template>
	<header-block v-if="customer" title="Completed moves" bgcolor="white"></header-block>
	<CustomerLoadWait :customer="completedMoves" />
	<transition>
		<div v-if="completedMoves" class="completedMoves">
			<div>
				<v-row class="moveRow" v-for="m in completedMoves" :key="m.id">
					<v-col cols="1" class="iconCol">
						<img class="moveIcon" :src="m.move.backgroundImage" />
					</v-col>
					<v-col>
						<a :href="'/#/Moves/' + m.id">
							<h2>{{ m.title }}</h2>
							<p>{{ m.description }}</p>
							<div class="moveMeta">
								{{ (m.actioned ? 'Completed ' + ( m.actionedCount > 0 ? m.actionedCount + ' time(s)' : '') : 'Ignore for now') }}
								<span>{{ this._dateToString(m.actioned) }}</span>
							</div>
						</a>
					</v-col>
				</v-row>
			</div>
			<div class="moveAd">
				<h1>There's a Move for that</h1>
				<p>We want to hear what moves matter to you. Email us at moves@moneyguided.com with any ideas!</p>
			</div>
		</div>
	</transition>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import Helpers from '../mixins/Helpers';

export default {
	mixins: [Services, Helpers],
	components: {CustomerLoadWait, HeaderBlock},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			
			this._getCustomer();
			this._getCompletedMoves((res) => {
				this.completedMoves = res.sort((a, b) => new Date(b.actioned) - new Date(a.actioned));
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.completedMoves {
	margin-left: -30px;
	margin-right: -30px;
	span {
		float: right;
	}
	h2 {
		font-size: 16px;
	}
	.moveMeta{
		font-weight: bold;
	}
	.moveRow{
		padding-left: 30px;
		padding-right: 30px;
		border-bottom: 1px solid var(--border-color);
	}

	.moveRow:last-child {
		border-bottom: none;
	}
	
	.iconCol {
		padding-right: 30px;
	}
	
	.moveIcon {
		width: 24px;
		border-radius: 50%;
	}
	.moveAd{
		margin-top: 20px;
		background-color: var(--text-color);
		color: white;
		padding: 25px;
		h1{
			font-size: 20px;
		}
	}
}


.middleAlign {
	margin-top:auto;
	margin-bottom:auto;
}

</style>
