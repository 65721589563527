<template>
	<a :href="href != null ? href : $router.path">
		<div class="ad-button" :style="'background-image:url(' + bottomrighticon + '); ' + 'background-color: ' +(barColor ? barColor : 'unset')">
			<div class="image" :style="'background-image: url(' + image + ')'"></div>
			<div class="table">
				<v-row>
					<v-col class="title-col v-col-10">
						<div class="title">{{ title }}</div>
					</v-col>
					<v-col class="arrow-col v-col-2">
						<img class="arrow" src="../../assets/arrow-right.png">
					</v-col>
				</v-row>
			</div>
		</div>
	</a>
</template>

<script>
export default {
	name: "CategoryButton",
	props: ['image', 'title' , 'href', 'barColor'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.ad-button {
	border-radius: 16px;
	padding: 18px;
	border-color: var(--border-color);
	b--order: 1px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	padding: 0px;
	box-shadow: 0px 4px 8px 0px rgba(51, 40, 71, 0.05);
}

.table {
	padding: 25px;
}

.title-col{
	padding: 0px;
	margin-top:auto;
	margin-bottom:auto;
}

.arrow-col {
	padding: 0px;
	margin-top:auto;
	margin-bottom:auto;
}

.title {
	max-width: 100%;
	font-size: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.image {
	background-image: unset;
	background-position: bottom;
	background-size: cover;
	height: 150px;
	border-radius: 16px 16px 0px 0px;
}

.arrow-col {
	text-align: right;
}

.arrow {
	height: 8px;
}

</style>