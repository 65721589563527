<template>
	<header-block :del="true" @delete="deleteAccount" :title="'Loan'" :edit="'/Credit/Edit/' + this.$route.params.id"></header-block>
	<CustomerLoadWait :customer="creditAccount" />
	<transition>
		<div v-if="creditAccount">
			<WellPanel 
				:title="creditAccount.provider.name" 
				subTitle="Provider"
				:avatarLetter="creditAccount.provider.name.charAt(0)"
				:data="wellData"
			/>
			
		</div>
	</transition>
	<DrawerPanel bottom="-18px" openHeight="450px" title="Saved documents" backgroundColor="white" openBackgroundColor="white">
		<div class="docsPanel" v-if="documents">
			<a v-for="d in documents" :key="d.id" :href="'https://customer-documents-sysodev.s3.eu-west-1.amazonaws.com/' + d.awsKey">
				<v-row>
					<v-col cols="1"><v-icon class="docIcon" icon="mdi-file-document-outline"></v-icon></v-col>
					<v-col class="docCol">{{ d.name }}<br/><span></span></v-col>
					<v-col cols="1" class="arrowCol"><img class="arrow" src="../assets/arrow-right.png" /></v-col>
				</v-row>
			</a>
		</div>
	</DrawerPanel>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import WellPanel from './elements/WellPanel.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, WellPanel, DrawerPanel},
	data() {
		return {
			policyId: null,
			wellData: []
		}
	},
	methods: {
		onLoad: async function() {
			this.policyId = this.$route.params.id;
			this._getCustomer();
			this._getCreditAccount(this.policyId, () => {
				this.wellData = [
					{order: 1, label: 'Type', value: this.creditAccount.creditType.name},
					{order: 2, label: 'Provider', value: this.creditAccount.provider.name},
					{order: 3, label: 'Account number', value: this.creditAccount.reference},
					{order: 4, label: 'Balance', value: this.formatter.format(this.creditAccount.balance.amount)},
					{order: 5, label: 'Ownership', value: this.creditAccount.jointStatus === 2 ? 'Joint' : this.creditAccount.jointStatus === 1 ? 'Sole' : null},
					{order: 6, label: 'Start date', value: this._dateToString(this.creditAccount.creditCreateDate)},
					{order: 7, label: 'Term (months)', value: this.creditAccount.termMonths},
				];
				this._getDocumentsByHubId(this.creditAccount.id);
			});
		},		
		deleteAccount: function(){
			this._deleteCreditAccount(this.$route.params.id, () => {
				this._getCreditInfo(/*onSuccess*/ null, /*refreshCache*/ true);
				this.$router.push('/aiop');
			});
		},
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.docsPanel {
	padding-right: 20px;
}

.docIcon {
	c--olor: var(--grey-text);
}

.docCol {
	font-size: 14px;
	c--olor: var(--grey-text);
}

.arrowCol {
	padding-top: 15px;
}

.arrow {
	width: 18px;
	
}

</style>
