<template>
	<HeaderBlock title="Financial Fitness" />
	<transition>
		<div v-if="financialFitnessHistory && financialFitnessHistory[0]">	
			<h1 v-if="!scoreId">Your current score</h1>
			<h1 v-if="scoreId">Your score</h1>
			<h2 v-if="scoreId">On {{ this.dateFormatPretty.format(new Date( financialFitnessHistory[0].createDateTime))  }}</h2>
			<FinancialFitnessScore class="mt-5" :dayToDay="financialFitnessHistory[0].dayToDay" :future="financialFitnessHistory[0].future" :security="financialFitnessHistory[0].security" :started="financialFitnessHistory[0].started" :score="financialFitnessHistory[0].score" />
			<FinancialFitnessBars :dayToDay="financialFitnessHistory[0].dayToDay" :future="financialFitnessHistory[0].future" :security="financialFitnessHistory[0].security" :started="financialFitnessHistory[0].started" />
		</div>
	</transition>
	<transition>
		<div class="mt-5 anchor-bottom" v-if="financialFitnessHistory && financialFitnessHistory[1] &&!scoreId">
			<a @click="showScore(financialFitnessHistory[1].id)">
				<v-row>
					<v-col cols="4">
						<FinancialFitnessScore class="mt-2" mini="true" :dayToDay="financialFitnessHistory[1].dayToDay" :future="financialFitnessHistory[1].future" :security="financialFitnessHistory[1].security" :started="financialFitnessHistory[1].started" :score="financialFitnessHistory[1].score" />
					</v-col>
					<v-col class="previousDetail">
						Your previous score<br/>
						<span>{{ this.dateFormatPretty.format(new Date( financialFitnessHistory[1].createDateTime))  }}</span>
					</v-col>
				</v-row>
			</a>
		</div>
	</transition>
</template>

<script>

import Services from '../mixins/Services';
//import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import FinancialFitnessBars from './elements/FinancialFitnessBars.vue';
import FinancialFitnessScore from './elements/FinancialFitnessScore.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, /*CustomerLoadWait,*/ FinancialFitnessScore, FinancialFitnessBars},
	data() {
		return {
			financialFitnessHistory: null,
			scoreId: null,
		}
	},
	methods: {
		onLoad: async function() {
			if (this.$route.params.id) {
				this.scoreId = this.$route.params.id;
			}
			this._getFinancialFitness(null, /* clearCache */ true);
			this._getFinancialFitness((fitness) => {
				this.financialFitnessHistory = fitness;
				if(this.scoreId) {
					this.financialFitnessHistory = this.financialFitnessHistory.filter((f) => f.id === this.scoreId);
				}
				// this.financialFitnessHistory[0].started = 100;
				// this.financialFitnessHistory[0].dayToDay = 100;
				// this.financialFitnessHistory[0].future = 100;
				// this.financialFitnessHistory[0].security = 100;

			});
			
		},
		reTakeSurvey: function() {
			this.$router.push({path: '/FinancialFitness'});
		},
		showScore: function(id) {
			this.$router.push({path: '/FinancialFitnessHistory/' + id});
		}
	},
	mounted: function () {
		this.onLoad();
	}
}
</script>

<style scoped>

h1 {
	text-align: center;
	font-size: 24px;
}

h2 {
	text-align: center;
	font-size: 16px;
	font-weight: normal;
}

.footerInfo {
	font-size: 12px;
	text-align: left;
	margin-top: 10px;
	img{
		width: 100px;
		margin-top: 5px;
	}
}

.linkBtn {
	color: var(--text-color);
	text-transform: none !important;
	font-size: 12px;
}

.previousDetail {
	font-size: 14px;
	font-weight: bold;
	padding-top: 40px;
	padding-left: 30px;
	span {
		font-size: 12px;
		font-weight: normal;
	}
}



</style>
