<template>
	
	<div class="enableWhatsapp">
		<p>Add your mobile number to access Money Guided’s AI financial coach</p>
		<v-divider></v-divider>
		<v-switch color="#1dcd45" class="mt-0 switch" hide-details="auto" @change="enableWhatsapp ? $emit('on') : $emit('off')" v-model="enableWhatsapp" label="Enable AI coach via WhatsApp"></v-switch>
	</div>
	
</template>

<script>
export default {
	name: "EnableWhatsappWidget",
	props: ['formatter'],
	data() {
		return{
			enableWhatsapp: false
		}
	},
	computed: {
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.enableWhatsapp {
	border-radius: 20px;
	padding: 20px;
	padding-bottom: 40px;
	background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%);
	margin-top: 20px;
	margin-bottom: 20px;
	color: var(--text-color);
}


</style>