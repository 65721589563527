<template>
	<header-block title="" bgcolor="transparent" border="0"></header-block>
	<div class="personalDetails">
		<InputSections ref="inputSections" :sections="sections" />
		<div v-if="validationText" class="well">
			<p>{{ validationText }}</p>
		</div>
		<EnableWhatsAppWidget v-if="sectionNo==1" @on="enableWhatsApp = true" @off="enableWhatsApp = false"/>
		<div class="anchor-bottom">
			<MGButton :alt="true" text="continue" :arrow="true" @click="_inputControl_GoNext(registerUser)"/>
		</div>
	</div>
</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';
import EnableWhatsAppWidget from './elements/EnableWhatsAppWidget.vue';
import Helpers from '../mixins/Helpers';
import PersonalDetailsPageSections from './PersonalDetailsPageSections.js';

export default {
	mixins: [Services, Helpers, PersonalDetailsPageSections],
	components: {HeaderBlock, InputSections, MGButton, EnableWhatsAppWidget},
	data() {
		return {
			validationText: null,
			sectionNo: 0,
			enableWhatsApp: false,
		}
	},
	methods: {
		onLoad: async function() {
			if (!this.$cookies.get('employerId')) {
				this.$router.push({path: '/getstarted'});
			}
		},
		registerUser: function() {
			var userObject = {
				firstName:  this._findFieldValue('First Name'),
				lastName: this._findFieldValue('Last Name'),
				phone: this._findFieldValue('+44'),
				dob: this._stringToDate(this._findFieldValue('Date of birth')),
				postCode: this._findFieldValue('Postcode'),
				email: this._findFieldValue('Email address'),
				password: this._findFieldValue('Password'),
				employerId: this.$cookies.get('employerId'),
				enableWhatsApp: this.enableWhatsApp,
			}
			localStorage.setItem('registerUser', JSON.stringify(userObject));
			this.$cookies.remove('employerId');
			this.$router.push({path: '/createaccount'});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.personalDetails {
	color: white;
}

:deep(.check .v-label) {
	color: white !important;
}

.well {
	text-align: center;
	background-color: #ffffff33;
}

</style>
