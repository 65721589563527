<template>
	<header-block title="" border="0" bgcolor="transparent"></header-block>
	
	<div class="simplePage">
		<img class="heroImg" src="../assets/verified.png" />
		<h1>Great job!</h1>
		<p>You've tagged all of your outgoing transactions.  Keep it up.</p>
		<div class="anchor-bottom">
			<MGButton text="Done" @click="$router.replace({path: '/SpendingAnalysis'})" :arrow="false"/>
		</div>
		
	</div>

</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {MGButton, HeaderBlock},
	data() {
		return {
			employer: null,
			interval: null
		}
	},
	computed: {
		type() {
			return this.$route.query.type;
		}
	},
	methods: {
		onLoad: async function() {
			
		}
	},
	mounted: function () {
		this.onLoad();
	}
}
</script>

<style scoped>



</style>
