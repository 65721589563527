<template>
	<a :href="href != null ? href : $router.path">
		<div :class="'scroll-thumb-block ' + (blur ? 'blur' : '')">
			<v-row>
				
				<v-col class="title-col">
					<div class="title">{{ title }}</div>
					<div class="sub-title">{{ subtitle }}</div>
				</v-col>
				<v-col class="rightimage-col v-col-2" >
					<img :src="rightimage">
				</v-col>
			</v-row>
			<v-row>
				<v-slide-group>
					<a v-for="(t, idx) in thumbs" :key="idx" :href="t.url"><div class="thumb" :style="'background-image: url(' + t.img + ')'" /></a>
				</v-slide-group>
			</v-row>
			<div v-if="blur" class="comingSoon">Coming Soon</div>
		</div>
	</a>
</template>

<script>
export default {
	name: "ScrollThumbBlock",
	props: ['title', 'subtitle', 'thumbs', 'rightimage', 'blur', 'href'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.scroll-thumb-block {
	border-radius: 8px;
	padding: 18px;
	border: 1px solid var(--border-color);
	background-color: var(--background-color);
}

.title {
	font-size: 16px;
}

.sub-title {
	font-size: 12px;
}

.thumb {
	height: 60px;
	width: 60px;
	margin: 8px;
	border-radius: 8px;
	border: 1px solid var(--border-color);
	display: inline-block;
	background-position: center;
	background-size: cover;
}

.rightimage-col {
	text-align: right;
}

.rightimage-col img {
	height: 12px;
}

.comingSoon {
	text-align: center;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: -18px;
	margin-top: 16px;
	border-top: 1px solid var(--border-color);
	margin-right: -18px;
	margin-left: -18px;
	background-color: #FEF9F4;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	font-size: 10px;
	background: linear-gradient(180deg, #FEF9F4 0%, #F7F1F1 100%);
}

</style>