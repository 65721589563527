<template>
	<div class="transactions-tagging" @click="event => {event.stopPropagation()}">


		<transition>
			<div class="well centered" v-if="suggestedClassification && showSuggestion">
				<h1>Is this {{ suggestedClassification.fullName }}?</h1>
				<p>Does this correctly categorize your transaction? Your feedback helps improve accuracy for you and other users.</p>
				<v-row class="mt-3">
					<v-col class="centered"><MGButton color="green" :circle="true" icon="mdi-thumb-up" @click="acceptAutoSuggest()" /></v-col>
					<v-col class="centered"><MGButton color="red" :circle="true" icon="mdi-thumb-down" @click="showSuggestion = false" /></v-col>
				</v-row>
			</div>
		</transition>

		<div v-for="(c, index) in this.allCategories" :key="c.id">
			<v-divider class="mt-3" v-if="index > 0" />
			<h2>{{ c.name }}</h2>
			<div v-for="category in c.categories" :key="category.id" class="hubItemWrapper">
				<!-- This is a parent, no children -->
				<div v-if="category.mgClassification.mgSubClassification == null && category.children.length == 0" >
					<v-btn 
					@click="handleCategoryButtonClick(category)" 
					variant="flat" 
					class="categoryBtn"
					:style="getButtonStyle(category, c.colour)">
						<img v-if="category.mgClassification.iconPath" 
						:src="require('@/assets/transactions/' + category.mgClassification.iconPath.toLowerCase().replace('.svg', '.png'))" 
						class="icon" 
						:style="'background-color: ' + c.colour">
						<div v-else class="icon-placeholder" :style="'background-color: ' + c.colour"></div>
						<span class="categoryName">{{ category.mgClassification.fullName }}</span>
					</v-btn>
				</div>
				<!-- this is a parent, with children.  The dialog that opens will contain the parent along with the children -->
				<v-dialog v-if="category.children.length > 0">
					<template v-slot:activator="{ props: activatorProps }">
						<div class="hubItemWrapper">
							<v-btn 
							v-bind="activatorProps" variant="flat" 
							class="categoryBtn"
							:style="getButtonStyle(category, c.colour)">
								<img v-if="category.mgClassification.iconPath" 
								:src="require('@/assets/transactions/' + category.mgClassification.iconPath.toLowerCase().replace('.svg', '.png'))" 
								class="icon" :style="'background-color: ' + c.colour">
								<div v-else class="icon-placeholder" :style="'background-color: ' + c.colour"></div>
								<span class="categoryName">{{ getClassificationLabel(category) }}</span>
								<v-icon right>mdi-chevron-down</v-icon>
							</v-btn>
						</div>
					</template>
					<template v-slot:default="{ isActive }">
						<v-slide-y-transition>
							<v-card>
							<v-card-text>
								<!-- get the children along with the parent -->
								<div v-for="subCategory in getChildredWithParent(category)" :key="subCategory.id" class="hubDialogItemWrapper">
									<v-btn @click="handleCategoryButtonClick(subCategory), isActive.value = false" 
									variant="flat"  class="categoryBtn">
										{{ getChildClassificationLabel(subCategory) }}
									</v-btn>
								</div>
							</v-card-text>
							<v-card-actions>
								<v-spacer></v-spacer>
								<v-btn text="Close" @click="isActive.value = false"></v-btn>
							</v-card-actions>
							</v-card> 
						</v-slide-y-transition>
					</template>
				</v-dialog>
			</div>

			<div style="clear: both;"></div>
		</div>
		<v-divider class="mt-3" />
		<br/>
		<div class="toggles">
			<v-row>
				<v-col cols="9">Always apply tag to this merchant</v-col>
				<v-col cols="2"><v-switch color="#B4D8D7" v-model="alwaysApplyTag" @change="handleAlwaysApplyTagToMerchantChange" class="switch"></v-switch></v-col>
			</v-row>
			<v-row>
				<v-col cols="9">Exclude from Spending Analysis</v-col>
				<v-col cols="2"><v-switch color="#B4D8D7" v-model="excludeFromSpendingAnalysis" @change="handleExcludeFromSpendingAnalysisChange" class="switch"></v-switch></v-col>
			</v-row>
		</div>
		<div class="centered">
			<MGButton class="mb-15" @click="confirmTag" :text="'Confirm tag'" />
		</div>
		<div style="height: 100px; clear: both;"></div>
		<v-dialog v-model="transactionDialogVisible" max-width="500px">
			<v-card>
				<v-card-title class="headline center">{{AlwaysTextDialog}} transaction</v-card-title>
				<v-card-text>
					Would you like to always {{(this.excludeFromSpendingAnalysis ? 'Exclude' : 'Include').toLowerCase()}} transactions from this merchant?
					<div class="centered">
						<MGButton @click="handleAlwaysExcludeIncludeFromSpendingAnalysis(true)" :text="'Yes, always ' + AlwaysTextDialog.toLowerCase()" :small="true" />
						<MGButton @click="handleAlwaysExcludeIncludeFromSpendingAnalysis(false)" :text="'No, just this once'" :small="true" />
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
<br>

	
</template>

<script>


import Helpers from '../../mixins/Helpers';
import Transaction from '../../mixins/Transaction';
import SpendingAnalysis, {GRANDPARENT_CLASSIFICATIONS} from '../../mixins/SpendingAnalysis';
import MGButton from '../elements/MGButton.vue';

export default {
	name: "TransactionTagging",
	props: ['categories', 'currentSelectionId', 'transaction', 'selectAlways', 'suggestedClassification'],
	mixins: [Helpers, SpendingAnalysis, Transaction],
	components: { MGButton },
	emits: ['tag', 'chosen'],
	data() {
		return{
			showSuggestion: true,
			incomeCategories: [],
			futureCategories: [],
			everydayCategories: [],
			committedCategories: [],
			committedSubCategories: [],
			allCategories: [],
			selectedClassification: null,
			currentSelectedCategory: null,
			activeCategory: null,
			localTransaction: { ...this.transaction }, // Create a local copy of the transaction prop
			alwaysApplyTag: false,
			alwaysApplyRule: false,
			excludeFromSpendingAnalysis: this.transaction.excludeFromAnalysis,
			transactionDialogVisible: false,
			localCurrentSelectionId: this.currentSelectionId
		}
	},
	computed: {
		AlwaysTextDialog() {
			return this.localTransaction.excludeFromAnalysis ? 'Include' : 'Exclude';
		}
	},
	watch: {
		transaction: {
			immediate: true,
			handler(newVal) {
				this.localTransaction = { ...newVal }; // Update local copy when prop changes
				this.excludeFromSpendingAnalysis = newVal.excludeFromAnalysis;
			}
		}
	},
	methods: {
		onLoad() {
			try {
				this.getIndividualCategories();
			} 
			catch (error) {
				console.log.error(error);
			}
		},
		getIndividualCategories() {
			this.incomeCategories = this.categories.filter(c => c.mgClassification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.INCOME);
			this.futureCategories = this.categories.filter(c => c.mgClassification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING);
			this.everydayCategories = this.categories.filter(c => c.mgClassification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND);
			this.committedCategories = this.categories.filter(c => c.mgClassification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS);
			this.allCategories = [
				{ name: 'Income', categories: this.incomeCategories, colour: '#B4D8D7' },
				{ name: 'Future Plans', categories: this.futureCategories, colour: '#D5C9C8' },
				{ name: 'Everday Spending', categories: this.everydayCategories, colour: '#9EA2D0' },
				{ name: 'Committed Costs', categories: this.committedCategories, colour: '#413655' }
			];
		},
		getChildredWithParent(category) {
			const children = [...category.children];
			return [category, ...children];
		},
		selectCategory(category) {
			console.log('select category', category);
			this.activeCategory = category;
			category.newSelectedClassificationId = category.mgClassification.id;
			this.selectedClassification = category;
		},
		acceptAutoSuggest() {
			this.selectedClassification = this._findClassificationById(this.suggestedClassification.id, this.categories);
			this.localTransaction.customerClassification = this.selectedClassification.mgClassification;
			this.localTransaction.excludeFromAnalysis = false;
			this.localTransaction.alwaysApplyTag = true;
			this.$emit('tag', this.localTransaction);
			this.localCurrentSelectionId = null;
			this.showSuggestion = true;
			this.selectedClassification = null;
		},
		confirmTag() {
			// If user hasn't selected a classification, default to the current selection
			if (this.selectedClassification === null) {
				if (this.localCurrentSelectionId != null) {
					this.selectedClassification = this._findClassificationById(this.localCurrentSelectionId, this.categories);
				}
			} 
			if (this.selectedClassification) {
				this.localTransaction.customerClassification = this.selectedClassification.mgClassification;
			}
			this.localTransaction.excludeFromAnalysis = this.excludeFromSpendingAnalysis;
			this.localTransaction.alwaysApplyTag = this.alwaysApplyTag;
			this.localCurrentSelectionId = null;
			this.$emit('tag', this.localTransaction);
		},
		getButtonStyle(category, colour) {
			const isSelected = category.mgClassification.id === this.localCurrentSelectionId ||
				category.children.some(child => child.mgClassification.id === this.localCurrentSelectionId);
		
			if (isSelected){
				return `background: ${colour} !important; color: white !important;`;
			}

			return null;
		},
		handleExcludeFromSpendingAnalysisChange() {
			this.transactionDialogVisible = true;
		},
		handleAlwaysExcludeIncludeFromSpendingAnalysis(always) {
			this.transactionDialogVisible = false;
			if (always)
			{
				this.localTransaction.alwaysApplyRule = true;
			}
			else
			{
				this.localTransaction.alwaysApplyRule = false;
			}
			this.localTransaction.excludeFromAnalysis = !this.localTransaction.excludeFromAnalysis;
		},
		handleAlwaysApplyTagToMerchantChange() {
			this.localTransaction.alwaysApplyTag = !this.localTransaction.alwaysApplyTag;
		},
		handleCategoryButtonClick(category) {
			this.selectCategory(category);
			this.localCurrentSelectionId = this.selectedClassification.mgClassification.id;
			this._scrollToBottom();
			this.$emit('chosen');
		},
		getClassificationLabel(category) {
			if (this.localCurrentSelectionId === category.mgClassification.id) {
				return category.mgClassification.fullName;
			}

			for (const child of category.children) {
				if (child.mgClassification.id === this.localCurrentSelectionId) {
					return child.mgClassification.mgSubClassification;
				}
			}

			return category.mgClassification.fullName;
		},
		getChildClassificationLabel(subCategory) {
			return subCategory.mgClassification.mgSubClassification ? subCategory.mgClassification.mgSubClassification : subCategory.mgClassification.fullName;
		},
	},
	mounted: function() { 
		this.onLoad();
		this.alwaysApplyTag = this.selectAlways;
	}
}

</script>

<style scoped>

.icon {
	width: 27px;
	background-color: #E7ECF7;
	border-radius: 90px;
}

.hubItemWrapper {
    margin-top: 6px !important;
	margin-bottom: 6px !important;
    padding-right: 6px !important;
	width: calc((100vw - 40px) / 2) !important;
}

.hubItemWrapper:nth-child(even) {
    padding-left: 6px !important;
}

.hubDialogItemWrapper {
	margin-top: 5px; 
	margin-bottom: 5px;
	margin-left: 0px; 
    margin-right: 10px;
	width: calc(100vw - 110px) !important;
	float: left !important;
}

.categoryBtn {
    text-transform: none !important;
    background: #F5F4F6 !important;
    width: 100% !important;
    height: 56px !important;
    margin-left: 5px !important;
    margin-right: 5px !important;
    font-size: 13px !important;
    text-align: left !important;
    justify-content: flex-start !important; 
    padding-left: 10px !important;
    font-weight: 800 !important;
}

.categoryName {
    padding-left: 5px !important;
    font-size: 13px !important;
    white-space: normal !important;
    word-wrap: break-word !important; 
}

.toggles {
    background: #F5F4F6 !important;
    m--ax-width: 90%;
    margin: 0 auto;
    text-align: left;
    border-radius: 10px;
    padding: 20px;
}

h2 {
    margin-bottom: 8px;
    margin-top: 15px;
}

.switch {
    height: 20px;
    margin-top: -17px;
}

.icon-placeholder {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #B4D8D7;
    display: inline-block;
}

</style>