export default {

	data() {
		return {
			sections: 
			[
				{
					fields: [
						{
							id: 'investmentType',
							validationText: 'Please choose an investment type',
							label: 'Type',
							type: 'dropdown',
							items: this.investmentTypes,
							value: null,
							required: true
						}				
					]
				},
				{
					fields: [
						{
							validationText: 'Please select a provider',
							label: 'Provider',
							type: 'dropdown',
							items: this.investmentProviders,
							value: null,
							required: true
						}	,
						{
							validationText: 'Please enter the account number',
							label: 'Account number',
							type: 'text',
							items: this.accountNumber,
							value: null,
							required: true
						},
						{
							validationText: 'Please enter the estimated value',
							label: 'Estimated Value',
							type: 'currency',
							value: null,
							required: true
						}
					]
				},
				{
					rule: 'investmentType: Stocks & Shares Isa, Lifetime Isa',
					fields: [
						{
							validationText: 'Please enter allowance used this year',
							label: 'Allowance used this year',
							type: 'currency',
							value: null,
							required: true
						},
					]
				},
				{
					rule: 'investmentType: General Investment Account, Premium Bonds, Investment, Investment Bond',
					fields: [
						{
							validationText: 'Please select ownership type',
							label: 'Ownership',
							type: 'select',
							items: ['Sole', 'Joint'],
							required: true
						},
					]
				}
			]
		}
	},
}