<template>
	<div class="welcome full centered">
		
			
				<div class="slide1" />
			
		
		<div class="anchor-bottom centered">
			<h1>Money Guided</h1>
			<h2>Feel good financial outcomes</h2>
			<MGButton text="Continue" @click="$router.push({ path: 'getstarted' })" />
			<p class="mt-3 centered">Already have an account? <a href="/#/login">Sign in</a></p>
			<p class="small centered">Money Guided is a registered company in England & Wales registered number 11713082.</p>
		</div>
	</div>
</template>

<script>
import Services from '../mixins/Services';	
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {MGButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.welcome {
	color: white;
}

.full {
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -10px;
}

.slide1 {
	background-image: url('../assets/bg/hero.png');
	background-size: cover;
	background-position: center;
	height: 100vh;
	widows: 100vw;
}

.anchor-bottom {
	margin-left: 30px;
	margin-right: 30px;
}
</style>
