<template>
	

	<div class="financialFitnessBars">
		<div class="startedBackground">
			<div class="barProgress started" :style="'width: ' + started + '%;'">
				Getting Started ({{ started }}%)
			</div>
		</div>
		<div class="dayToDayBackground">
			<div class="barProgress dayToDay" :style="'width: ' + dayToDay + '%;'">
				Day to Day ({{ dayToDay }}%)
			</div>
		</div>
		<div class="securityBackground">
			<div class="barProgress security" :style="'width: ' + security + '%;'">
				Security and Protection ({{ security }}%)
			</div>
		</div>
		<div class="futureBackground">
			<div class="barProgress future" :style="'width: ' + future + '%;'">
				Invest in my Future ({{ future }}%)
			</div>
		</div>
	</div>


</template>

<script>
export default {
	name: "FinancialFitnessBars",
	props: ['started', 'dayToDay', 'security', 'future'],
	data() {
		return{
			something: null
		}
	},
	computed: {
		somethingElse() {
			return null;
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.financialFitnessBars {
		margin-top: 35px;
	}

	.arrow {
		width: 19px;
		margin-top: -42px;
		margin-bottom: 10px;
		float: right;
		margin-right: 13px;
	}

	.barProgress {
		b--ackground: linear-gradient(246.92deg, #EEF0FF -13.35%, #D4D9F2 85.06%);
		border-radius: 16px;
		height: 20px;
		margin-bottom: 10px;
		height: 48px;
		text-overflow: show;
		white-space: nowrap;
		text-align: left;
		font-size: 14px;
		font-weight: bold;
		padding: 13px;
	}

	.started {
		background-color: #9EA2D0;
	}

	.startedBackground {
		background-color: #d4d7f350;
		border-radius: 16px;
	}

	.dayToDay {
		background-color: #C7989E;
	}

	.dayToDayBackground {
		background-color: #f5e2e450;
		border-radius: 16px;
	}

	.security {
		background-color: #77A4A7;
	}

	.securityBackground {
		background-color: #d4e3e450;
		border-radius: 16px;
	}

	.future {
		background-color: #5f5672;
	}

	.futureBackground {
		background-color: #afa7be50;
		border-radius: 16px;
	}
	


</style>