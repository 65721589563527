<template>
	<header-block title="Goals"></header-block>
	<CustomerLoadWait :customer="bankInfo && savingGoals" />
	<transition>
		<AdButton v-if="savingsAccounts && savingsAccounts.length == 0" title="Link Your Savings Accounts" href="/#/aiop?add=true"
			subtitle="Money Guided is designed to work with insights into your personal finances. Link your savings accounts to setup savings goals!"
			:topicon="require('@/assets/coins.png')" :bottomrighticon="require('@/assets/go.png')" />
	</transition>
	<transition>
		<div v-if="bankInfo" class="bankInfo">
			<div class="well" v-for="a in savingsAccounts" :key="a.id">
				<h2>{{ a.bank.prettyName }} {{ a.displayName }}</h2>
				<v-row class="mt-2">
					<v-col>
						Account balance:
					</v-col>
					<v-col>
						{{ formatter.format(a.balance) }}
					</v-col>				
				</v-row>
				<v-row>
					<v-col>
						Unallocated savings:
					</v-col>
					<v-col>
						{{ formatter.format(a.balance - a.allocated) }}
					</v-col>
				</v-row>
				<div class="mt-7" />
				<GoalInfoPanel :small="true" :href="'/#/Goals/' + g.id" class="edged goalInfoPanel" v-for="g in a.goals" :key="g.id" :title="g.name" :goal="g.targetAmount" :saved="g.currentBalance" :instalment="g.instalmentAmount" :formatter="formatter" />
				<div class="addButton" @click="addGoal(a)">
					Create Goal
				</div>
			</div>
		</div>
	</transition>

	<DrawerPanel v-if="selectedAccount" ref="drawer" :closedHeight="0" :bottom="0" :title="'Add Goal - ' + selectedAccount.bank.prettyName + ' ' + selectedAccount.displayName" openBackgroundColor="white">
		<div class="goalInput" >
			<InputSections :sections="goalSections" @submit="onGoalSubmit" theme="light" />
			<div v-if="quotation">
				<h2>Quote</h2>
				<p>Instalment: {{ formatter.format(quotation.instalmentAmount) }}</p>
				<p>{{ quote.instalmentDescription }}</p>
			</div>
			<MGButton :text="quotation ? 'Re-Quote' : 'Quote'" @click="quoteGoal" />
			<MGButton v-if="quotation" text="Save" @click="createGoal(quotation)" />
		</div>
	</DrawerPanel>
	
</template>

<script>

import Services from '../mixins/Services';
//import BarArrowButton from './elements/BarArrowButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import GoalInfoPanel from './elements/GoalInfoPanel.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';
import AdButton from './elements/AdButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, GoalInfoPanel, DrawerPanel, InputSections, MGButton, AdButton},
	data() {
		return {
			savingsAccounts: null,
			selectedAccount: null,
			quote:null,
			quotation:null,
			goalSections: [
				{
					
					fields: [
						{ name: "name", label: "What are you saving for?", type: "text" },
						{ name: "targetAmount", label: "Target Amount", type: "currency" },
						{ name: "initial", label: "Initial contribution?", type: "currency" },
						{ name: "targetDate", label: "When do you want to reach your goal?", type: "datetext" },
					]
				}
			]
		}
	},
	methods: {
		addGoal(account){
			this.selectedAccount = account;
			this.$nextTick(() => {
				this.$refs.drawer.openDrawer();
			});
		},
		quoteGoal(){
			var targetDate = this.goalSections[0].fields.find(f => f.name == "targetDate").value;
			var goal = {
				bankAccountID: this.selectedAccount.id,
				"name": this.goalSections[0].fields.find(f => f.name == "name").value,
				"targetAmount": this.goalSections[0].fields.find(f => f.name == "targetAmount").value,
				"targetDate": targetDate.split('/').reverse().join('-'),
				"monthsRemaining":0,
				"currentBalance":this.goalSections[0].fields.find(f => f.name == "initial").value,
				"bankAccountName":this.selectedAccount.bank.prettyName,
				"instalmentAmount":0.0,
				"archived":false
			};
			this._quoteSavingGoal(goal, (quote) => {
				this.quote = quote;
				this.quotation = this.quote.goals.find(q => q.id == '00000000-0000-0000-0000-000000000000');
			});
		},
		createGoal(goal){
			this._createSavingGoal(goal, () => {
				this.$refs.drawer.closeDrawer();
				this.quote = null;
				this.quotation = null;
				this.goalSections = [
					{
						fields: [
							{ name: "name", label: "What are you saving for?", type: "text" },
							{ name: "targetAmount", label: "Target Amount", type: "currency" },
							{ name: "initial", label: "Initial contribution?", type: "currency" },
							{ name: "targetDate", label: "When do you want to reach your goal?", type: "datetext" },
						]
					}
				];
				this._clearSavingGoals();
				this.onLoad();
			});
		},
		onLoad: async function() {
			this._getCustomer(() => {
				this._getBankingInfo(() => {
					this.savingsAccounts = [];
					this.bankInfo.accounts.forEach(a => {
						a.goals = [];
						a.allocated = 0;
						if (a.accountType.prettyName.toLowerCase() == "savings") {
							this.savingsAccounts.push(a);
						}
					});
					this._getSavingGoals(() => {
						this.savingGoals.forEach(g => {
							var account = this.savingsAccounts.find(a => a.id == g.bankAccountID);
							account.allocated += g.currentBalance;
							account.goals.push(g);
						});
					});
				});
				
					
				
	
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.bankInfo
{
	.v-row {
		padding: 0px;
		padding-left: 12px;
	}
	.v-col{
		padding: 0px;
	}
	padding-bottom: 40px;
}

.addButton {
	background-color: white;
	font-size: 16px;
	text-align: center;
	padding: 15px;
	border-bottom-right-radius: 24px;
	border-bottom-left-radius: 24px;
	margin-left: -24px;
	margin-right: -24px;
	margin-bottom: -24px;
	border-top: 1px solid #E6E1DD;
}

.goalInfoPanel {
	
}

.goalInput {
	padding: 10px;
}

.edged {
	margin-left: -25px;
	margin-right: -25px;
	margin-bottom: -8px;
}

.well {
	background: linear-gradient(95.19deg, #E6E1DD 0%, #E7DFDD 100%);
}

</style>
