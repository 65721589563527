<template>
	<div :class="'info-panel'">
		<a :href="href">
			<div :class="(blur ? 'blur' : '')">
			<v-row>
				<v-col v-if="icon && icon != ''" class="icon-col v-col-2">
					<img :src="icon"/>
				</v-col>
				<v-col class="title-col">
					<div v-if="title" class="title">{{ title }}</div>
					<div class="sub-title">{{ subtitle }}</div>
				</v-col>
				<v-col v-if="valueTitle && value" class="right-col v-col-2">
					<div class="value-title">{{ valueTitle }}</div>
					<div class="value mt-1"><a :href="valueHref">{{ value }}</a></div>
				</v-col>
			</v-row>
		</div>
	</a>
	<div v-if="blur" class="comingSoon">Coming Soon</div>
	</div>
</template>

<script>
export default {
	name: "InfoPanel",
	props: ['icon','title','subtitle','valueTitle','value', 'blur', 'href', 'valueHref'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.info-panel {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 1px solid var(--border-color);
	background-color: var(--background-color);
}

.title {
	font-size: 16px;
}

.sub-title {
	font-size: 12px;
}

.icon-col {

}

.icon-col img {
	height: 40px;
}

.value-title {
	font-size: 10px;
}

.value {
	font-size: 16px;
}

.right-col {
	text-align: center;
	border-left: 1px solid var(--border-color);
}

.comingSoon {
	text-align: center;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: -18px;
	margin-top: 16px;
	border-top: 1px solid var(--border-color);
	margin-right: -18px;
	margin-left: -18px;
	background-color: #FEF9F4;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	font-size: 10px;
	background: linear-gradient(180deg, #FEF9F4 0%, #F7F1F1 100%);
}

</style>