<template>
	<header-block title="Spending Analysis"></header-block>
	<CustomerLoadWait :customer="ready" />

	<div class="monthSelector" v-if="selectedSlotKey">
		<v-slide-group
			:center-active="true" 
			class="monthSlider"
			v-model="selectedSlotKey">
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item :value="s.key" v-slot:default="{ isSelected, toggle }" v-for="s in slots" :key="s.key" >
				<span :class="isSelected ? 'selected' : ''" @click="toggle(); selectedSlotKey = s.key">{{ s.label }}</span>
			</v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>

		</v-slide-group>
	</div>

	<transition>
		<v-row v-if="ready">
			<v-col>
				<AnalysisWidget :totals="ready ? selectedSpendingAnalysis : null" :daysRemaining="ready ? spendingAnalysis.daysTillCycleResets : 0" :formatter="formatter" :title="false" />
			</v-col>
		</v-row>
	</transition>

	<transition>
		<v-row class="mt-2" v-if="recurringPayments && recurringPayments.payments.length > 0">
			<v-col>
				<InfoPanel :href="'/#/RecurringPayments'" :valueHref="'/#/RecurringPayments'"
					:icon="require('@/assets/coins.png')" title="Recurring Payments"
					subtitle="Stay on top of your recurring payments" valueTitle="Count"
					:value="recurringPayments.payments.length" />
			</v-col>
		</v-row>
	</transition>

	<transition>
		<v-row class="mt-2" v-if="bankInfo && bankInfo.accounts.length > 0 && bankInfo.untaggedTransactionsCurrentCycleCount > 0">
			<v-col>
				<InfoPanel class="shadowed"
					:href="'/#/Transaction/Bulk?from=' + bankInfo.currentCycleStartDate + '&to=' + bankInfo.today + '&includeNegative=true&includePositive=true'" 
					:valueHref="'/#/Transaction/Bulk?from=' + bankInfo.currentCycleStartDate + '&to=' + bankInfo.today + '&includeNegative=true&includePositive=true'" 
					:icon="require('@/assets/coins2.png')" 
					title="Tag Transactions" 
					subtitle="You have transactions to tag" 
					valueTitle="Count" 
					:value="bankInfo.untaggedTransactionsCurrentCycleCount" 
				/>
			</v-col>
		</v-row>
	</transition>

	<transition>
		<div v-if="ready" class="mt-4">
			<v-expansion-panels color="#B4D8D7" variant="accordion">
				<v-expansion-panel>
					<v-expansion-panel-title>
						<v-row class="analysisTitleRow">
							<v-col cols="8">
								Income
							</v-col>
							<v-col class="right">
								{{ formatter.format(Math.round(selectedIncome.totalIn))}}
							</v-col>
						</v-row>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<SpendingAnalysisSubCategory v-for="group in selectedIncome.groupedAnalysis"
							:key="group" 
							:group="group"
							:income="true"
							:planPage="false"
							:bgColour="`#B4D8D7`" 
							:formatter="formatter"
							@categoryClicked="(c) => { _handleCategoryClicked(c, 0) }"
							@uncatagorisedTransactionsClicked="(c) => { handleBulkCategorisationClick(c) }">
						</SpendingAnalysisSubCategory>
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>

			<v-expansion-panels color="#EFE8E6" class="mt-4" variant="accordion">
				<v-expansion-panel>
					<v-expansion-panel-title>
						<v-row class="analysisTitleRow">
							<v-col cols="8">
								Future plans
							</v-col>
							<v-col class="right">
								{{ formatter.format(Math.abs(Math.round(selectedFuturePlans.totalNet))) }}
							</v-col>
						</v-row>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<SpendingAnalysisSubCategory v-for="group in selectedFuturePlans.groupedAnalysis"
							:key="group" 
							:group="group"
							:plans="spendingPlans"
							:planPage="false"
							bgColour="#EFE8E6" 
							:isCollapsed="isFuturePlansCollapsed"
							:formatter="formatter"
							@categoryClicked="(c) => { _handleCategoryClicked(c, 1) }"
							@uncatagorisedTransactionsClicked="(c) => { handleBulkCategorisationClick(c) }">
						</SpendingAnalysisSubCategory>
					</v-expansion-panel-text>
				</v-expansion-panel>

				<v-expansion-panel color="#EEF0FF">
					<v-expansion-panel-title>
						<v-row class="analysisTitleRow">
							<v-col cols="8">
								Everyday spending
							</v-col>
							<v-col class="right">
								{{ formatter.format(Math.abs(Math.round(selectedEverydaySpend.totalNet))) }}
							</v-col>
						</v-row>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<SpendingAnalysisSubCategory v-for="group in selectedEverydaySpend.groupedAnalysis"  
							:key="group" 
							:group="group"
							:plans="spendingPlans"
							:planPage="false"
							bgColour="#EEF0FF" 
							:isCollapsed="isEveryDaySpendCollapsed"
							:formatter="formatter"
							@categoryClicked="(c) => { _handleCategoryClicked(c, 2) }"
							@uncatagorisedTransactionsClicked="(c) => { handleBulkCategorisationClick(c) }">
						</SpendingAnalysisSubCategory>
					</v-expansion-panel-text>
				</v-expansion-panel>

				<v-expansion-panel color="#413655">
					<v-expansion-panel-title>
						<v-row class="analysisTitleRow">
							<v-col cols="8">
								Committed costs
							</v-col>
							<v-col class="right">
								{{ formatter.format(Math.abs(Math.round(selectedCommittedCosts.totalNet))) }}
							</v-col>
						</v-row>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<SpendingAnalysisSubCategory v-for="group in selectedCommittedCosts.groupedAnalysis"
							:key="group" 
							:group="group"
							:plans="spendingPlans"
							:planPage="false"
							:bgColour="`#413655`"
							:isCollapsed="isCommittedCostsCollapsed"
							:formatter="formatter"
							@categoryClicked="(c) => { _handleCategoryClicked(c, 3) }"
							@uncatagorisedTransactionsClicked="(c) => { handleBulkCategorisationClick(c) }">
						</SpendingAnalysisSubCategory>
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</transition>

	<DrawerPanel
		openHeight="85%" closedHeight="0px" :back="true" @goBack="handleDrawerGoBack" @closed="_handleDrawerClosed"
		ref="transactionDrawer" bottom="44px" :title="_drawerTitle" backgroundColor="white" openBackgroundColor="white">
		<!-- Page 1 -->
		<div v-if="!showMerchantTransactions && !showPlanManagement">
			<div class="centered">
				<h2>{{ selectedClassificationIncome > 0 ? 'Total Income' : 'Total Spend' }}</h2>
			</div>
			<div class="centered">
				<h1>{{ formatter.format(Math.abs(Math.round(selectedClassificationIncome > 0 ? selectedClassificationIncome : selectedClassificationSpend))) }}</h1>
			</div>
			<PlanInfoPanel v-if="selectedClassificationPlanAmount > 0 && selectedClassification"
				:alt="true"
				@click="_navigateToPlan(selectedClassification)" 
				:plan="selectedClassificationPlanAmount" 
				:spent="Math.abs(selectedClassificationSpend)" 
				hideArrow="true" :key="totalPlan"
				title="Spending Plan"
				:formatter="formatter" 
				:iconPath="selectedClassification.iconPath" 
				:bgColour="selectedClassification.bgColour"/>
			<BarArrowButton v-else title="Create a new plan" :large="false" class="createNew" @click="_navigateToPlan(selectedClassification)"/>
			<WellPanel :data="categoryWellData" @merchantClicked="(m) => {_handleMerchantClicked(m)}" />
		</div>
		<!-- Page 2 -->
		<div v-if="showMerchantTransactions && !showPlanManagement">
			<div class="centered">
				<h2>Total Spend</h2>
			</div>
			<div class="centered">
				{{ formatter.format(Math.abs(Math.round(selectedMerchantSpend))) }}
			</div>
			<TransactionsList :transactions="merchantTransactions" :formatter="formatter" :today="today" :yesterday="yesterday" :showDate="true" :search="false" />
		</div>
		<!-- Page 3-->
		<div v-if="showPlanManagement">
			<SpendingPlanManagement
			@createPlan="(p) => { createPlan(p) }" 
			@updatePlan="(p) => { updatePlan(p) }" 
			@deletePlan="(p) => { deletePlan(p) }"
			:key="group" 
			:selectedPlan="selectedPlan" 
			:sections="sections"
			:managePlan="true"
			:categoriesPanel="categoriesPanel" :themeColour="themeColour" :formatter="formatter"/>
		</div>
	</DrawerPanel>

</template>

<script>

import Services from '../mixins/Services';
import Helpers from '../mixins/Helpers';
import SpendingAnalysis from '../mixins/SpendingAnalysis';
import { GRANDPARENT_CLASSIFICATIONS } from '../mixins/SpendingAnalysis';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import SpendingAnalysisSubCategory from './elements/SpendingAnalysisSubCategory.vue';
import AnalysisWidget from './elements/AnalysisWidget.vue';
import InfoPanel from './elements/InfoPanel.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import WellPanel from './elements/WellPanel.vue';
import TransactionsList from './elements/TransactionsList.vue';
import PlanInfoPanel from './elements/PlanInfoPanel.vue';
import BarArrowButton from './elements/BarArrowButton.vue';
import SpendingPlanManagement from './elements/SpendingPlanManagement.vue';
import SpendingPlans from '../mixins/SpendingPlans';

export default {
	mixins: [Services, Helpers, SpendingAnalysis, SpendingPlans],
	components: {CustomerLoadWait, HeaderBlock, SpendingAnalysisSubCategory, AnalysisWidget, InfoPanel, DrawerPanel, WellPanel
		, TransactionsList, PlanInfoPanel, BarArrowButton, 
		SpendingPlanManagement
	},
	data() {
		return {		
			customer: null,
			recurringPayments: null,
			isEveryDaySpendCollapsed: true,
			isFuturePlansCollapsed: true,
			isCommittedCostsCollapsed: true,
			months: [],
			years: [],
			slots: [],
			allEveryDaySpend: [],
			allFuturePlans: [],
			allCommittedCosts: [],
			allIncome: [],
			selectedSlotKey: null,
			selectedTotals: [],
			selectedPlan: [],
			ready: false,
			selectedCategoryCache: [],
			selectedClassification: null,
			groupedTransactions: [],
			merchantWellData: [],
			merchantTransactions: [],
			closeDrawerNext: true,
			selectedClassificationPlanAmount: 0,
			selectedMerchantSpend: 0,
			sections: 
			[
				{
					fields: [
					{
							label: 'Plan amount',
							type: 'currency',
							value: null,
							required: true
						}
					]
				},
			],
		};
	},
	computed: {
		selectedSpendingAnalysis() {
			return this.selectedTotals;
		},

		selectedEverydaySpend() {
			return this.selectedCategory(this.allEveryDaySpend, GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND);
		},

		selectedFuturePlans() {
			return this.selectedCategory(this.allFuturePlans, GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING);
		},

		selectedCommittedCosts() {
			return this.selectedCategory(this.allCommittedCosts, GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS);
		},

		selectedIncome() {
			var selected = this.allIncome.find(s => s.slotName === this.selectedSlotKey);
			if (!selected) {
				selected = {
					label: this.selectedSlotKey,
					totalIn: 0,
					totalOut: 0,
					totalNet: 0,
					groupedAnalysis: []
				};
			}
			if (selected.groupedAnalysis.length > 0) {
				selected.groupedAnalysis[0].name = this.customer.employer || 'Employer';
			}
			return selected;
		},
	},
	watch: {
		selectedSlotKey: '_updateSelectedTotals',
	},
	mounted: function() { 
		this.onLoad(); 
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				this._getBankingInfo(() => {
					if (this.bankInfo.spendingCycle.id == "00000000-0000-0000-0000-000000000000") {
						this.$router.replace('/setupspendinganalysis');
						return;
					}
					this._getRecurringPayments((res) => {
						this.recurringPayments = res;
					});
				});
				this._getSpendingAnalysis(() => {
					this._getSpendingPlansFull(() => {
						this._extractLitePlansFromFullPlans();
						this._setupCategories();
						this._updateSelectedTotals();
						this.ready = true;	
					});
				});
			});
		},

		updatePlan(p) {
			this._updatePlan(p, false);
			this.showPlanManagement = false, 
			this.closeDrawerNext = true
			this.selectedClassificationPlanAmount = p.plan.amount;
		},

		deletePlan(p) {
			this._deletePlan(p, false)
			this.showPlanManagement = false
			this.closeDrawerNext = true
			this.selectedClassificationPlanAmount = 0;
		},

		createPlan(p) {
			this.managePlan = false
			this._createPlan(p);
		},

		handleBulkCategorisationClick(classification) {
			var selectedSlot = this.slots.find(s => s.key === this.selectedSlotKey);
			var includePositive = classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.INCOME;
			var includeNegative = classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING || classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND || classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS;
			this.$router.push({ path: '/Transaction/Bulk', query: { from: selectedSlot.from, to: selectedSlot.to, includeNegative: includeNegative, includePositive: includePositive } });
		},

		handleDrawerGoBack() {
			if (this.showPlanManagement) {
				this.showPlanManagement = false;
				this.closeDrawerNext = true;
			}
			else
			if (this.showMerchantTransactions) {
				this.showMerchantTransactions = false;
				this.showPlanManagement = false;
				this.closeDrawerNext = true;
			}
			else {
				this.selectedClassificationPlanAmount = 0;
				this.selectedClassificationSpend = 0;
				this._setFieldValue('Plan amount', 0);
				this.$refs.transactionDrawer.closeDrawer();
			}
		},

		selectedCategory(categoryArray, grandparentClassification) {
			if (this.selectedCategoryCache[grandparentClassification] != null)
			{
				return this.selectedCategoryCache[grandparentClassification];
			}
			var selected = categoryArray.find(s => s.slotName === this.selectedSlotKey) 
			if (!selected) {
				selected = {
					label: this.selectedSlotKey,
					totalIn: 0,
					totalOut: 0,
					totalNet: 0,
					groupedAnalysis: []
				};
			}
			// add any missing groups that we have plans for
			var newGroups = [];
			var plans = this.spendingPlans.plans.filter(plan => plan.classification.grandParentClassification === grandparentClassification);
			plans.forEach(plan => {
				// if this plan isn't found in the analysis, add it
				if (!selected.groupedAnalysis.find(g => g.name === plan.classification.fullName))
				{
					newGroups.push({
						name: plan.classification.fullName,
						transactionClassification: plan.classification,
						amountIn: 0,
						amountOut: 0,
						amountNet: 0,						
					});
				}
			});
			selected.groupedAnalysis = selected.groupedAnalysis.concat(newGroups);
			// sort the groups by amountOut (abs)
			selected.groupedAnalysis.sort((a, b) => Math.abs(b.amountOut) - Math.abs(a.amountOut));
			// If the groupedAnalysis.name is 'Uncategorised' then change then name to: 'Tag n transactions'.  Get n from groupedAnalysis.countNegativeTransactions
			selected.groupedAnalysis.forEach(group => 
				{
					if (group.name === 'Uncategorized') {
						group.name = 'Tag ' + group.countNegativeTransactions + ' transaction' + (group.countNegativeTransactions !== 1 ? 's' : '');
					}
				}
			);
			// Move the 'Tag n transactions' group to the top
			const uncategorizedGroupIndex = selected.groupedAnalysis.findIndex(group => group.name.startsWith('Tag '));
			if (uncategorizedGroupIndex > -1) {
				const [uncategorizedGroup] = selected.groupedAnalysis.splice(uncategorizedGroupIndex, 1);
				selected.groupedAnalysis.unshift(uncategorizedGroup);
			}
			this.selectedCategoryCache[grandparentClassification] = selected;
			return selected;
		},

		toggleEveryDaySpending(isCollapsed) {
			this.isEveryDaySpendCollapsed = isCollapsed;
		},
		
		toggleFuturePlans(isCollapsed) {
			this.isFuturePlansCollapsed = isCollapsed;
		},

		toggleCommittedCosts(isCollapsed) {
			this.isCommittedCostsCollapsed = isCollapsed;
		}
	}
};

</script>

<style scoped>

.monthSelector {

	margin-bottom: 15px;

	.monthSlider {
		font-size: 12px;
		span {
			margin-right: 25px;
			margin-left: 25px;
		}
		span.selected {
			color: var(--primary-color);
			font-weight: bold;
		}
	}

	.selectedDot {
		width: 7px;
	}
}

.analysisTitleRow {
	.v-col {
		font-size: 15px !important;
	}
}

.createNew {
	margin-top: 15px;
}

</style>