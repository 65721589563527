export default {

	data() {
		return {
			sections: 
			[
				{
					title: 'Your name',
					subtitle: null,
					fields: [
						{
							validationText: 'Please complete all fields',
							label: 'First Name',
							type: 'text',
							required: true,
							value: null
						},
						{
							validationText: 'Please complete all fields',
							label: 'Last Name',
							type: 'text',
							required: true,
							value: null
						}
					]
				},
				{
					title: 'Mobile phone number',
					subtitle: null,
					fields: [
						{
							validationText: 'Please complete all fields',
							label: '+44',
							type: 'number',
							required: true,
							value: null,
							subtitle: 'We never share any of your personal information with your employer. We never share any of your personal information with a third party, without your consent.'
						}
					]
				},
				{
					title: 'Personal information',
					subtitle: null,
					fields: [
						{
							validationText: 'Please complete all fields',
							label: 'Date of birth',
							type: 'datetext',
							required: true,
							value: null
						},
						{
							validationText: 'Please complete all fields',
							label: 'Postcode',
							type: 'text',
							required: true,
							value: null
						}
					]
				},
				{
					title: 'Email and password',
					subtitle: "<h3>Money is personal.</h3>You can use an email address of your choice and set a password.",
					fields: [
						{
							validationText: 'Please complete all fields',
							label: 'Email address',
							type: 'email',
							required: true,
							value: null
						},
						{
							validationText: 'Please complete all fields',
							label: 'Password',
							type: 'password',
							required: true,
							value: null
						},
						{
							validationText: 'Please accept the terms & conditions',
							label: 'Accept below terms & conditions',
							link: "https://www.moneyguided.com/#/terms",
							linkLabel: 'Click here to view our Terms & Conditions',
							type: 'check',
							required: true,
							value: null
						}
					]
				}
			]
		}
	},
}