<template>
	
	<v-btn @click="$emit('click')" v-if="!circle" :class="'mg-button ' + (small ? 'mgButtonSmall ' : ' ')  + (alt ? 'alt ' : ' ') + (outline ? 'outline ' : ' ') + (fullWidth ? 'fullWidth ' : ' ')" :disabled="disabled">

		<v-row v-if="!small && !circle">
			<v-col v-if="icon || arrow || open" class="leftIconCol" cols="2">
				<img v-if="!icon || !icon.startsWith('mdi-')" class="leftIcon" :src="icon ?? require('@/assets/transparent.png')" />
				<v-icon v-if="icon && icon.startsWith('mdi-')" :icon="icon" />
			</v-col>
			<v-col :class="(icon || arrow || open ? 'textCol' : ' ')">
				{{text}}
			</v-col>
			<v-col v-if="arrow || open || icon" class="rightIconCol" cols="2">
				<img v-if="arrow" class="arrow rightIcon" :src="(alt || outline ? require('@/assets/arrow-right.png') : require('@/assets/light-arrow-right.png'))" />
				<img v-if="open" class="open rightIcon" :src="(alt || outline ? require('@/assets/chevron-down.png') : require('@/assets/chevron-down.png'))"/>
			</v-col>
		</v-row>
		<div v-if="small">
			{{ text }}
		</div>
	</v-btn>

	<v-btn @click="$emit('click')" v-if="circle" :color="color ? color : (alt ? '#E0C5C8' : '#281C3B')" :icon="icon" :density="small ? 'compact': 'default'"></v-btn>

</template>

<script>
export default {
	name: "MGButton",
	props: ['text', 'outline', 'small', 'arrow', 'alt', 'icon', 'open', 'circle', 'disabled', 'fullWidth', 'color'],
	emits: ['click'],
	data() {
		return{
		}
	},
	computed: {
	},
	methods: {
		onLoad() {
	
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.fullWidth {
	width: 100% !important;
}

.mgButtonSmall
{
	width: unset !important;
	height: 40px !important;
}

.rightIconCol {
	margin-top:auto;
	margin-bottom:auto;
}


.rightIcon {
	margin-top:auto;
	margin-bottom:auto;
}

.leftIconCol {
	padding-left: 0px;
	margin-left: -8px;
	margin-top:auto;
	margin-bottom:auto;
}

.arrowCol {
	padding-right: 4px;
	margin-top:auto;
	margin-bottom:auto;
}

.leftIcon {
	width: 30px;
}

.textCol {
	margin-top:auto;
	margin-bottom:auto;
}

.alt {
	background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%) !important;
	color: var(--text-color) !important;
}

.arrow {
	height: 10px;
}

.open {
	height: 8px;
}

</style>