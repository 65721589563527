<template>
	<header-block :title="goal ? goal.name + ' Goal': 'Savings Goal'"></header-block>
	<CustomerLoadWait :customer="goal" />
	<transition>
		<div v-if="goal && goal.account">
			<GoalInfoPanel v-if="goal" class="edged goalInfoPanel" :goal="goal.targetAmount" :saved="goal.currentBalance" :instalment="goal.instalmentAmount" :formatter="formatter" />
			<div class="info">
				<WellPanel :data="wellData"/>
				<MGButton icon="mdi-delete" text="Delete Goal" @click="showDelete = true" />
			</div>
		</div>
	</transition>

	<v-dialog max-width="500" v-if="showDelete && goal" v-model="showDelete">
		<v-card>
			<v-card-title>Delete</v-card-title>
			<v-card-text>Are you sure you want to delete your {{goal.name}} goal?</v-card-text>
			<v-card-actions>
				<v-btn @click="showDelete = false">Cancel</v-btn>
				<v-btn @click="deleteGoal" color="red">Delete</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
	
</template>

<script>

import Services from '../mixins/Services';
//import BarArrowButton from './elements/BarArrowButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
// import DrawerPanel from './elements/DrawerPanel.vue';
import GoalInfoPanel from './elements/GoalInfoPanel.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import WellPanel from './elements/WellPanel.vue';
// import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, GoalInfoPanel, WellPanel, MGButton},
	data() {
		return {
			goal:null,
			wellData: null,
			showDelete: false
		}
	},
	methods: {
		deleteGoal() {
			this._deleteSavingGoal(this.goal.id, () => {
				this._clearSavingGoals();
				this.$router.push('/Goals');
			});
		},
		onLoad: async function() {
			this._getCustomer(() => {
				this._getBankingInfo(() => {
					this._getSavingGoal(this.$route.params.id, (g) => {
						this.goal = g;
						this.goal.account = this.bankInfo.accounts.find(a => a.id == g.bankAccountID);
						this.wellData = [
							{order: 0, label: 'Goal', value: this.goal.name},
							{order: 1, label: 'Account', value: this.goal.account.bank.prettyName + ' ' + this.goal.account.displayName},
							{order: 2, label: 'Account Balance', value: this.formatter.format(this.goal.account.balance)},
							{order: 3, label: 'Saved so Far', value: this.formatter.format(this.goal.currentBalance)},
							//{order: 4, label: 'Unallocated', value: this.formatter.format(this.goal.account.balance - this.goal.currentBalance)},
							{order: 5, label: 'Goal Target', value: this.formatter.format(this.goal.targetAmount)},
							{order: 6, label: 'Monthly Instalment', value: this.formatter.format(this.goal.instalmentAmount)},
						];
					});
				});
			});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>


</style>
