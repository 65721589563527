<template>
	<div :class="'card ' + (selected ? 'selected' : '')" :style="'background-color: #' + (account.bank.hexColour2 ? account.bank.hexColour2.substring(2) : '281C3B')">
		<v-row class="nameRow">
			<v-col class="v-col-9 nameCol">
				<p>{{account.displayName}}</p>
				<span>{{merchant}}</span>
			</v-col>
			<v-col class="right"><img class="bankLogo" :src="account.bank.logoPath ? require('@/assets/logos/' + account.bank.logoPath): account.bank.logoUri"/></v-col>
		</v-row>
		<v-row class="mt-0">
			<v-col class="balanceCol">{{ formatter.format(Math.abs(amount))}}</v-col>
			<v-col class="balanceCol right">{{ count }}</v-col>
		</v-row>
		<v-row class="mt-1 mb-4">
			<v-col class="labelCol">{{ amount < 0 ? 'Debit' : 'Credit'}}</v-col>
			<v-col class="labelCol right">{{ count === 1 ? 'transaction' : 'transactions' }}</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col class="dateDetailsCol">
				{{ date }} <span v-if="txnTime !== '00:00'">@ {{ txnTime }}</span>
				<span class="floatRight"><div @click="$router.push('/transaction/' + transactionId)"><v-icon icon="mdi-dots-horizontal"/></div></span>
			</v-col>
		</v-row> 
		<v-row></v-row>
	</div>
</template>

<script>
export default {
	name: "TransactionGroupCard",
	props: ['account', 'merchant','amount','date','rawDate','count','formatter', 'selected', 'transactionId'],
	data() {
		return{
			
		}
	},
	computed: {
		txnTime() {
			return this.rawDate.split('T')[1].split('.')[0].substring(0,5)
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.card {
	color:var(--light-text-color); 
	margin-top: 13px;
	padding: 25px; 
	height: 20vh; 
	width: calc(100vw - 40px);
	min-height: 200px;
	margin-left: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.263);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	opacity: 0.7;
}

.card:last-child {
	margin-right: 20px;
}

.selected {
	transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	opacity: 1;
}

.nameCol
{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	p{
		font-size: 12px;
		margin-bottom: 0px;
	};
	span{
		font-size: 15px;
	}
}

.balanceCol
{
	font-size: 28px;
	padding-bottom: 0px;
}

.labelCol
{
	padding-bottom: 0px;
	padding-top:0px;
	font-size: 12px;
}

.bankLogo
{
	width: 40px;
	border-radius: 50%;
	background-color: #ffffff66;
}

.dateDetailsCol {
	
}

</style>