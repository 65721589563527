<template>
	<header-block title="Test Page"></header-block>

	<AddressLookup theme="light" v-model="address" />
		<BarArrowButton :icon="require('../assets/coins.png')" title="Bar Arrow Button" href="#" :large="true"/>
		<BarArrowButton title="Bar Arrow Button" href="#" :large="true"/>
		<MGButton text="Hello" />
		<MGButton text="Some Longer Text" />
		<MGButton :alt="true" text="Alt" />
		<MGButton :arrow="true" text="Arrow" />
		<MGButton :icon="require('../assets/coins.png')" :arrow="true" text="Icon Arrow" />
		<MGButton :outline="true" :icon="require('../assets/coins.png')" :arrow="true" text="O I Arrow" />
		<MGButton :outline="true" :icon="require('../assets/coins.png')" :arrow="true" text="O I D" disabled="true" />
		<MGButton :alt="true" :arrow="true" text="Alt arrow" />
		<MGButton :icon="require('../assets/coins.png')" text="Icon" />
		<MGButton :icon="require('../assets/coins.png')" text="Icon disabled" disabled/>
		<MGButton :alt="true" :icon="require('../assets/coins.png')" text="Alt icon" />
		<MGButton :alt="true" :icon="require('../assets/coins.png')" :arrow="true" text="Alt icon arrow" />
		<MGButton :small="true" text="Small" />
		<MGButton :small="true" :outline="true" text="Small" />
		<MGButton :small="true" :alt="true" text="Small A" />
		<MGButton :small="true" :alt="true" :arrow="true" text="Small A A" />
		<MGButton :small="true" :icon="require('../assets/coins.png')" :alt="true" :arrow="true" text="Small I A A" />
		<MGButton :small="true" :icon="require('../assets/coins.png')" :alt="true" :arrow="true" text="Small I A A D" disabled="true" />
		<MGButton text="Down with icon" :arrow="false" :open="true" :icon="require('../assets/coins.png')"/>
		<MGButton text="Down no icon" :arrow="false" :open="true"/>
</template>

<script>
import Services from '../mixins/Services';
import AddressLookup from './elements/AddressLookup.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';
import BarArrowButton from './elements/BarArrowButton.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, MGButton, AddressLookup, BarArrowButton},
	data() {
		return {
			drawer: false,
			rail: true,
			currentAccounts: [],
			currentAccountsBalance: 0,
			creditAccounts: [],
			creditAccountsBalance: 0,
			savingsAccounts: [],
			savingsAccountsBalance: 0,
			address:null
		}
	},
	watch: {
		// 'drawer': {
		// 	handler: function(newValue) {
		// 		if (!newValue)
		// 		{
		// 			this.drawer = true;
		// 			this.rail = true;
		// 		}
		// 	},
		// }
	},
	methods: {

		onLoad: async function () {
			console.log(window.location.origin + '/#/');
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
.title {
	font-size: 14px;
}

.divider {
	width: 48px;
	margin-bottom: 8px;
	margin-top: 8px;
	margin-right: auto;
	margin-left: auto;
	background: var(--Keyline, rgba(51, 40, 71, 0.10));
	height: 4px;
	border-radius: 4px;
}

.drawer {
	border-radius: 16px 16px 0px 0px;
	border: 0px;
	overflow-y: hidden;
}

.topTabButton {
	margin-top: 0px;
}
</style>
