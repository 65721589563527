<template>
	<HeaderBlock title="All in one place" />
	<CustomerLoadWait :customer="customer && bankInfo" :cardBlocks="true" />
	<transition>
		<div v-if="customer && bankInfo" @scroll="alert()">

			<div class="addingAccountsWidget" v-if="adding">
				<div class="float-left">Searching for new accounts . . .</div><div class="float-right" @click="adding=false">Cancel</div>
				<v-progress-linear indeterminate color="#332847"></v-progress-linear>
			</div>

			<v-row class="topMenuRow">
				<v-col class="topMenuCol">
					<v-btn :class="['topTabButton', { selected: section === 'd2d' }]" title="" @click="section='d2d'; drawerHeight = '79px'">Day-to-day</v-btn>
				</v-col>
				<v-col class="topMenuCol">
					<v-btn :class="['topTabButton', { selected: section === 'lt' }]" title="" @click="section='lt'; drawerHeight = '0px'">Long term</v-btn>
				</v-col>
				<v-col class="topMenuCol">
					<v-btn :class="['topTabButton', { selected: section === 'ins' }]" title="" @click="section='ins'; drawerHeight = '0px'">Insurances</v-btn>
				</v-col>
			</v-row>

			
			<div v-if="section == 'd2d'">
				<transition>
					<div class="relink" v-if="relinkNeeded">
						<AdButton title="Keep Your Accounts Connected" href="/#/Accounts/All/Relink" 
							subtitle="To keep your account data flowing smoothly, we need you to refresh the permissions for some of your connected bank accounts. This only takes a moment." 
							:topicon="require('@/assets/coins.png')" :bottomrighticon="require('@/assets/go.png')" 
						/>
					</div>
				</transition>
				<v-row>
					<v-col><AccountSummaryButton background="linear-gradient(251.68deg, #EEF0FF88 24.87%, #D4D9F288 72.24%)" href="#/accounts/current" title="Current" :icon="require('@/assets/round1.png')" :count="currentAccounts.length" :total="formatter.format(currentAccountsBalance)"></AccountSummaryButton></v-col>
					<v-col><AccountSummaryButton background="linear-gradient(243.52deg, #FCE5D488 18.85%, #E0C5C888 81.37%);" href="#/accounts/credit" title="Credit" :icon="require('@/assets/round2.png')" :count="creditAccounts.length" :total="formatter.format(Math.round(creditAccountsBalance))"></AccountSummaryButton></v-col>
				</v-row>
				<v-row>
					<v-col><AccountSummaryButton background="linear-gradient(243.52deg, #EFE8E688 16.63%, #CFC1C088 83.37%);" href="#/accounts/savings" title="Savings" :icon="require('@/assets/round3.png')" :count="savingsAccounts.length" :total="formatter.format(Math.round(savingsAccountsBalance))"></AccountSummaryButton></v-col>
					<v-col><AccountSummaryButton v-if="vouchers" background="linear-gradient(215.75deg, #9EA2D088 3.1%, #61678D88 95.08%);" href="#/MyVouchers" title="Retail Cards" :icon="require('@/assets/round1.png')" :count="vouchers.vouchers.length" :total="formatter.format(Math.round(vouchers.balance))" word="cards"></AccountSummaryButton></v-col>
				</v-row>
			</div>

			<div v-if="section == 'lt' && mortgageInfo && investmentInfo && creditInfo">
				<div :class="'hubItemWrapper'" v-for="m in mortgageInfo" :key="m.id">
					<AccountButton :href="'/#/Mortgages/' + m.id" title="Mortgage" :icon="require('@/assets/round1.png')" :provider="m.account.provider.name" :balance="formatter.format(Math.round(m.account.balances[0].amount))"></AccountButton>
				</div>
				<div :class="'hubItemWrapper'" v-for="i in investmentInfo" :key="i.id">
					<AccountButton :href="i.accountType.name === 'Pension' ? '/#/Pensions/' + i.id : '/#/Investments/' + i.id" :title="i.accountType.subType" :icon="require('@/assets/round1.png')" :provider="i.provider.name" :balance="formatter.format(Math.round(i.balance))"></AccountButton>
				</div>
				<div :class="'hubItemWrapper'" v-for="c in creditInfo" :key="c.id">
					<AccountButton :href="'/#/Credit/' + c.id" :title="c.creditType.name" :icon="require('@/assets/round1.png')" :provider="c.provider.name" :balance="formatter.format(Math.round(c.balance.amount))"></AccountButton>
				</div>
			</div>

			<div v-if="section == 'ins' && insuranceInfo">
				<div :class="'hubItemWrapper'" v-for="i in insuranceInfo" :key="i.id">
					<InsuranceButton :expiringText="getExpiringText(i)" :href="'/#/Policies/' + i.id" :title="i.policyType.name" :icon="require('@/assets/CarInsuranceIcon.svg')" :provider="i.provider.name" :expires="i.endDateString"></InsuranceButton>
				</div>
			</div>

			<div style="clear:both; padding-bottom: 110px;"></div>

			<div class="addButtons" v-if="!adding">
				<MGButton alt="true" :small="false" :circle="true" icon="mdi-plus" @click="openLinkDrawer" />
			</div>

			<DrawerPanel ref="transactionsDrawer" bottom="44px" title="Recent Transactions" backgroundColor="white" openBackgroundColor="white">
				<TransactionsList :showDateTotal="true" v-if="bankInfo" search="true" :transactions="bankInfo.recentTransactions" :formatter="formatter" :today="today" :yesterday="yesterday" />
			</DrawerPanel>

			<DrawerPanel ref="linkDrawer" openHeight="70%" bottom="-10px" title="Add an Account" backgroundColor="white" openBackgroundColor="white">
				<!-- <h2 class="centered">What sort of account would you like to add?</h2> -->
				<div>
					<div v-if="!ob">
						<p class="centered">What would you like to add?</p>
						<MGButton :icon="require('../assets/open-banking-black.png')" :outline="true" text="Link an Account" :arrow="true" :alt="true" @click="ob=true" />
						<p class="mt-2 small centered">Link an account with Open Banking - recommended</p>
						<MGButton :icon="require('../assets/coins.png')" :outline="true" :arrow="true" text="Offline Account" @click="$router.push('/Accounts/Current/Add')" />
						<MGButton :icon="require('../assets/coins.png')" :outline="true" :arrow="true" text="Credit" @click="$router.push('/Credit/Add')" />
						<MGButton :icon="require('../assets/coins.png')" :outline="true" :arrow="true" text="Mortgage" @click="$router.push('/Mortgages/Add')" />
						<MGButton :icon="require('../assets/coins.png')" :outline="true" :arrow="true" text="Pension" @click="$router.push('/Pensions/Add')" />
						<MGButton :icon="require('../assets/coins.png')" :outline="true" :arrow="true" text="Investment" @click="$router.push('/Investments/Add')" />
						<MGButton :icon="require('../assets/coins.png')" :outline="true" :arrow="true" text="Insurance" @click="$router.push('/Policies/Insurance/Add')" />
					</div>
					<div class="centered" v-if="ob">
						<p class="bold">You’ll be redirected to your banking app to grant permission.</p>
						<p>To keep your information secure, TrueLayer connects to your account using bank-grade encryption.</p>
						<a :href="this._getOpenBankingLinkUrl(this.customer.id)" target="_blank"><MGButton @click="linkBankAccount" text="Let's do it" alt="true" :small="false" /></a>
						<div class="mt-3" @click="ob=false">Back to selection</div>
					</div>
				</div>
			</DrawerPanel>

		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import AccountSummaryButton from './elements/AccountSummaryButton.vue';
import TransactionsList from './elements/TransactionsList.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import InsuranceButton from './elements/InsuranceButton.vue';
import AccountButton from './elements/AccountButton.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import MGButton from './elements/MGButton.vue';
import AdButton from './elements/AdButton.vue';

export default {
	mixins: [Services],
	components: { TransactionsList, CustomerLoadWait, AccountSummaryButton, InsuranceButton, 
		AccountButton, HeaderBlock, DrawerPanel, MGButton, AdButton },
	data() {
		return {
			section: 'd2d',
			drawerHeight: "79px",
			drawer:false,
			currentAccounts: [],
			currentAccountsBalance: 0,
			creditAccounts: [],
			creditAccountsBalance: 0,
			savingsAccounts: [],
			savingsAccountsBalance: 0,
			adding:false,
			ob:false,
			relinkNeeded: false,
		}
	},
	methods: {
		toggleTransactions: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = "79px";
			}
			else
			{
				this.drawerHeight = "80%";
			}
		},
		getBankInfo: function(onSuccess = null){
			this._getBankingInfo(() => {
				this.currentAccounts = [];
				this.currentAccountsBalance = 0;
				this.creditAccounts = [];
				this.creditAccountsBalance = 0;
				this.savingsAccounts = [];
				this.savingsAccountsBalance = 0;
				this.bankInfo.accounts.forEach((a) => 
				{
					if (a.consentExpired) {
						this.relinkNeeded = true;
					}
					if (a.accountType.prettyName == "Current")
					{
						this.currentAccounts.push(a);
						this.currentAccountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Credit")
					{
						this.creditAccounts.push(a);
						this.creditAccountsBalance += a.balance;
					}
					else if (a.accountType.prettyName == "Savings")
					{
						this.savingsAccounts.push(a);
						this.savingsAccountsBalance += a.balance;
					}
				});
				if (onSuccess) { onSuccess(); }
			});
		},

		openLinkDrawer: function(){
			this.$refs.linkDrawer.openDrawer();
		},

		openTransactionsDrawer: function(){
			this.$refs.transactionsDrawer.openDrawer();
		},

		linkBankAccount: function(){
			this.adding = true;
			this.$refs.linkDrawer.closeDrawer();
			var accountsCount = this.bankInfo.accounts.length;
			this.interval = setInterval(() => {
				if (!this.adding) { clearInterval(this.interval); return; }
				this._clearBankingInfo();
				this.getBankInfo();
				if (this.bankInfo.accounts.length > accountsCount)
				{
					this.adding = false;
					clearInterval(this.interval); return;
				}
			}, 5000);
		},

		getInsuranceInfo: function(){
			this._getInsuranceInfo(() => {
				
			});
		},
		getMortgageInfo: function(){
			this._getMortgageInfo(() => {
				
			});
		},
		getInvestmentInfo: function(){
			this._getInvestmentInfo(() => {
				
			});
		},
		getCreditInfo: function(){
			this._getCreditInfo(() => {
			});
		},
		getExpiringText: function(insurance){
			var days = this._daysBetweenDates(new Date(), new Date(insurance.endDate));

			if (days < 0)
			{
				return "Expired";				
			}
			if (days <= 1)
			{
				return "Expiring today";
			}
			if (days < 7)
			{
				return `Expiring in ${days} days`;
			}
			if (days < 30)
			{
				const weeks = Math.floor(days / 7);
				return `Expiring in ${weeks} week${weeks > 1 ? 's' : ''}`;
			}
			if (days < 60)
			{
				const months = Math.floor(days / 30);
				return `Expiring in ${months} months`;
			}
			else if (days < 120)
			{
				return null;
			}
		},
		onLoad: async function() {
			this._getCustomer(() => {
				this.getBankInfo(() => {
					if (this.$route.query.show) {
						setTimeout(() => {
							this.openTransactionsDrawer();
						}, 1000);
					}
					if (this.$route.query.add) {
						setTimeout(() => {
							this.openLinkDrawer();
						}, 1000);
					}
				});
				this._getCustomerVouchers();
				this.getInsuranceInfo();
				this.getMortgageInfo();
				this.getInvestmentInfo();
				this.getCreditInfo();
			});
		}
	},
	mounted: function () {
		this.onLoad();
	}
}
</script>

<style scoped>

.addingAccountsWidget {
	h--eight: 50px;
	b--order-bottom: 1px solid #e0e0e0;
	margin-left: -30px;
	margin-right: -30px;
	margin-bottom: 30px;
	margin-top: -20px;
	b--order-bottom-right-radius: 20px;
	b--order-bottom-left-radius: 20px;
	padding: 20px;
	background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%);
	transition: all 1s ease-out;
}

.topTabButton
{
	margin-top: 0px;
	text-transform: none;
	padding-top: 17px !important;
	padding-bottom: 30px !important;
	vertical-align: middle !important;
	background-color: #ffffff66 !important;
	font-weight: bold;
}

.topTabButton.selected {
    background-color: var(--text-color) !important;
	color: white !important;
}

.topMenuCol {
	padding-left: 3px;
	padding-right: 3px;
}

.topMenuRow
{
	margin-left: -5px;
	margin-right: -5px;
	margin-bottom: 15px;
}
.addButtons {
	position:fixed;
	bottom: 20%;
	right: 35px;
	margin-top: 20px;
	text-align: right;
}

.relink {
	margin-top: -15px;
	margin-bottom: 15px;
}

</style>
