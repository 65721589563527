<template>
	<header-block title="Plans"></header-block>
	<CustomerLoadWait :customer="ready" />

	<div class="monthSelector" v-if="selectedSlotKey">
		<v-slide-group
			:center-active="true" 
			class="monthSlider"
			v-model="selectedSlotKey">
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item :value="s.key" v-slot:default="{ isSelected, toggle }" v-for="s in slots" :key="s.key" >
				<span :class="isSelected ? 'selected' : ''" @click="toggle(); selectedSlotKey = s.key">{{ s.label }}</span>
			</v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>
			<v-slide-group-item><span></span></v-slide-group-item>

		</v-slide-group>
	</div>

	<transition>

		<div v-if="ready" class="mt-4">
			<v-row>
				<v-col>
					<InfoPanel 
						:icon="require('@/assets/Personalisation.png')" 
						title="Spending plan management" 
						subtitle="Create and maintain your spending plans, and track your progress over time" 
					/>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<PlanInfoPanel :plan="totalPlan" :spent="totalSpend" title="Overview" hideArrow="true" :key="totalSpend"
						:formatter="formatter" />
				</v-col>
			</v-row>

			<v-expansion-panels color="#EFE8E6" class="mt-4" variant="accordion">
				<v-expansion-panel value="futurePlans">
					<v-expansion-panel-title>
						<v-row class="analysisTitleRow">
							<v-col cols="11">
								<v-row class="classificationLabel">
									Future plans
								</v-row>
								<v-row>
									<v-progress-linear
										:class="futurePlansSpend > futurePlansPlan ? 'futurePlansExceeded' : 'futurePlansProgress'"
										height="7"
										:model-value="animatedFuturePlansProgress"
										color="primary"
										rounded>
									</v-progress-linear>
								</v-row>
							</v-col>
						</v-row>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<SpendingAnalysisSubCategory v-for="group in selectedFuturePlans.groupedAnalysis"
							@planClicked="(c) => { _handleCategoryClicked(c, 1) }"
							:key="group" 
							:group="group"
							:plans="spendingPlans"
							bgColour="#EFE8E6" 
							:planPage="true"
							:formatter="formatter">
						</SpendingAnalysisSubCategory>
						<BarArrowButton title="Create a new plan" :large="false" class="createNew" @click="openCreateDrawer(1, '#EFE8E6')"/>
					</v-expansion-panel-text>
				</v-expansion-panel>

				<v-expansion-panel color="#EEF0FF">
					<v-expansion-panel-title>
						<v-row class="analysisTitleRow">
							<v-col cols="11">
								<v-row class="classificationLabel">
									Everyday spending
								</v-row>
								<v-row>
									<v-progress-linear 
										:class="everyDaySpendSpend > everyDaySpendPlan ? 'everydaySpendExceeded' : 'everydaySpendProgress'"
										height="7"
										:model-value="animatedEveryDaySpendProgress"
										color="primary"
										rounded>
									</v-progress-linear>
								</v-row>
							</v-col>
						</v-row>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<SpendingAnalysisSubCategory v-for="group in selectedEverydaySpend.groupedAnalysis"  
							@planClicked="(c) => { _handleCategoryClicked(c, 2) }"
							:key="group" 
							:group="group"
							:plans="spendingPlans"
							bgColour="#EEF0FF" 
							:planPage="true"
							:formatter="formatter">
						</SpendingAnalysisSubCategory>
						<BarArrowButton title="Create a new plan" :large="false" class="createNew"  @click="openCreateDrawer(2, '#EEF0FF')"  />
					</v-expansion-panel-text>
				</v-expansion-panel>

				<v-expansion-panel color="#413655">
					<v-expansion-panel-title>
						<v-row class="analysisTitleRow">
							<v-col cols="11">
								<v-row class="classificationLabel">
									Committed costs
								</v-row>
								<v-row>
									<v-progress-linear 
										:class="committedCostsSpend > committedCostsPlan ? 'committedCostsExceeded' : 'committedCostsProgress'"
										height="7"
										:model-value="animatedCommittedCostsProgress"
										color="primary"
										rounded>
									</v-progress-linear>
								</v-row>
							</v-col>
						</v-row>
					</v-expansion-panel-title>
					<v-expansion-panel-text>
						<SpendingAnalysisSubCategory v-for="group in selectedCommittedCosts.groupedAnalysis"
							@planClicked="(c) => { _handleCategoryClicked(c, 3) }"
							:key="group" 
							:group="group"
							:plans="spendingPlans"
							:planPage="true"
							:bgColour="`#413655`"
							:formatter="formatter">
						</SpendingAnalysisSubCategory>
						<BarArrowButton title="Create a new plan" :large="false" class="createNew" @click="openCreateDrawer(3, '#413655')"/> <!-- TO DO - I can't get the ENUM to work here!! -->
					</v-expansion-panel-text>
				</v-expansion-panel>
			</v-expansion-panels>
		</div>
	</transition>
	<DrawerPanel @goBack="handleDrawerGoBack" :back="true"  @closed="_handleDrawerClosed"
		openHeight="85%" closedHeight="0px"
		ref="transactionDrawer" bottom="44px" title="Spending Plan" backgroundColor="white" openBackgroundColor="white">

		<!-- Page 1 -->
		<div v-if="!showPlanManagement">
			<div class="centered">
				<h2>Total Spend</h2>
			</div>
			<div class="centered">
				<h1>{{ formatter.format(Math.abs(Math.round(selectedClassificationSpend))) }}</h1>
			</div>
			<PlanInfoPanel v-if="selectedClassificationPlanAmount > 0 && selectedClassification" 
				:alt="true"
				@click="_navigateToPlan(selectedClassification)" 
				:plan="selectedClassificationPlanAmount" 
				:spent="Math.abs(selectedClassificationSpend)" 
				hideArrow="true" :key="totalPlan"
				title="Spending Plan"
				:formatter="formatter" 
				:iconPath="selectedClassification.iconPath" 
				:bgColour="selectedClassification.bgColour"/>
			<BarArrowButton v-else title="Create a new plan" :large="false" class="createNew" @click="_navigateToPlan(selectedClassification)"/>
			<WellPanel :data="categoryWellData" @merchantClicked="(m) => {_handleMerchantClicked(m)}" />
		</div>
		<!-- Page 3-->
		<div v-if="showPlanManagement">
			<SpendingPlanManagement
				@createPlan="(p) => { createPlan(p) }" 
				@updatePlan="(p) => { updatePlan(p) }" 
				@deletePlan="(p) => { deletePlan(p)}" 
				:managePlan="managePlan" 
				:selectedPlan="selectedPlan" 
				:sections="sections"
				:categoriesPanel="categoriesPanel" 
				:themeColour="themeColour" 
				:formatter="formatter"/>
		</div>
	</DrawerPanel>
</template>

<script>

import Services from '../mixins/Services';
import Helpers from '../mixins/Helpers';
import SpendingPlans from '../mixins/SpendingPlans';
import SpendingAnalysis, { GRANDPARENT_CLASSIFICATIONS } from '../mixins/SpendingAnalysis';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import SpendingAnalysisSubCategory from './elements/SpendingAnalysisSubCategory.vue';
import PlanInfoPanel from './elements/PlanInfoPanel.vue';
import InfoPanel from './elements/InfoPanel.vue';
import BarArrowButton from './elements/BarArrowButton.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import SpendingPlanManagement from './elements/SpendingPlanManagement.vue';
import WellPanel from './elements/WellPanel.vue';

export default {
	mixins: [Services, Helpers, SpendingAnalysis, SpendingPlans],
	components: {CustomerLoadWait, HeaderBlock, SpendingAnalysisSubCategory, PlanInfoPanel, BarArrowButton, DrawerPanel, 
		InfoPanel, SpendingPlanManagement, WellPanel},
	data() {
		return {		
			customer: null,
			isEveryDaySpendCollapsed: false,
			isFuturePlansCollapsed: true,
			isCommittedCostsCollapsed: true,
			months: [],
			years: [],
			slots: [],
			allEveryDaySpend: [],
			allFuturePlans: [],
			allCommittedCosts: [],
			allIncome: [],
			selectedSlotKey: null,
			selectedTotals: [],
			ready: false,
			categoriesPanel: [],
			totalPlan: 0,
			committedCostsPlan: 0,
			everyDaySpendPlan: 0,
			futurePlansPlan: 0,
			totalSpend: 0,
			committedCostsSpend: 0,
			everyDaySpendSpend: 0,
			futurePlansSpend: 0,
			committedCostsProgress: 0,
			createNewButtonClicked: false,
			everyDaySpendProgress: 0,
			futurePlansProgress: 0,
			managePlan: false,
			selectedPlan: [],
			closeDrawerNext: false,
			currentCategoryId: null,
			themeColour: null,
			animatedEveryDaySpendProgress: 0,
			animatedFuturePlansProgress: 0,
			animatedCommittedCostsProgress: 0,
			sections: 
			[
				{
					fields: [
					{
							label: 'Plan amount',
							type: 'currency',
							value: null,
							required: true
						}
					]
				},
			],
			selectedCategoryCache: []
		};
	},
	computed: {
		selectedSpendingAnalysis() {
			return this.selectedTotals;
		},

		selectedEverydaySpend() {
			return this.selectedCategory(this.allEveryDaySpend, GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND);
		},

		selectedFuturePlans() {
			return this.selectedCategory(this.allFuturePlans, GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING);
		},

		selectedCommittedCosts() {
			return this.selectedCategory(this.allCommittedCosts, GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS);
		},

		selectedIncome() {
			var selected = this.allIncome.find(s => s.slotName === this.selectedSlotKey);
			if (!selected) {
				selected = {
					label: this.selectedSlotKey,
					totalIn: 0,
					totalOut: 0,
					totalNet: 0,
					groupedAnalysis: []
				};
			}
			if (selected.groupedAnalysis.length > 0) {
				selected.groupedAnalysis[0].name = this.customer.employer || 'Employer';
			}
			return selected;
		},

		everyDaySpendingPlanProgressPercent() {
			return Math.min(100, Math.max(0, Math.round((this.everyDaySpendSpend / this.everyDaySpendPlan) * 100)));
		},

		futurePlansProgressPercent() {
			return Math.min(100, Math.max(0, Math.round((this.futurePlansSpend / this.futurePlansPlan) * 100)));
		},

		committedCostsProgressPercent() {
			return Math.min(100, Math.max(0, Math.round((this.committedCostsSpend / this.committedCostsPlan) * 100)));
		}
	},
	watch: {
		selectedSlotKey() {
			this._updateSelectedTotals();
			this.getPlanTotals();
			this.animateEveryDaySpendingProgress(this.everyDaySpendingPlanProgressPercent);
			this.animateFuturePlansProgress(this.futurePlansProgressPercent);
			this.animateCommittedCostsProgress(this.committedCostsProgressPercent);

		}
	},
	mounted: function() { 
		this.onLoad(); 
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				this._getBankingInfo();
				this._getSpendingAnalysis(() => {
					this._getSpendingPlansFull(() => {
						this._extractLitePlansFromFullPlans();
						this._setupCategories();
						this._updateSelectedTotals();
						this.getPlanTotals();
						this.animateEveryDaySpendingProgress(this.everyDaySpendingPlanProgressPercent);
						this.animateFuturePlansProgress(this.futurePlansProgressPercent);
						this.animateCommittedCostsProgress(this.committedCostsProgressPercent);
						this.ready = true;	
					});
				});
			});
		},

		handleDrawerGoBack() {
			if (this.showPlanManagement) {
				// if a went to the list first by clicking create new
				if (this.createNewButtonClicked) {
					// if i'm on the 2nd page, go to the 1st page
					if (this.managePlan) {
						this._setFieldValue('Plan amount', 0);
						this.managePlan = false
					}
					// if I'm on the 1st page, close plan management
					else
					{
						this.selectedClassificationPlanAmount = 0;
						this.selectedClassificationSpend = 0;
						this.createNewButtonClicked = false;
						this._setFieldValue('Plan amount', 0);
						this.$refs.transactionDrawer.closeDrawer();
					}
				}
				else {
					this.showPlanManagement = false;
					this.closeDrawerNext = true;
				}
			}
			else {
				this.selectedClassificationPlanAmount = 0;
				this.selectedClassificationSpend = 0;
				this._setFieldValue('Plan amount', 0);
				this.$refs.transactionDrawer.closeDrawer();
			}
		},

		updatePlan(p) {
			this._updatePlan(p, false);
			this.showPlanManagement = false, 
			this.closeDrawerNext = true
			this.selectedClassificationPlanAmount = p.plan.amount;
		},

		deletePlan(p) {
			this._deletePlan(p, false)
			this.showPlanManagement = false
			this.closeDrawerNext = true
			this.selectedClassificationPlanAmount = 0;
		},

		createPlan(p) {
			this.managePlan = false
			this._createPlan(p);
		},

		animateEveryDaySpendingProgress(targetValue) {
			this.animatedEveryDaySpendProgress = 0;
			const increment = targetValue / 50;
			const interval = setInterval(() => {
				if (this.animatedEveryDaySpendProgress < targetValue) {
					this.animatedEveryDaySpendProgress += increment;
				} else {
					this.animatedEveryDaySpendProgress = targetValue;
					clearInterval(interval);
				}
			}, 10);
		},

		animateFuturePlansProgress(targetValue) {
			this.animatedFuturePlansProgress = 0;
			const increment = targetValue / 50;
			const interval = setInterval(() => {
				if (this.animatedFuturePlansProgress < targetValue) {
					this.animatedFuturePlansProgress += increment;
				} else {
					this.animatedFuturePlansProgress = targetValue;
					clearInterval(interval);
				}
			}, 10);
		},

		animateCommittedCostsProgress(targetValue) {
			this.animatedCommittedCostsProgress = 0;
			const increment = targetValue / 50;
			const interval = setInterval(() => {
				if (this.animatedCommittedCostsProgress < targetValue) {
					this.animatedCommittedCostsProgress += increment;
				} else {
					this.animatedCommittedCostsProgress = targetValue;
					clearInterval(interval);
				}
			}, 10);
		},

		getPlanTotals() {
			const everydaySpendPlans = this.spendingPlans.plans.filter(plan => 
				plan.classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND
			);
			const filteredEverydaySpend = this.selectedEverydaySpend.groupedAnalysis.filter(group => 
				everydaySpendPlans.some(plan => plan.classification.id === group.transactionClassification.id)
			);

			this.everyDaySpendPlan = everydaySpendPlans.reduce((a, b) => a + b.plan.amount, 0);
			this.everyDaySpendSpend = filteredEverydaySpend.reduce((a, b) => a + Math.abs(b.amountNet), 0);

			const futurePlansPlans = this.spendingPlans.plans.filter(plan => 
				plan.classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING
			);
			const filteredFuturePlans = this.selectedFuturePlans.groupedAnalysis.filter(group => 
				futurePlansPlans.some(plan => plan.classification.id === group.transactionClassification.id)
			);

			this.futurePlansPlan = futurePlansPlans.reduce((a, b) => a + b.plan.amount, 0);
			this.futurePlansSpend = filteredFuturePlans.reduce((a, b) => a + Math.abs(b.amountNet), 0);

			const committedCostsPlans = this.spendingPlans.plans.filter(plan => 
				plan.classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS
			);
			const filteredCommittedCosts = this.selectedCommittedCosts.groupedAnalysis.filter(group => 
				committedCostsPlans.some(plan => plan.classification.id === group.transactionClassification.id)
			);

			this.committedCostsPlan = committedCostsPlans.reduce((a, b) => a + b.plan.amount, 0);
			this.committedCostsSpend = filteredCommittedCosts.reduce((a, b) => a + Math.abs(b.amountNet), 0);

			this.totalSpend = this.everyDaySpendSpend + this.futurePlansSpend + this.committedCostsSpend;
			this.totalPlan = this.everyDaySpendPlan + this.futurePlansPlan + this.committedCostsPlan;

			this.planReady = true;
		},

		openCreateDrawer: function(classificationId, colour){
			this.createNewButtonClicked = true;
			this.managePlan = false;
			this.showPlanManagement = true;
			this.closeDrawerNext = true;
			this.categoriesPanel = this.spendingPlansFull.plans.filter(plan => plan.classification.grandParentClassification === classificationId);
			this.currentCategoryId = classificationId;
			this.themeColour = colour;
			this.$refs.transactionDrawer.openDrawer();
		},

		handleBulkCategorisationClick(classification) {
			// get the selected slot
			var selectedSlot = this.slots.find(s => s.key === this.selectedSlotKey);
			var includePositive = classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.INCOME;
			var includeNegative = classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING || classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND || classification.grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS;
			this.$router.push({ path: '/Transaction', query: { from: selectedSlot.from, to: selectedSlot.to, includeNegative: includeNegative, includePositive: includePositive } });
		},

		selectedCategory(categoryArray, grandparentClassification) {
			if (this.selectedCategoryCache[grandparentClassification] != null)
			{
				return this.selectedCategoryCache[grandparentClassification];
			}
			var selected = categoryArray.find(s => s.slotName === this.selectedSlotKey) 
			if (!selected) {
				selected = {
					label: this.selectedSlotKey,
					totalIn: 0,
					totalOut: 0,
					totalNet: 0,
					groupedAnalysis: []
				};
			}
			// add any missing groups that we have plans for
			var newGroups = [];
			var plans = this.spendingPlans.plans.filter(plan => plan.classification.grandParentClassification === grandparentClassification);
			plans.forEach(plan => {
				// if this plan isn't found in the analysis, add it
				if (!selected.groupedAnalysis.find(g => g.name === plan.classification.fullName))
				{
					newGroups.push({
						name: plan.classification.fullName,
						transactionClassification: plan.classification,
						amountIn: 0,
						amountOut: 0,
						amountNet: 0,						
					});
				}
			});
			selected.groupedAnalysis = selected.groupedAnalysis.concat(newGroups);
			// sort the groups by amountOut (abs)
			selected.groupedAnalysis.sort((a, b) => Math.abs(b.amountOut) - Math.abs(a.amountOut));
			// If the groupedAnalysis.name is 'Uncategorised' then change then name to: 'Tag n transactions'.  Get n from groupedAnalysis.countNegativeTransactions
			selected.groupedAnalysis.forEach(group => 
				{
					if (group.name === 'Uncategorized') {
						group.name = 'Tag ' + group.countNegativeTransactions + ' transaction' + (group.countNegativeTransactions !== 1 ? 's' : '');
					}
				}
			);
			// Move the 'Tag n transactions' group to the top
			const uncategorizedGroupIndex = selected.groupedAnalysis.findIndex(group => group.name.startsWith('Tag '));
			if (uncategorizedGroupIndex > -1) {
				const [uncategorizedGroup] = selected.groupedAnalysis.splice(uncategorizedGroupIndex, 1);
				selected.groupedAnalysis.unshift(uncategorizedGroup);
			}
			this.selectedCategoryCache[grandparentClassification] = selected;
			return selected;
		},
	}
};

</script>

<style scoped>

.monthSelector {

	margin-bottom: 15px;

	.monthSlider {
		font-size: 12px;
		span {
			margin-right: 25px;
			margin-left: 25px;
		}
		span.selected {
			color: var(--primary-color);
			font-weight: bold;
		}
	}

	.selectedDot {
		width: 7px;
	}
}

.analysisTitleRow {
	.v-col {
		font-size: 15px !important;
	}
}

.createNew {
	margin-top: 15px;
}

.createPlanHeader {
	font-weight: bold;
	padding: 5px;
	
}

.icon {
	width: 35px;
	background-color: #E7ECF7;
	border-radius: 90px;
}

.plusCol {

}

.plusIcon {
	width: 30px;
}

.backIcon {
	width: 20px;
}

/* .drawerContent {
    overflow-y: scroll;
	overflow-x: hidden;
    max-height: calc(75vh - 100px); 
} */

.simplePage {
	h1 {
		font-size: 28px;
	}
	.smallHeroImg {
		width: 70px;
		background-color: var(--text-color);
		border-radius: 50%;
	}
	.v-text-field {
		width: 60%;
		margin-left: auto;
		margin-right: auto;
	}
}

.inputAmount {
	width: 60%;
		margin-left: auto;
		margin-right: auto;
}

.everydaySpendProgress {
	margin-top: 10px;
	margin-bottom: 10px;
}

.everydaySpendExceeded {
	margin-top: 10px;
	margin-bottom: 10px;
}

.committedCostsProgress {
	margin-top: 10px;
	margin-bottom: 10px;
}

.committedCostsExceeded {
	margin-top: 10px;
	margin-bottom: 10px;
}

.futurePlansProgress {
	margin-top: 10px;
	margin-bottom: 10px;
}

.futurePlansExceeded {
	margin-top: 10px;
	margin-bottom: 10px;
}

.classificationLabel {
	margin-top: 0px;
}

.everydaySpendProgress :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.everydaySpendExceeded :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #FFA726 3.1%, #9EA2D0 95.08%);
}

.committedCostsProgress :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.committedCostsExceeded :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #FFA726 3.1%, #9EA2D0 95.08%);
}

.futurePlansProgress :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.futurePlansExceeded :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #FFA726 3.1%, #9EA2D0 95.08%);
}

.everydaySpendProgress :deep(.v-progress-linear__background.bg-primary) {
	background-color: white !important;
	opacity: 1 !important
}

.committedCostsProgress :deep(.v-progress-linear__background.bg-primary) {
	background-color: white !important;
	opacity: 1 !important
}

.futurePlansProgress :deep(.v-progress-linear__background.bg-primary) {
	background-color: white !important;
	opacity: 1 !important
}

</style>