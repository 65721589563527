<template>
	
	<div>

		<div class="financialFitness" v-if="!mini">
			<v-progress-circular v-if="!mini" width="5" :model-value="started" :size="160" color="#9EA2D0" bg-color="#9EA2D011">
				<v-progress-circular width="5" :model-value="dayToDay" :size="130" color="#C7989E" bg-color="#C7989E11">
					<v-progress-circular width="5" :model-value="security" :size="100" color="#77A4A7" bg-color="#77A4A711">
						<v-progress-circular width="5" :model-value="future" :size="70" color="#5f5672" bg-color="#5f56727511">
							
								<div v-if="score != undefined && score != null" class="score">{{ score }}</div>
							
						</v-progress-circular>
					</v-progress-circular>
				</v-progress-circular>
			</v-progress-circular>
		</div>

		<v-progress-circular v-if="mini" width="4" :model-value="score" :size="85" color="#E3C9C2" bg-color="#f7faf7">
			<div v-if="score != undefined && score != null" :class="'score ' + (mini ? 'mini' : '')">{{ score }}</div>
		</v-progress-circular>

	</div>
</template>

<script>
export default {
	name: "FinancialFitnessScore",
	props: ['started', 'dayToDay', 'security', 'future', 'score', 'mini'],
	data() {
		return{
			something: null
		}
	},
	computed: {
		somethingElse() {
			return null;
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.financialFitness {
		width: calc(160px + 40px);
		height: calc(160px + 40px);
		border-radius: 50%;
		padding: 20px;
		margin-right: auto;
		margin-left: auto;
		background-color: #faf9f7;
	}
	.score {
		text-align: center;
		font-size: 15px;
		background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%);
		border-radius: 50%;
		height: 45px;
		width: 45px;
		padding-top: 9px;
		aspect-ratio: 1;
		border: 3px solid white;
		color: var(--text-color)
	}

	.mini {
		height: 75px;
		width: 75px;
		padding-top: 19px;
		font-size: 20px;
		font-weight: bold;
	}

	.v-progress-linear__indeterminate {
		animation-duration: 5s !important;
	}


</style>