<template>
	<BarArrowButton :large="true" :title="this.title ? this.titleLabel : null">
		<v-row class="barArrowButtonSlotContent">
                <v-col class="centered" v-if="!totals">
                    <v-progress-circular indeterminate></v-progress-circular>
                </v-col>
            </v-row>
		<div class="barArrowButtonSlotContent" v-if="totals">
			<v-row>
				<Bar
					ref="spendChart"
					id="spend-chart-id"
					:options="chartOptions"
					:data="chartData"
				/>
			</v-row>
			<v-row>
				<v-col class="centered">In: {{ formatter.format(Math.round(totalIn)).replace('-', '') }}</v-col>
				<v-col class="centered">Pay: {{ daysRemaining }} day{{ daysRemaining === 1 ? '' : 's' }}</v-col>
				<v-col class="centered">Out: {{ formatter.format(Math.round(totalOut)).replace('-', '') }}</v-col>
			</v-row>
		</div>
	</BarArrowButton>




</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { GRANDPARENT_CLASSIFICATIONS } from '../../mixins/SpendingAnalysis';
import BarArrowButton from './BarArrowButton.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
	name: "AnalysisWidget",
	components: { Bar, BarArrowButton },
	props: ['totals', 'formatter', 'title', 'titleLabel', 'daysRemaining', 'titleSize'],
	data() {
		return {
			titleFontSize: '20px',
			chartData: {
				labels: [ 'Income', 'Spend' ],
				datasets: [ 
					{ 
						label: 'Income',
						backgroundColor: '#B4D8D7',
						data: [],
						borderRadius: 10
					},
					{
						label: 'Fixed Costs',
						backgroundColor: '#413655',
						data: [],
						borderRadius: 10
					},
					{
						label: 'Everyday Spend',
						backgroundColor: '#E3E6FA',
						data: [],
						borderRadius: 10
					},
					{
						label: 'Future Planning',
						backgroundColor: '#F0D8CF',
						data: [],
						borderRadius: 10
					}
				],
			},
			chartOptions: {
				responsive: true,
				maintainAspectRatio: false,
				scales: {
					x: {
						stacked: true,
						grid: {
							display: false 
						},
						ticks: {
							display: false 
						}
					},					
					y: {
						stacked: true,
						grid: {
							display: true 
						},
						ticks: {
							display: true,
							maxTicksLimit: 5,
							callback: function(value) {
								if (value >= 1000) {
									return '£' + Math.round(value / 1000) + 'k';
								}
								return '£' + value;
							}
						}
					}
				},
				plugins: {
					legend: {
						display: false
					},
					title: {
						display: false
					},
					tooltip: {
						callbacks: {
							label: function(context) {
								let label = context.dataset.label || '';
								if (label) {
									label += ': ';
								}
								if (context.parsed.y !== null) {
									label += '£' + context.parsed.y.toLocaleString();
								}
								return label;
							}
						}
					}
				}
			},
			totalIn: 0,
			totalOut: 0
		}	
	},
	watch: {
		totals: {
			immediate: true,
			handler(newVal) {
				if (newVal) {
					var income = newVal[GRANDPARENT_CLASSIFICATIONS.INCOME].Amount;
					var fixedCosts = newVal[GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS].Amount;
					var everyDaySpend = newVal[GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND].Amount;
					var futurePlanning = newVal[GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING].Amount;
					this.chartData.datasets[0].data = [
						income,
						0 // Placeholder for Spend
					];
					this.chartData.datasets[1].data = [
						0, // Placeholder for Income
						fixedCosts
					];
					this.chartData.datasets[2].data = [
						0, // Placeholder for Income
						everyDaySpend
					];
					this.chartData.datasets[3].data = [
						0, // Placeholder for Income
						futurePlanning
					];
					this.totalIn = income;
					this.totalOut = fixedCosts + everyDaySpend + futurePlanning;
					//console.log('this.chartData', this.chartData);

					// Create a deep copy of chartData to trigger reactivity
					this.chartData = JSON.parse(JSON.stringify(this.chartData));

					// Update the chart
					this.$nextTick(() => {
						if (this.$refs.spendChart && this.$refs.spendChart.chart) {
							this.$refs.spendChart.chart.update();
						}
					});
				}
			}
		}
	},
	methods: {
		onLoad() {
			if (this.titleSize === 'small') {
				this.titleFontSize = '16px';
			}
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.barArrowButtonSlotContent {
	padding: 20px;
	padding-top: 12px;
	padding-bottom: 5px;
}

.analysis-widget {
	border-radius: 8px;
	padding: 15px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
	box-shadow: 0px 4px 8px 0px rgba(51, 40, 71, 0.05);
}

.title-col {
	font-size: 20px;
	white-space: nowrap;
	margin-top: auto;
	margin-bottom: auto;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
	margin-top: auto;
	margin-bottom: auto;
}

.arrow {
	height: 10px;
}


</style>