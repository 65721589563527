<template>
	<header-block title="Spending Analysis"></header-block>
	<CustomerLoadWait :customer="cycleId" />
	<transition>
		<div class="simplePage" v-if="cycleId">
			<h1>Set Pay Day</h1>
			
			<div class="calendar">
				<div class="calendar-grid">
				<div class="calendar-days">
					<template v-for="(day, index) in 31" :key="index">
					<v-btn
						v-if="day !== null"
						:class="{ 'selected': selectedDay === day }"
						:variant="selectedDay === day ? 'tonal' : 'text'"
						density="comfortable"
						@click="selectDay(day)"
					>
						{{ day }}
					</v-btn>
					<div v-else class="calendar-placeholder"></div>
					</template>
				</div>
				</div>
			</div>
			
			<p>By default, the Spending Analysis and Plan reset on the 1st of every month. However, this can be changed to the day you get paid to help you better manage your monthly finances. Select the day on the calendar above.</p>
			<MGButton text="OK" :alt="true" @click="setPaymentCycle" :arrow="true"/>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, MGButton},
	data() {
		return {
			cycleId: null,
			days: Array.from({ length: 31 }, (_, i) => i + 1),
			selectedDay:null
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				this._getBankingInfo(() => {
					this.cycleId = this.bankInfo.spendingCycle.id;
					this.selectedDay = this.bankInfo.spendingCycle.day;
				})
			});
		},
		setPaymentCycle: function() {
			this._setupSpendingCycle(this.customer.id, this.cycleId, this.selectedDay, true, () => {
				this._clearBankingInfo();
				this.$router.push({path: '/activity'});
			});
		},
		selectDay: function(day) {
			this.selectedDay = day;
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.calendar {
  background: var(--background-elevated-color);
  border-radius: 12px;
  padding: 12px 8px;
  margin: 0 -8px 24px;
  border: 1px solid rgba(var(--primary-color-rgb), 0.1);
  transition: all 0.2s ease;
  width: calc(100% + 16px);
  display: flex;
  justify-content: center;
}

.calendar:hover {
  border-color: rgba(var(--primary-color-rgb), 0.2);
  background: rgba(var(--primary-color-rgb), 0.03);
}

.calendar-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.calendar-days {
  display: inline-grid;
  grid-template-columns: repeat(7, 36px);
  gap: 4px;
  padding: 4px;
}

.calendar-placeholder {
  width: 36px;
  height: 36px;
  flex-shrink: 0;
}

.calendar-days .v-btn {
  width: 36px;
  height: 36px;
  min-width: 36px;
  max-width: 36px;
  border-radius: 50%;
  font-size: 14px;
  color: var(--text-color);
  opacity: 0.87;
  transition: all 0.2s ease;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
}

.calendar-days .v-btn:hover {
  transform: scale(1.05);
  opacity: 1;
}

.calendar-days .v-btn.selected {
  background: var(--text-color);
  color: white;
  opacity: 1;
  box-shadow: var(--shadow-sm);
  transform: scale(1.05);
}

</style>
