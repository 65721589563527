<template>
	<HeaderBlock title="" bgcolor="transparent" border="0" />
	<CustomerLoadWait :customer="article" />
	<transition>
		<div v-if="article" class="article">
			<div class="articleImg" :style="'background-image: url(' + article.image + ')'"></div>
			<!-- <img :src="article.image" /> -->
			<h1>{{ article.title }}</h1>
			<p><v-chip variant="outlined" size="small">{{ article.tagsArray[0] }}</v-chip></p>
			<p v-html="article.body.replaceAll('</h2>\n\n', '</h2>\n').replaceAll('\n', '<br/>')" />
		</div>	
	</transition>
	<ChatMgHoverButton :icon="require('@/assets/ai.png')" bottom="10%" v-if="article" :prompt="'Summarise this article in one very short paragraph, use emojis: ' + article.body" />

</template>

<script>
import Services from '../mixins/Services';
import ChatMgHoverButton from './elements/ChatMgHoverButton.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, ChatMgHoverButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCmsArticle(this.$route.params.id);
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.article {
	.articleImg {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 300px;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -110px;
	}
	img {
		width: 100%;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -30px;
		/* width: 100vw;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -30px; */
		margin-bottom: 15px;
	}
	h1 {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.v-chip {
		margin-bottom: 15px;
	}
	p {
		margin-top: 15px;
		margin-bottom: 15px;
	}
}


</style>
