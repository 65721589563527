<template>
	
		<HeaderBlock bgcolor="#00000000" border="0" />
	
		<div class="simplePage" v-if="!about" style="margin-top: -40px;">
			<img src="../assets/financialFitness.png" />
			<h1>Financial Fitness Score</h1>
			<p>Our financial fitness survey has been developed after extensive research by MoneyHelper.</p>
			<p>The survey will help to identify areas of your finance that you may want to prioritise.</p>
			<p class="bold">The survey should take you no more than 10 minutes to complete.</p>
			<div class="info onlyTallScreen">
				<h1>Your details are private & confidential.</h1>
				<p>We will not share any of your information with MoneyHelper, your employer or any other 3rd party organisation</p>
			</div>
			<div class="anchor-bottom">
				<v-btn class="mg-button" @click="about=true">Get Started<span class="tick"><img class="arrow" :src="require('@/assets/light-arrow-right.png')" /></span></v-btn>
				<p class="partnership">In partnership with <img src="../assets/moneyHelper.png" /></p>
			</div>
		</div>
	

	<transition>
		<div class="simplePage" v-if="about" style="margin-top: -40px;">
			<div class="well">
				<img src="../assets/moneyHelper.png" />
				<h1>Making your money and pension choices clearer</h1>
				<p>MoneyHelper is here to help, so you can move on with life. Here to cut through the jargon and complexity, explain what you need to do and how you can do it. Here to put you in control, with free, impartial help for money and pensions that's quick to find, easy to use and backed by government.</p>
				<p class="onlyTallScreen">Whatever your circumstances or plans, we offer clear guidance online, over the phone and face-to-face. We can also point you to trusted services, if you need more support.</p>
				<p class="onlyTallScreen">Find out more at:</p>
				<p class="bold onlyTallScreen">www.moneyhelper.org.uk</p>
			</div>
			<div class="anchor-bottom">
				<v-row>
					<v-col cols="5"><v-btn class="mg-button outline" @click="about=false">Back</v-btn></v-col>
					<v-col><v-btn class="mg-button" @click="startSurvey">Next<span class="tick"><img class="arrow" :src="require('@/assets/light-arrow-right.png')" /></span></v-btn></v-col>
				</v-row>
			</div>
		</div>
	</transition>

</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock},
	data() {
		return {
			about: false
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
		},
		startSurvey: function() {
			this._getQuestionnaireByKey('FFS', (q) => {
				console.log(q, 'Found this questionnaire');
				this.$router.push({path: '/Questionnaire/' + q.id, query: {theme: 'light', allowBack: true, allowSkip: false, escapeRoute:'you'}});
			});	
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.simplePage {
	text-align: center;
	.v-col{
		padding: 0px;
	}
	.well {
		margin-top: 45px;
		padding: 25px;
		background-color: #f5f3f5;
		border-radius: 24px;
		h1{
			font-size: 16px;
		}
	}
	img {
		width: 96px;
		margin-bottom: 15px;
		margin-top: 15px;
	}
	h1 {
		font-size: 24px;
		margin-top: 8px;
		margin-bottom: 15px;
		text-align: left;
	}
	p {
		margin-top: 15px;
		margin-bottom: 15px;
		font-size: 14px;
		text-align: left;
	}
	.info {
		text-align: left;
		padding: 15px;
		background-color: var(--background-color-alt);
		border-radius: 6px;
		h1{
			font-weight: bold;
			font-size: 12px;
		}
		p {
			font-size: 12px;
			margin-top: 15px;
			margin-bottom: 15px;
		}
	}
	.tick {
		text-align: right;
		float: right;
	}
	.arrow {
		margin-top: 20px;
		width: 12px;
		margin-left: 10px;
	}
	.partnership{
		margin-top: 30px;
		text-align: center;
		img {
			margin: 0px;
			margin-left: 5px;
			margin-bottom: -5px;
			width: 64px;
		}
	}
}

</style>
