<template>

	<transition>
		<div class="bg" v-if="this.drawer && this.blur" @click="toggleDrawer" />
	</transition>
	
	<div class="drawer" :style="'bottom: ' + bottom + '; background-color: ' + (drawer ? openBackgroundColor : backgroundColor) + '; height: ' + drawerHeight" @click="toggleDrawer">
		<v-list-item style="text-align: center;">
			<div class="divider"></div>
			<v-row>
				<v-col cols="2">
					<div v-if="back" class="mg-circle-image-button" @click="event => { event.stopPropagation(); this.$emit('goBack') }"><v-icon icon="mdi-arrow-left"/></div>
				</v-col>
				<v-col>
					<p class="title">{{ title }}</p>
				</v-col>
				<v-col cols="2">
					<div v-if="this.drawer" class="mg-circle-image-button" @click="event => { event.stopPropagation(); closeDrawer() }"><v-icon icon="mdi-close"/></div>
				</v-col>
			</v-row>
			
			
		</v-list-item>
		<v-divider></v-divider>
		<div class="panelContent" @click="event => {event.stopPropagation()}">
			<slot />
		</div>
	</div>
	
</template>

<script>
export default {
	name: "DrawerPanel",
	emits: ['opened', 'closed', 'goBack'],
	props: ['title', 'backgroundColor', 'openBackgroundColor', 'bottom', 'openHeight', 
	'closedHeight', 'startOpened', 'blurBackground', 'back'],
	data() {
		return{
			drawerHeight: this.closedHeight ?? "82px",
			drawer:false,
			blur: true
		}
	},
	methods: {
		toggleDrawer: function() {
			this.drawer = !this.drawer;
			if (!this.drawer)
			{
				this.drawerHeight = this.closedHeight ?? "82px";
				this.$emit("closed");
			}
			else
			{
				this.drawerHeight = this.openHeight ?? "80%";
				this.$emit("opened");
			}
		},
		openDrawer: function() {
			this.drawer = true;
			this.drawerHeight = this.openHeight ?? "80%";
			this.$emit("opened");
		},
		closeDrawer: function() {
			this.drawer = false;
			this.drawerHeight = this.closedHeight ?? "82px";
			this.$emit("closed");
		},
		onLoad() {
			if (this.startOpened)
			{
				this.toggleDrawer();
			}
			if (this.blurBackground !== null && this.blurBackground !== undefined)
			{
				this.blur = this.blurBackground;
			} 
			else {
				this.blur = true;
			}
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

	.panelContent {
		padding: 20px;
		padding-bottom: 150px;
		height: 100%;
		overflow-y: scroll;
	}

	.title{
		font-weight: bold;
	}

	.divider {
		width: 48px;
		margin-bottom: 8px;
		margin-top: 8px;
		margin-right: auto;
		margin-left: auto;
		background: var(--Keyline, rgba(51, 40, 71, 0.10));
		height: 4px;
		border-radius: 4px;
	}

	.drawer {
		border-radius: 16px 16px 0px 0px;
		border: 0px;
		overflow-y: hidden;
		position: fixed;
		width: 100%;
		left: 0px;
		transition: all .3s ease-out;
		z-index: 1000 !important;
	}

	.bg {
		z-index: 999 !important;
		position: fixed;
		width: 100vw;
		height: 100vh;
		top: 0px;
		left: 0px;
		background-color: #332847b5;
	}

	.mg-circle-image-button {
		float:left;
		background-color: #ffffff77;
		border-radius: 50% !important;
		height: 33px !important;
		width: 33px !important;
		padding-top: 7px !important;
		text-align: center;
		margin-top: -5px;
		font-size: 12px;
	}

</style>