<template>
	<header-block :title="this.$route.params.id ? 'Edit loan details' : 'Add loan details'" bgcolor="white" border="0"></header-block>
	<CustomerLoadWait :customer="customer && creditTypes && creditProviders"/>
	<div class="progress-container">
		<v-progress-linear :model-value="progressValue" rounded></v-progress-linear>
	</div>		
	<transition>
		<div v-if="customer && creditTypes && creditProviders && (creditAccount || !this.$route.params.id)">
			<div class="personalDetails">
				<InputSections ref="inputSections" :sections="sections" theme="light"/>
				<div v-if="validationText" class="well">
					<p>{{ validationText }}</p>
				</div>
				<div class="anchor-bottom submitControls">
					<v-row>
						<v-col cols="5" v-if="currentPageNumber > 0"><MGButton small="true" :outline="true" @click="_inputControl_GoBack()" text="Back" /></v-col>
						<v-col><MGButton :small="currentPageNumber > 0" :alt="true" text="Next" :arrow="true" @click="_inputControl_GoNext(addCredit, _preValidate)"/></v-col>
					</v-row>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Helpers from '../mixins/Helpers';
import Services from '../mixins/Services';
import AddCreditPageSections from './AddCreditPageSections';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services, Helpers, AddCreditPageSections],
	components: {HeaderBlock, InputSections, MGButton, CustomerLoadWait},
	data() {
		return {
			validationText: null,
			sectionNo: 0,
			currentPageNumber: 0
		}
	},
	computed: {
		progressValue() {
			var insuranceType = this._findFieldValue('Policy type');
			var totalPages = this.sectionsAvailableCount(insuranceType);
			if (totalPages === 0) return 0;
			return ((this.currentPageNumber + 1) / totalPages) * 100;
		},

	},
	methods: {
		onLoad: async function() {
			if (this.$route.params.id) {
				this._getCreditAccount(this.$route.params.id, (account) => {
					this.creditAccount = account;
					this.populateFields();
				});
			}
			this._getCustomer();
			this._getCreditProviders((res) => {
				res = res.filter(item => item.loans === true);
				var providerField = this._findField('Provider');
				providerField.items = res.map(x => {
					return x.name.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
			this._getCreditTypes((res) => {
				res = res.filter(item => item.name.toUpperCase() !== 'MORTGAGE');
				this.sections[0].fields[0].items = res.map(x => {
					return x.name.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
		},
		populateFields() {
			const fieldMappings = {
				'Type': this.creditAccount.creditType.name,
				'Provider': this.creditAccount.provider.name,
				'Account number': this.creditAccount.reference,
				'Balance': this.creditAccount.balance.amount,
				'Ownership': this.creditAccount.jointStatus === 2 ? 'Joint' : this.creditAccount.jointStatus === 1 ? 'Sole' : null,
				'Start date': this._dateToString(this.creditAccount.creditCreateDate),
				'Term (months)': this.creditAccount.termMonths,
			};

			this._loadFieldsFromObject(fieldMappings);
		},
		addCredit() {
			const creditType = {
				name: this._findFieldValue('Type') || null
			};
			const provider = {
				name: this._findFieldValue('Provider') || null,
			};
			const balance = {
				amount: this._findFieldValue('Balance') || null,
			}
			const balances = [balance];

			const creditAccount = ({
				creditType: creditType,
				reference: this._findFieldValue('Account number') || null,
				balances: balances,
				balance: balance,
				provider: provider,
				source: 1, // 1 = User
				termMonths: this._findFieldValue('Term (months)') || null,
				creditCreateDate:  this._stringToDate(this._findFieldValue('Start date')) || null,
				jointStatus: this._findFieldValue('Ownership') === 'Joint' ? 2 : this._findFieldValue('Ownership') === 'Sole' ? 1 : null,
			});

			this._removeNullProperties(creditAccount);

			if (this.$route.params.id) {
				creditAccount.id = this.$route.params.id;
			}

			const handleResponse = (res) => {
				if (res) {
					if (this.$route.params.id) {
						this._getCreditAccount(this.$route.params.id, /*onSuccess*/ null, /*refreshCache*/ true);
					}
					this._getCreditInfo(/*onSuccess*/ null, /*refreshCache*/ true);
					this.$router.push({ path: '/policysuccess', query: { type: 'account' } });
				}
			};

			if (!this.$route.params.id) {
				this._addCreditAccount(creditAccount, handleResponse);
			} else {
				this._updateCreditAccount(creditAccount, handleResponse);
			}
		},
		sectionsAvailableCount(insuranceType) {
			var sectionsNoRule = this.sections.filter(section => !section.rule);
			var sectionsMatchingRule = this.sections.filter(section => section.rule && section.rule.includes(insuranceType));
			return sectionsNoRule.length + sectionsMatchingRule.length;
		},
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>


:deep(.check .v-label) {
	color: white !important;
}

.well {
	text-align: center;
	background-color: #3328470D;
}

.progress-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

:deep(.v-progress-linear__background) {
  background-color: var(--border-color) !important;
}

</style>
