<template>
	<a :href="href != null ? href : $router.path">
		<div :class="'account-summary-button ' + (expiringText ? 'expiring' : '')">
			<v-row>
				<v-col class="title-col">
					<span class="nowrap bold">{{ title }}</span>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<img class="icon" :src="icon" />
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col>
					{{ provider }}
				</v-col>
			</v-row>
			<v-row class="mt-0" v-if="!expiringText">
				<v-col class="v-col-8">
					{{ expires }}
				</v-col>
				<v-col class="arrow-col">
					<img class="arrow" src="../../assets/arrow-right.png">
				</v-col>
			</v-row>
			<div v-if="expiringText" class="expiringText">{{expiringText}}</div>
		</div>
	</a>
</template>

<script>
export default {
	name: "InsuranceButton",
	props: ['title', 'icon', 'provider', 'expires', 'href', 'expiringText'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.account-summary-button {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
	box-shadow: 0px 4px 8px 0px rgba(51, 40, 71, 0.05);
}

.expiring {
	border: 1px dashed #E0C5C8;
}

.title-col {
	font-size: 13px;
	span {
		text-wrap: nowrap;
		overflow: ellipsis;
		display: block;
	}
}

.icon {
	width: 40px;
}

.arrow-col {
	text-align: right;
}

.arrow {
	width: 18px;
}

.expiringText {
	text-align: center;
	z-index: 999;
	padding-top: 6px;
	padding-bottom: 6px;
	margin-bottom: -18px;
	margin-top: 21px;
	border-top: 1px solid var(--border-color);
	margin-right: -18px;
	margin-left: -18px;
	background-color: #FEF9F4;
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	font-size: 10px;
	background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%);
}

</style>