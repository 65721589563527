<template>
	<HeaderBlock bgcolor="#00000000" border="0" />
	<CustomerLoadWait :customer="customer" />
	<transition>
		<div class="simplePage" v-if="customer">
			<h1>Set Pin</h1>
			<img class="heroImg" src="../assets/tip.png" />
			<p>{{ text }}</p>
			<v-otp-input class="pinField"
				v-model="pin"
				length="4"
				type="password"
				:label="xxxx"
				maxlength="4"
				variant="solo"
				:flat="true"
				single-line
				bg-color="#ffffffaa"
			></v-otp-input>
			<div v-if="validationText" class="well">
				<p>{{ validationText }}</p>
			</div>
			<div class="anchor-bottom">
				<MGButton text="Set PIN" :alt="true" @click="setPin" :arrow="true"/>
			</div>
		</div>
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, MGButton, HeaderBlock},
	data() {
		return {
			pin: null,
			pin2: null,
			validationText: null,
			text: "Please set a 4 digit PIN to secure your account:"
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
		},
		setPin() {
			this.validationText = null;
			if (!/^\d{4}$/.test(this.pin)) {
				this.validationText = 'PIN must be a 4 digit number';
				return;
			}
			if (this.pin2 != this.pin)
			{
				this.pin2 = this.pin;
				this.pin = null;
				this.text = "Please re-enter your PIN:"
				return;
			}
			this._signalV1('setPin', this.pin, this.$cookies.get('user'), this.$cookies.get('pass'), 'enablenotifications');
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.pinField {
	width: 70%;
	margin-right: auto;
	margin-left: auto;
}

/* .pinField :deep(input){
	text-align: center !important;
} */

</style>
