<template>
	<header-block title="" border="0" bgcolor="transparent"></header-block>
	
	<div class="simplePage">
		<div v-if="!useCode">
			<h1>Let's get started</h1>
			<p>Enter your work email address and we'll check that your employer's on board.</p>
			
			<v-text-field
				v-model="email"
				type="email"
				label="Enter work email address"
				variant="solo"
				:flat="true"
				clearable
				single-line
				bg-color="white"
			></v-text-field>
			
			<p class="small onlyTallScreen">We never share any of your personal information with your employer. 
				We never share any of your personal information with a third party, without your consent.</p>
			<MGButton :disabled="!email" @click="validateEmployer" text="Continue" :arrow="true"></MGButton>
			
			<div class="anchor-bottom withNoKeyboard">
				<p>or</p>
				<p>Redeem a one-time access code</p>
				<MGButton :small="true" text="Redeem code" @click="toggleUseCode()"></MGButton>
			</div>
		</div>
		<div v-else>
			<h1>Get started with Money Guided</h1>
			<p>Your unique code will be 12 digits and provided to you by your employer.</p>

			<v-text-field
				v-model="token"
				type="email"
				label="Unique code"
				variant="solo"
				:flat="true"
				clearable
				single-line
				bg-color="white"
			></v-text-field>

			<p>Need help redeeming your code? Email <a href="mailto:help@moneyguided.com">help@moneyguided.com</a></p>
			
			<div class="anchor-bottom">
				<MGButton :disabled="!token" @click="validateEmployer" text="Continue" :arrow="true"></MGButton>
			</div>

		</div>

		<DrawerPanel ref="emaildrawer" :closedHeight="0" bottom="0px" title="Sorry we didn't find you" openHeight="70%" backgroundColor="white" openBackgroundColor="white">
			
			<h2>We couldn't find your email address</h2>
			<p>Please double check we have the correct work email for you.</p>
			<p>You could also check your employer has an active account with Money Guided. </p>
		
		</DrawerPanel>	

		<DrawerPanel ref="tokendrawer" :closedHeight="0" bottom="0px" title="Sorry we didn't find you" openHeight="70%" backgroundColor="white" openBackgroundColor="white">
			
			<h2>We couldn't find your token</h2>
			<p>Please double check your have the correct token.</p>
			<p>You could also check your employer has an active account with Money Guided. </p>
		
		</DrawerPanel>	

	</div>

</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';
import DrawerPanel from './elements/DrawerPanel.vue';

export default {
	mixins: [Services],
	components: {MGButton, HeaderBlock, DrawerPanel},
	data() {
		return {
			email: null,
			token: null,
			invalid: false,
			useCode: false,
		}
	},
	
	methods: {
		onLoad: async function() {
			
		},
		toggleUseCode() {
			this.useCode = !this.useCode;
		},
		validateEmployer() {
			if (this.useCode && this.token) {
				this._verifyToken(this.token, (res) => {
					console.log(res);
					if (res) {
						this.$cookies.set('token', this.token);
						this.$cookies.set('employerId', res.id); // TODO: check if we need this
						this.$router.push({ path: '/eligibilityverified'});
					} else {
						console.log('Invalid');
						this.invalid = true;
						this.$refs.tokendrawer.openDrawer();
					}
				});
			} else {
				this._validateEmployer(this.email, (res) => {
					if (res.success) {
						this.$cookies.set('employerId', res.employer.id);
						this.$router.push({ path: '/verifyemail/' + res.employer.name + '/' + res.emailVerificationID });
					} else {
						console.log('Invalid');
						this.invalid = true;
						this.$refs.emaildrawer.openDrawer();
					}
				});
			}
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>



</style>
