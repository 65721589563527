<template>
  <div ref="body" id="body" :style="'background-color: ' + (backgroundColor + ';' + ($route.meta.backgroundImage ? 'background: url(' + $route.meta.backgroundImage + ')' : ''))">
    <div id="app" :style="'min-height: ' + minHeight + '; padding-bottom:' + padding">
        <router-view :key="$route.query"></router-view>
        <MenuBar v-if="$route.meta.bottomMenu" />
    </div>
  </div>
</template>

<script>
import MenuBar from './components/elements/MenuBar.vue';
export default {
  name: 'App',
  components: {
    MenuBar
  },
  data() {
		return {
			defaultBackgroundColor: "#FBF7F4"
		}
	},
  computed: {
		backgroundColor() {
      if (this.$route.meta.backgroundColor){
        return this.$route.meta.backgroundColor;
      }
      if (this.$route.query.theme)
      {
        return this.$route.query.theme === 'dark' ? '#332847' : 'white';
      }
      return this.defaultBackgroundColor;
		},
    minHeight(){
      //var ret = 'calc(100vh - ' + (this.$route.meta.bottomMenu ? 60 : -4) + 'px)';
      //console.log(this.$route.meta.bottomMenu, 'bottom menu');
      var ret = 'calc(100vh - ' + (this.$route.meta.bottomMenu ? 60 : 0) + 'px)';
      //console.log(ret, 'Min Height');
      return ret;
    },
    padding(){
      //var ret = 'calc(100vh - ' + (this.$route.meta.bottomMenu ? 60 : -4) + 'px)';
      //console.log(this.$route.meta.bottomMenu, 'bottom menu');
      var ret = (this.$route.meta.bottomMenu ? 80 : 0) + 'px';
      //console.log(ret, 'Padding');
      return ret;
    }
	},
}
</script>

<style>

:root {
  --background-color: white;
  --text-color: #281C3B;
  --light-text-color: white;
  --border-color: gainsboro;
  --background-color-alt: #FBF7F4;
  --background-color-alt2: #332847;
  --grey-text: #8b8b8b;
}

@font-face {
  font-family: 'Gotham';
  src: url('assets/Gotham-Book.ttf');
  font-display: block;
}

@font-face {
  font-family: 'Gotham-Heavy';
  src: url('assets/Gotham-Medium.ttf');
  font-display: block;
}

* { font-family: 'Gotham'; }

/* Actual HTML body element outside of app control */
body{
  margin:0px;
  background-color:var(--alt-color);
  color: var(--text-color);
  font-size: 12px;
  min-height: calc(100vh);
  font-family: gotham-heavy;
  user-select: none;
}

/* 'Our' HTML body element inside app control */
#body {
  background-color: var(--alt-color);
  padding-left: 30px;
  padding-right: 30px;
  margin: 0px;
  padding-top: 10px;
  margin-bottom: 78px;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

html {
  height: 100vh;
}

#app
{
  background-color:transparent;
  min-height: calc(100vh - 60px);
}

h1 {
	font-size: 32px;
  margin-bottom: 8px;
  font-family: gotham;
}

.bold {
  font-family: gotham-heavy;
}

strong {
  font-family: gotham-heavy;
}

h2 {
  font-size: 16px;
  margin-bottom: 8px;
  font-family: gotham;
}

p {
  margin-bottom: 9px;
  font-family: gotham;
  f--ont-weight: lighter;
  font-size: 15px;
}

.middle-page {
  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)
}

.middle-page-topthird {
  position: absolute; top: 30%; left: 50%; transform: translate(-50%, -50%)
}

.v-label {
  font-size: small !important;
  color: var(--text-color) !important;
  opacity: 100 !important;
  font-size: 12px !important;
}

.pageHeaderIcon {
  width: 40px;
  margin-bottom: 20px;
  /* margin-right:auto;
  margin-left:auto;
  display: block; */
}

.pageHeaderIconBigger {
  width: 80px;
  margin-bottom: 20px;
  /* margin-right:auto;
  margin-left:auto;
  display: block; */
}

.mg-button {
    display: block !important;
    border-radius: 30px !important;
    height: 50px !important;
    background-color: var(--text-color) !important;
    color: var(--light-text-color) !important;
    text-decoration: none !important;
    text-transform: capitalize !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-top: 10px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 80% !important;
    margin-top: 30px !important;
    font-family: gotham-heavy;
}

.mg-button.outline{
	background-color: white !important;
	border: 1px solid var(--text-color) !important;
	color: var(--text-color) !important;
}

.mg-select {
    display: block !important;;
    border-radius: 30px !important;
    height: 50px !important;
    background-color: var(--text-color) !important;
    color: var(--light-text-color) !important;
    text-decoration: none !important;
    text-transform: capitalize !important;
    padding-right: 10px !important;
    padding-left: 10px !important;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 80% !important;
    margin-top: 30px !important;
    font-family: gotham-heavy;
    .v-field--variant-filled .v-field__overlay {
      margin-top:-10px;
      background-color: transparent !important;
    }
    .v-field__input {
      padding-top: 10px !important;
    }
    .v-field__append-inner {
      margin-top: -6px;
    }

}

.centered {
  text-align: center;
}

.left {
  text-align: left;
}

.right {
  text-align: right;
}

.blur {
	opacity: 0.7;
	filter: blur(1.2px);
}

a {
  text-decoration: none;
  color: unset;
}

.topTabButton
{
  border: 1px solid var(--border-color) !important;
  border-radius: 4px !important;
  font-size: 11px !important;
  padding: 10px !important;
  box-shadow: none !important;
  m--argin:0px !important;
  background-color: transparent !important;
  color: var(--text-color) !important;
  width: 100% !important;
}

.active
{
  background-color: var(--text-color) !important;
  color: var(--light-text-color) !important;
}

.v-navigation-drawer__scrim {
  background-color: var(--background-color) !important;
  opacity: 0 !important;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.4s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}

.right {
  text-align: right;
}

.allowYScrollContainer
{
	overflow: scroll;
	scrollbar-width: none;
}

.allowYScrollContent {
	display: inline-block;
	white-space: nowrap;
}

.nowrap {
  overflow: hidden;
	text-wrap: nowrap;
	max-width: 100%;
	display: block;
	text-overflow: ellipsis
}

.no-top-padding {
  padding-top: 0px !important;
}

.tp-7 {
  padding-top: 7px !important;
}

.tp-4 {
  padding-top: 4px !important;
}

.tp-2 {
  padding-top: 2px !important;
}

.v-skeleton-loader {
  background-color: transparent !important;
}

.v-slider-thumb__surface {
		background-color: #332847 !important;
		border: 3px solid white !important;
}

.v-slider-track__background {
  background-color: #FFFFFF66 !important;
}

.v-slider-track__fill {
  background-color: white !important;
}

.v-progress-linear {
  background: #FFFFFF33 !important;
  w--idth: 80%;
}

.v-progress-linear__determinate {
  background: white;
}

.anchor-bottom {
	position: fixed;
	bottom: 60px;
	width: calc(100vw - 60px);
}

@media(max-height: 670px) {
  .anchor-bottom {
    margin-top: 10px;
    position: unset;
    padding-bottom: 30px;
  }
}

.small {
  font-size: 10px;
}

.simplePage {
	text-align: center;
  padding-bottom: 30px;
	h1 {
    margin-top: 45px;
		margin-bottom: 20px;
		font-size: 32px;
	}
	p {
		margin-bottom: 20px;
	}
  .smallHeroImg {
    width: 120px;
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 100px;
    color: var(--text-color)
  }
  .heroImg {
    width: 160px;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .largeHeroImg {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 30px;
  }
	.v-field__overlay {
		border: 1px solid black !important;
	}
}

@media(max-height: 760px) {
  .simplePage .heroImg {
    width:120px !important;
    margin-top: 20px !important;
  }
  .simplePage h1 {
    margin-top: 10px !important;
  }
}

.well {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 25px;
  background-color: #f5f3f5;
  border-radius: 24px;
  h1{
    font-size: 16px;
  }
  h2{
    font-size: 14px;
  }
}

.hubItemWrapper {
	margin-top: 12px; 
	margin-bottom: 12px;
	margin-left: 0px; 
  padding-right: 10px;
  padding-left: 0px;
	width: calc((100vw - 60px) / 2);
	float: left !important;
}

.hubItemWrapper:nth-child(even) {
  padding-right: 0px;
  padding-left: 10px;
	float:right !important;
}

.shadowed {
	box-shadow: 0px 4px 8px 0px rgba(51, 40, 71, 0.05);
}

.floatRight {
  float: right;
}

.floatLeft {
  float: left;
}

.verticalCenter {
  /* display: flex;
  align-items: center; */
  margin-top: auto;
  margin-bottom: auto;
}

@media(max-height: 760px) {
  .onlyTallScreen {
    display: none;
  }
}

@media(max-height: 600px) {
  .withNoKeyboard {
    display: none;
  }
}

</style>
