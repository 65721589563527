<template>
	<header-block v-if="customer" title="Recurring Payments" bgcolor="white"></header-block>
	<CustomerLoadWait :customer="recurringPayments" />
	<transition>
		<div v-if="recurringPayments" class="recurringPayments">
			<div class="totals">
				Monthly:
				{{ formatter.format(Math.abs(Math.round(recurringPayments.total))) }}
				
			</div>
			<div>
				<v-row class="row" v-for="p in recurringPayments.payments" :key="p.id" @click="showPayments(p.merchantName)">
					<v-col cols="2" class="iconCol">
						<img class="icon" :style="'background-color: #' + (p.bankAccount.bank.hexColour2 ? p.bankAccount.bank.hexColour2.substring(2) : '332847')" :src="p.bankAccount.bank.logoPath ? require('@/assets/logos/' + p.bankAccount.bank.logoPath): p.bankAccount.bank.logoUri" />
					</v-col>
					<v-col>
						
						<h2>{{ p.merchant ? p.merchant.name : p.merchantName }}</h2>
						<p>Last amount: {{ formatter.format(Math.abs(p.lastAmount)) }}</p>
						<div class="meta">
							<span>Last paid: {{ this._dateToString(p.lastPaid) }}</span>
							<v-chip size="small" color="red" prepend-icon="mdi-arrow-up" v-if="p.increasing">increasing</v-chip>
							<v-chip size="small" color="green" variant="outlined" prepend-icon="mdi-check" v-if="!p.changing">stable</v-chip>
							<v-chip size="small" color="green" prepend-icon="mdi-arrow-down" v-if="p.changing && !p.increasing">decreasing</v-chip>
						</div>
						
					</v-col>
				</v-row>
			</div>
			<div class="ad">
				<h1>Stay on top of your recurring payments</h1>
				<p>Staying on top of your monthly recurring payments is crucial for maintaining financial health and avoiding unnecessary problems. 
					Regularly reviewing your recurring payments helps you identify subscriptions you no longer use.</p>
			</div>

			<DrawerPanel ref="drawer" bottom="0px" closedHeight="0px" title="Recent Transactions" backgroundColor="white" openBackgroundColor="white">
				<TransactionsList ref="transactions" v-if="bankInfo" search="true" :transactions="bankInfo.recentTransactions" :formatter="formatter" :today="today" :yesterday="yesterday" />
			</DrawerPanel>

		</div>
	</transition>
	
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import Helpers from '../mixins/Helpers';
import DrawerPanel from './elements/DrawerPanel.vue';
import TransactionsList from './elements/TransactionsList.vue';

export default {
	mixins: [Services, Helpers],
	components: {CustomerLoadWait, HeaderBlock, DrawerPanel, TransactionsList},
	data() {
		return {
			recurringPayments: null
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer(() => {
				this._getBankingInfo(() => {
					this._getRecurringPayments((res) => {
						res.payments = res.payments.sort((a, b) => {
							return (b.increasing ? 1 : 0) - (a.increasing ? 1 : 0);
						});
						res.payments.forEach(rp => {
							rp.bankAccount = this.getBankAccount(rp.bankAccountId);
						});
						this.recurringPayments = res;
						var origCache = this.useCache;
						this.useCache = false;
						this._getBankingInfo(() => {
							this.useCache = origCache;
						}, true, 93);
					});
				});
				
			});
		},
		showPayments: function(merchantName) {
			this.$refs.transactions.searchTransactions(merchantName);
			this.$refs.drawer.openDrawer();
		},
		getBankAccount: function(id) {
			return this.bankInfo.accounts.find(ba => ba.id === id);
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.recurringPayments {
	margin-left: -30px;
	margin-right: -30px;
	span {
		f--loat: right;
	}
	h2 {
		font-size: 16px;
		font-weight: normal;
	}
	.meta{
		f--ont-weight: bold;
		.v-chip {
			float:right
		}
	}
	.row{
		padding-left: 30px;
		padding-right: 30px;
		border-bottom: 1px solid var(--border-color);
	}

	.row:last-child {
		border-bottom: none;
	}
	
	.iconCol {
		padding-right: 30px;
	}
	
	.icon {
		width: 40px;
		border-radius: 50%;
		//background-color: var(--text-color)
	}
	.ad{
		margin-top: 20px;
		background-color: var(--text-color);
		color: white;
		padding: 25px;
		h1{
			font-size: 20px;
		}
	}
	.totals {
		padding-right: 30px;
		text-align: right;
		padding-bottom: 10px;
		b--order-bottom: 1px solid var(--border-color);
		font-weight: bold;
	}
}


.middleAlign {
	margin-top:auto;
	margin-bottom:auto;
}

</style>
