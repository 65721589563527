import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import { createRouter, createWebHashHistory } from 'vue-router';
import axios from 'axios';

import App from './App.vue'
import Notifications from './components/NotificationsPage.vue'
import WhatsAppCoaching from './components/WhatsAppCoachingPage.vue'
import MoneyHubLink from './components/MoneyHubLinkPage.vue'
import MoneyHubCallback from './components/MoneyHubCallbackPage.vue'
import YouPage from './components/YouPage.vue'
import EnableWhatsApp from './components/EnableWhatsApp.vue';
import AiopPage from './components/AiopPage.vue';
import SpendingAnalysisPage from './components/SpendingAnalysisPage.vue';
import TestPage from './components/TestPage.vue';
import AccountsPage from './components/AccountsPage.vue';
import AccountPage from './components/AccountPage.vue';
import TransactionPage from './components/TransactionPage.vue';
import TransactionBulkPage from './components/TransactionBulkPage.vue';
import HomePage from './components/HomePage.vue';
import ActivityPage from './components/ActivityPage.vue';
import GuidesPage from './components/GuidesPage.vue';
import GuidePage from './components/GuidePage.vue';
import GuideSectionPage from './components/GuideSectionPage.vue';
import GuideTaskPage from './components/GuideTaskPage.vue';
import ArticlePage from './components/ArticlePage.vue';
import VouchersShopPage from './components/VouchersShopPage.vue';
import VoucherShopPage from './components/VoucherShopPage.vue';
import VouchersSearchPage from './components/VouchersSearchPage.vue';
import PaymentCompletePage from './components/PaymentCompletePage.vue';
import LoginPage from './components/LoginPage.vue';
import VouchersPage from './components/VouchersPage.vue';
import VoucherPage from './components/VoucherPage.vue';
import QuestionnairePage from './components/QuestionnairePage.vue';
import MortgagePage from './components/MortgagePage.vue';
import CreditPage from './components/CreditPage.vue';
import InvestmentPage from './components/InvestmentPage.vue';
import CompletedMovesPage from './components/CompletedMovesPage.vue'
import MovesPage from './components/MovesPage.vue';
import MovePage from './components/MovePage.vue';
import WelcomePage from './components/WelcomePage.vue';
import FinancialFitnessLandingPage from './components/FinancialFitnessLandingPage.vue';
import FinancialFitnessResultsPage from './components/FinancialFitnessResultsPage.vue';
import FinancialFitnessHistoryPage from './components/FinancialFitnessHistoryPage.vue';
import PersonalisationLandingPage from './components/PersonalisationLandingPage.vue';
import PersonalisationFinishPage from './components/PersonalisationFinishPage.vue';
import FinancesFinishPage from './components/FinancesFinishPage.vue';
import GetStartedPage from './components/GetStartedPage.vue';
import AwaitEmailVerificationPage from './components/AwaitEmailVerificationPage.vue';
import EligibilityVerifiedPage from './components/EligibilityVerifiedPage.vue';
import PolicySuccessPage from './components/PolicySuccessPage.vue';
import BulkTaggingCompletePage from './components/BulkTaggingCompletePage.vue';
import PersonalDetailsPage from './components/PersonalDetailsPage.vue';
import CreateAccountPage from './components/CreateAccountPage.vue';
import SetPinPage from './components/SetPinPage.vue';
import AddOfflineBankAccountPage from './components/AddOfflineBankAccountPage.vue';
import AddInsurancePage from './components/AddInsurancePage.vue';
import AddInvestmentPage from './components/AddInvestmentPage.vue';
import AddPensionPage from './components/AddPensionPage.vue';
import PensionPage from './components/PensionPage.vue';
import AddCreditPage from './components/AddCreditPage.vue';
import AddMortgagePage from './components/AddMortgagePage.vue';
import PolicyPage from './components/PolicyPage.vue';
import EnableNotificationsPage from './components/EnableNotificationsPage.vue';
import BenefitsPage from './components/BenefitsPage.vue';
import MarketplacePage from './components/MarketplacePage.vue';
import ProductPage from './components/ProductPage.vue';
import RecurringPaymentsPage from './components/RecurringPaymentsPage.vue';
import LinkOpenBankingAccountPage from './components/LinkOpenBankingAccountPage.vue';
import SetupSpendingAnalysisPage from './components/SetupSpendingAnalysisPage.vue';
import SpendingPlanPage from './components/SpendingPlanPage.vue';
import GoalsPage from './components/GoalsPage.vue';
import GoalPage from './components/GoalPage.vue';
import RelinkAccountsPage from './components/RelinkAccountsPage.vue';
import CalculatorsPage from './components/CalculatorsPage.vue';

// Vuetify
import 'vuetify/styles'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
	components,
	locale: {
		locale: 'en',
	},
	directives,
	icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
})

var stripeKey = "pk_live_51QG05vC4AKqm93HpI6MDqVHKiZjxjlgqsIDqooQ6rIUhLHDkpsFAjq9MIOUypzVy1sXwfc854ukr7B6UFfqPHLXv00ZWFRrUo4";
var api = "https://api.moneyguided.com";
var configUri = "dev";

const router = createRouter({
	history: createWebHashHistory(),
	scrollBehavior() {
		return { top: 0 };
	},
	routes: [
		{
			path: '/', component: HomePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'home',
				backgroundImage: require('@/assets/bg/bg.png'),
				aiChat: "I'd like to talk about my personal finances, spending analysis and plans on the 'Home' screen in the money guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: false,
				bounce: true
			}
		},
		{
			path: '/welcome', component: WelcomePage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				authenticated: false,
				allowBack: false,
				bounce:true
			}
		},
		{
			path: '/activity', component: ActivityPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'activity',
				backgroundImage: require('@/assets/bg/bg.png'),
				aiChat: "I'd like to talk about my spending analysis, savings goals and spending plans (which are budgets) in the 'Activity' screen in the money guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: false,
				bounce: true
			}
		},
		{
			path: '/getstarted', component: GetStartedPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				backgroundImage: require('@/assets/bg/hero-blurred.png'),
				authenticated: false,
				allowBack: false
			}
		},
		{
			path: '/verifyemail/:employer/:id', component: AwaitEmailVerificationPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				backgroundImage: require('@/assets/bg/hero-blurred.png'),
				authenticated: false
			}
		},
		{
			path: '/eligibilityverified', component: EligibilityVerifiedPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				authenticated: false,
				allowBack: false
			}
		},
		{
			path: '/policysuccess', component: PolicySuccessPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
			}
		},
		{
			path: '/bulktaggingcomplete', component: BulkTaggingCompletePage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				allowBack: false
			}
		},
		{
			path: '/setupspendinganalysis', component: SetupSpendingAnalysisPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				bottomMenu: false,
				allowBack: false,
				bgColor: 'white'
			}
		},
		{
			path: '/personaldetails', component: PersonalDetailsPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				backgroundColor: '#332847',
				authenticated: false,
				allowBack: false
			}
		},
		{
			path: '/linkopenbankingaccount', component: LinkOpenBankingAccountPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				backgroundColor: '#332847',
				allowBack: false
			}
		},
		{
			path: '/createaccount', component: CreateAccountPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
				backgroundColor: '#332847',
				authenticated: false
			}
		},
		{
			path: '/setpin', component: SetPinPage,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri,
			}
		},
		{
			path: '/notifications', component: Notifications,
			meta: {
				whatsapp: false,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/whatsappcoaching', component: WhatsAppCoaching,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/FinancialFitness', component: FinancialFitnessLandingPage,
			meta: {
				api: api,
				configUri: configUri,
				backgroundColor: 'white',
				escapeRoute: "You"
			}
		},
		{
			path: '/FinancialFitnessResults', component: FinancialFitnessResultsPage,
			meta: {
				api: api,
				configUri: configUri,
				backgroundColor: 'white',
				escapeRoute: "You"
			}
		},
		{
			path: '/FinancialFitnessHistory', component: FinancialFitnessHistoryPage,
			meta: {
				api: api,
				configUri: configUri,
				backgroundColor: 'white',
				escapeRoute: "You"
			}
		},
		{
			path: '/FinancialFitnessHistory/:id', component: FinancialFitnessHistoryPage,
			meta: {
				api: api,
				configUri: configUri,
				backgroundColor: 'white',
				escapeRoute: "You"
			}
		},
		{
			path: '/Personalisation', component: PersonalisationLandingPage,
			meta: {
				api: api,
				configUri: configUri,
				backgroundColor: 'white',
				allowBack: false
			}
		},
		{
			path: '/PersonalisationFinish', component: PersonalisationFinishPage,
			meta: {
				api: api,
				configUri: configUri,
				backgroundColor: '#332847',
				allowBack: false
			}
		},
		{
			path: '/FinancesFinish', component: FinancesFinishPage,
			meta: {
				api: api,
				configUri: configUri,
				backgroundColor: 'white',
				allowBack: false
			}
		},
		{
			path: '/moneyhublink', component: MoneyHubLink,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/moneyhubcb/:code', component: MoneyHubCallback,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/you', component: YouPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'you',
				aiChat: "I'd like to talk about the money guided app in the 'you' screen in the money guided app. The screen shows items that I should draw my attention to. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: false,
				bounce:true,
				backgroundImage: require('@/assets/bg/bg.png'),
			}
		},
		{
			path: '/Benefits', component: BenefitsPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'you',
				aiChat: "I'd like to talk about my employer benefits in the 'benefits' screen in the money guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				escapeRoute: "<"
			}
		},
		{
			path: '/Products/:id', component: ProductPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				backgroundColor: 'white',
				menuItem: 'you',
				escapeRoute: "<",
				allowBack: true
			}
		},
		{
			path: '/Marketplace', component: MarketplacePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'you',
				aiChat: "I'd like to talk the money guided marketplace in the 'marketplace' screen in the money guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				escapeRoute: "<"
			}
		},
		{
			path: '/Calculators', component: CalculatorsPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'you',
				aiChat: "I'd like to talk the money guided calculators in the 'Calculators' screen in the money guided app where you can find useful financial calculators. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				escapeRoute: "<"
			}
		},
		{
			path: '/guides', component: GuidesPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'guide',
				aiChat: "I'd like to talk about the financial guides in the 'guides' screen in the money guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: false,
				backgroundImage: require('@/assets/bg/bg.png'),
			}
		},
		{
			path: '/guides/:id', component: GuidePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white',
				escapeRoute: "<"
			}
		},
		{
			path: '/guides/sections/:id', component: GuideSectionPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white',
				escapeRoute: "<"
			}
		},
		{
			path: '/guides/sections/tasks/:id', component: GuideTaskPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white',
				escapeRoute: "<"
			}
		},
		{
			path: '/articles/:id', component: ArticlePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Goals', component: GoalsPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'activity',
				aiChat: "I'd like to talk about my savings goals on the 'goals' screen in the money guided app. Savings goals allow me to save for the important things in life. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: true,
				backgroundImage: require('@/assets/bg/bg.png'),
			}
		},
		{
			path: '/Goals/:id', component: GoalPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				escapeRoute: "<"
			}
		},
		{
			path: '/moves', component: MovesPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				aiChat: "I'd like to talk about my moves on the moves screen in the money guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				escapeRoute: "/"
			}
		},
		{
			path: '/moves/:id', component: MovePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'guide',
				backgroundColor: 'white',
				escapeRoute: "<"
			}
		},
		{
			path: '/completedmoves', component: CompletedMovesPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				backgroundColor: 'white',
				menuItem: 'activity',
				escapeRoute: "<"
			}
		},
		{
			path: '/enablewhatsapp', component: EnableWhatsApp,
			meta: {
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/enablenotifications', component: EnableNotificationsPage,
			meta: {
				api: api,
				configUri: configUri,
				allowBack: false
			}
		},
		{
			path: '/Aiop', component: AiopPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				aiChat: "I'd like to talk about my personal finances in the 'all in one place' screen in the money guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: false,
				backgroundImage: require('@/assets/bg/bg.png'),
			}
		},
		{
			path: '/Accounts/All/Relink', component: RelinkAccountsPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				allowBack: false,
				escapeRoute: "<",
				menuItem: 'money',
			}
		},
		{
			path: '/Accounts/Current/Add', component: AddOfflineBankAccountPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Accounts/Current/Edit/:id', component: AddOfflineBankAccountPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},		
		{
			path: '/SpendingAnalysis', component: SpendingAnalysisPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'activity',
				aiChat: "I'd like to talk about spending analysis in the 'Spending Analysis' section of the Money Guided app.  The screen shows how I have spent my money within my last pay period (or a period I choose). I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: true,
				backgroundImage: require('@/assets/bg/bg.png'),
			}
		},
		{
			path: '/SpendingPlan', component: SpendingPlanPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'activity',
				aiChat: "I'd like to talk about spending plans, which are budgets in the 'Spending Plans' section of the Money Guided app. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				allowBack: true,
				backgroundImage: require('@/assets/bg/bg.png'),
			}
		},
		{
			path: '/Login', component: LoginPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundImage: require('@/assets/bg/bg.png'),
				allowBack: false
			}
		},
		{
			path: '/Accounts/:accountType', component: AccountsPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				bounce: true
			}
		},
		{
			path: '/Account/:id', component: AccountPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Transaction/:id', component: TransactionPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				escapeRoute: "<"
			}
		},
		{
			path: '/Transaction/Bulk', component: TransactionBulkPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				escapeRoute: "<"
			}
		},
		{
			path: '/Transaction', component: TransactionPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				escapeRoute: "<"
			}
		},
		{
			path: '/Questionnaire/:id', component: QuestionnairePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				allowBack: false,
				menuItem: 'money',
			}
		},
		{
			path: '/Policies/Insurance/Add', component: AddInsurancePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Policies/Insurance/Edit/:id', component: AddInsurancePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Policies/:id', component: PolicyPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},			
		{
			path: '/Investments/Add', component: AddInvestmentPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Investments/Edit/:id', component: AddInvestmentPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Pensions/Add', component: AddPensionPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Pensions/Edit/:id', component: AddPensionPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Pensions/:id', component: PensionPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},				
		{
			path: '/Credit/Add', component: AddCreditPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Credit/Edit/:id', component: AddCreditPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},		{
			path: '/Mortgages/Add', component: AddMortgagePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Mortgages/Edit/:id', component: AddMortgagePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white'
			}
		},
		{
			path: '/Mortgages/:id', component: MortgagePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
			}
		},
		{
			path: '/Credit/:id', component: CreditPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
			}
		},
		{
			path: '/Investments/:id', component: InvestmentPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
			}
		},
		{
			path: '/Vouchers', component: VouchersShopPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				backgroundColor: 'white',
				aiChat: "I'd like to talk about retail cards in the 'retail cards' screen in the money guided app. Cards are purchased in the 'Show all Cards' section for their face value and cashback is applied to the 'Saved to spend' pot. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
				escapeRoute: "MyVouchers"
			}
		},
		{
			path: '/Vouchers/Search', component: VouchersSearchPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white',
				escapeRoute: "Vouchers"
			}
		},
		{
			path: '/MyVouchers', component: VouchersPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				menuItem: 'money',
				backgroundColor: 'white',
				aiChat: "I'd like to talk about retail cards in the 'retail cards' screen in the money guided app. Cards are purchased in the 'Show all Cards' section for their face value and cashback is applied to the 'Saved to spend' pot. I have just clicked on the help button which has shown this chat window. You are the chat agent for this screen. Do not acknowledge my message, only respond as a guide for the screen. Be succinct.",
			}
		},
		{
			path: '/MyVouchers/:id', component: VoucherPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white',
				escapeRoute: "<"
			}
		},
		{
			path: '/Vouchers/:id', component: VoucherShopPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white',
				stripeKey: stripeKey,
				escapeRoute: "<"
			}
		},
		{
			path: '/PaymentComplete', component: PaymentCompletePage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white',
				allowBack: false
			}
		},
		{
			path: '/RecurringPayments', component: RecurringPaymentsPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: false,
				menuItem: 'money',
				backgroundColor: 'white',
				escapeRoute: "<"
			}
		},
		{
			path: '/Test', component: TestPage,
			meta: {
				api: api,
				configUri: configUri,
				bottomMenu: true,
				backgroundColor: 'white'
			}
		}
	]
});

const app = createApp(App);
app.config.globalProperties.$http = axios;
app.use(VueCookies);
app.use(router);
app.use(vuetify);
app.mount("#app");
