import Helpers from '../mixins/Helpers';

export const SPENDING_ANALYSIS_CATEGORIES = {
    EVERYDAY_SPEND: 'Everyday spend',
    FIXED_COSTS: 'Fixed costs',
    INCOME: 'Income',
    FUTURE_PLANNING: 'Future planning'
};

export const SPENDING_ANALYSIS_KEYS = {
    MONTHLY: 'cycledAnalysis',
    ANNUAL: 'annualCycledAnalysis'
};

export const GRANDPARENT_CLASSIFICATIONS = {
	INCOME: 0,
	FUTURE_PLANNING: 1,
	EVERYDAY_SPEND: 2,
	FIXED_COSTS: 3
};

export const CLASSIFICATION_COLOURS = {
	INCOME: '#B4D8D7',
	FUTURE_PLANNING: '#D5C9C8',
	EVERYDAY_SPEND: '#9EA2D0',
	FIXED_COSTS: '#413655'
};

export default {
	mixins: [Helpers],
	data() {
		return {
			breadCrumb: [],

			showMerchantTransactions: false,
			showPlanManagement: false,
			selectedClassificationSpend: 0,
			selectedClassificationIncome: 0,
			categoryWellData: [],

		}
	},

	created: function() {
		this.bounce();
	},

	computed: {
		_drawerTitle() {
			if (this.showMerchantTransactions) {
				return this.merchantTransactions.length > 0 ? this.merchantTransactions[0][0].merchantName : '';
			}
			return this.selectedClassification ? this.selectedClassification.fullName : '';
		}
	},

	methods: {

		_getMockSpendingTotals() {
			var totals = [];
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.INCOME, 
					"Amount": Math.round(2000)
				});
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING,
					"Amount": Math.round(100)
				});
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND,
					"Amount": Math.round(400)
				});
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS,
					"Amount": Math.round(900)
				});
			return totals;
		},

		_getLatestMonthsSpendingTotals() {
			const fixedCosts = this.spendingAnalysis.cycledAnalysis[0].grandparentGroupedAnalysis.filter(item => item.label === SPENDING_ANALYSIS_CATEGORIES.FIXED_COSTS);
			const everyDaySpend = this.spendingAnalysis.cycledAnalysis[0].grandparentGroupedAnalysis.filter(item => item.label === SPENDING_ANALYSIS_CATEGORIES.EVERYDAY_SPEND);
			const futurePlanning = this.spendingAnalysis.cycledAnalysis[0].grandparentGroupedAnalysis.filter(item => item.label === SPENDING_ANALYSIS_CATEGORIES.FUTURE_PLANNING);
			const income = this.spendingAnalysis.cycledAnalysis[0].grandparentGroupedAnalysis.filter(item => item.label === SPENDING_ANALYSIS_CATEGORIES.INCOME);
			const fixedCostsTotalOut = fixedCosts.reduce((acc, item) => acc + Math.abs(item.totalNet), 0);
			const everyDaySpendTotalOut = everyDaySpend.reduce((acc, item) => acc + Math.abs(item.totalNet), 0);
			const futurePlanningTotalOut = futurePlanning.reduce((acc, item) => acc + Math.abs(item.totalNet), 0);
			const incomeTotalIn = income.reduce((acc, item) => acc + Math.abs(item.totalNet), 0);
			var totals = [];
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.INCOME, 
					"Amount": Math.round(Math.abs(incomeTotalIn))
				});
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING,
					"Amount": Math.round(Math.abs(futurePlanningTotalOut))
				});
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND,
					"Amount": Math.round(Math.abs(everyDaySpendTotalOut))
				});
			totals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS,
					"Amount": Math.round(Math.abs(fixedCostsTotalOut))
				});
			return totals;
		},

		_findClassificationById: function(id, classifications, returnParent = false) {
			for (const classification of classifications) {
				if (classification.mgClassification.id === id) {
					return classification;
				}
				if (classification.children && classification.children.length > 0) {
					const foundClassification = this._findClassificationById(id, classification.children);
					if (foundClassification) {
						if (returnParent) {
							return classification;
						}
						return foundClassification;
					}
				}
			}
			return null;
		},

		_findClassificationByName: function(name, classifications, returnParent = false) {
			for (const classification of classifications) {
				if (classification.mgClassification.mgClassification == name) {
					return classification;
				}
				if (classification.children && classification.children.length > 0) {
					const foundClassification = this._findClassificationByName(name, classification.children);
					if (foundClassification) {
						if (returnParent) {
							return classification;
						}
						return foundClassification;
					}
				}
			}
			return null;
		},

		_extractCycledAnalysisDateSlots(spendingAnalysis, analysisKey) {
			if (!spendingAnalysis[analysisKey] || !Array.isArray(spendingAnalysis[analysisKey])) {
				return [];
			}

			return spendingAnalysis[analysisKey]
				.map(entry => {
					var labelString = '';
					var keyString = '';
					if (analysisKey === SPENDING_ANALYSIS_KEYS.MONTHLY) {
						labelString = this._getMonthShortName(entry.to);
						keyString = this._getMonthName(entry.to) + ' ' + this._getYear(entry.to).toString();
					}
					else {
						labelString = this._getYear(entry.to).toString();
						keyString = this._getYear(entry.to).toString();
					}
					return {
						label: labelString,
						key: keyString,
						from: entry.from,
						to: entry.to
					};
				})
				.filter(entry => entry !== null);
		},

		_extractCycledAnalysisCategory(spendingAnalysis, categoryName, analysisKey) {
			const result = [];
			const traverse = (obj) => {
				if (obj.grandparentGroupedAnalysis) {
					obj.grandparentGroupedAnalysis.forEach(group => {
						if (group.label === categoryName) {
							if (analysisKey === SPENDING_ANALYSIS_KEYS.MONTHLY) {
								group.slotName = this._getMonthName(group.groupedAnalysis[0].to) + ' ' + this._getYear(group.groupedAnalysis[0].to).toString();
							} else {
								group.slotName = this._getYear(group.groupedAnalysis[0].to).toString();
							}
							result.push(group);
						}
					});
				}
				if (obj[analysisKey]) {
					obj[analysisKey].forEach(cycle => traverse(cycle));
				}
			};
			traverse(spendingAnalysis);
			return result;
		},

		_updateSelectedTotals() {
			this.selectedCategoryCache = [];
			this.selectedTotals = [];
			this.selectedTotals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.INCOME, 
					"Amount": Math.round(Math.abs(this.selectedIncome.totalNet))
				});
			this.selectedTotals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING,
					"Amount": Math.round(Math.abs(this.selectedFuturePlans.totalNet))
				});
			this.selectedTotals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND,
					"Amount": Math.round(Math.abs(this.selectedEverydaySpend.totalNet))
				});
			this.selectedTotals.push(
				{
					"id": GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS,
					"Amount": Math.round(Math.abs(this.selectedCommittedCosts.totalNet))
				});
		},

		_setupCategories() {
			this._getMonthlyAnalyis();
			this._getAnnualAnalysis();
			this._mergeMonthlyAnnualAnalysis();
			this._getSlots();
			this.selectedSlotKey = this.slots ? this.slots[this.slots.length - 2].key : '';
		},

		_getMonthlyAnalyis() {
			this.monthlyEverydaySpend = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.EVERYDAY_SPEND, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.monthlyCommittedCosts = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FIXED_COSTS, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.monthlyIncome = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.INCOME, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.monthlyFuturePlans = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FUTURE_PLANNING, SPENDING_ANALYSIS_KEYS.MONTHLY);
		},

		_getAnnualAnalysis() {
			this.annualEveryDaySpend = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.EVERYDAY_SPEND, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.annualCommittedCosts = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FIXED_COSTS, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.annualIncome = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.INCOME, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.annualFuturePlans = this._extractCycledAnalysisCategory(this.spendingAnalysis, SPENDING_ANALYSIS_CATEGORIES.FUTURE_PLANNING, SPENDING_ANALYSIS_KEYS.ANNUAL);
		},

		_mergeMonthlyAnnualAnalysis() {
			this.allEveryDaySpend = this._mergeAndSortArrays(this.monthlyEverydaySpend, this.annualEveryDaySpend, "to");
			this.allCommittedCosts = this._mergeAndSortArrays(this.monthlyCommittedCosts, this.annualCommittedCosts, "to");
			this.allFuturePlans = this._mergeAndSortArrays(this.monthlyFuturePlans, this.annualFuturePlans, "to");
			this.allIncome = this._mergeAndSortArrays(this.monthlyIncome, this.annualIncome, "to");
		},

		_getSlots() {
			this.months = this._extractCycledAnalysisDateSlots(this.spendingAnalysis, SPENDING_ANALYSIS_KEYS.MONTHLY);
			this.years = this._extractCycledAnalysisDateSlots(this.spendingAnalysis, SPENDING_ANALYSIS_KEYS.ANNUAL);
			this.slots = this._mergeAndSortArrays(this.months, this.years, "to");
		},

		_navigateToPlan(classification) {
			this.showPlanManagement = true;
			this.closeDrawerNext = false;
			var plan = this.spendingPlansFull.plans.find(p => p.classification.id === classification.id);

			if (!plan) {
				plan = {
					classification: classification,
					plan: {
						amount: 0
					},
					avgNetSpend: 0,
					avgTransactionCount: 0
				};
			}
			this._createPlan(plan);
		},

		_handleDrawerClosed() {
			this.merchantTransactions = [];
			this.categoryWellData = [];
			this.selectedClassification = null;
			this.showMerchantTransactions = false;
			this.selectedClassificationPlanAmount = 0;
			this.showPlanManagement = false;
		},

		_handleMerchantClicked(merchant) {
			this.merchantTransactions = [];
			var txns = this.transactions.transactions.filter(transaction => transaction.merchantName === merchant.label);

			// Group transactions by timestampString
			const groupedByTimestamp = txns.reduce((acc, transaction) => {
				const timestamp = transaction.timestampString || 'Unknown';
				if (!acc[timestamp]) {
					acc[timestamp] = [];
				}
				acc[timestamp].push(transaction);
				return acc;
			}, {});

			// Convert grouped transactions to an array of arrays
			this.merchantTransactions = Object.keys(groupedByTimestamp).map(timestamp => groupedByTimestamp[timestamp]);

			this.selectedMerchantSpend = this.merchantTransactions.reduce((total, transactions) => {
				return total + transactions.reduce((subTotal, transaction) => subTotal + transaction.amount, 0);
			}, 0);

			this.showMerchantTransactions = true;
			this.showPlanManagement = false;
			this.closeDrawerNext = false;
		},

		_handleCategoryClicked(classification, grandParentClassification) {
			var selectedSlot = this.slots.find(s => s.key === this.selectedSlotKey);
			this._getTransactions(classification.id, selectedSlot.from, selectedSlot.to, () => {
				this.groupedTransactions = this.transactions.transactions.reduce((acc, transaction) => {
					const merchantName = transaction.merchantName || 'Unknown';
					if (!acc[merchantName]) {
						acc[merchantName] = {
							merchantName: merchantName,
							totalAmount: 0,
							transactions: []
						};
					}
					acc[merchantName].totalAmount += transaction.amount;
					acc[merchantName].transactions.push(transaction);
					return acc;
				}, {});
				this.categoryWellData = [];
				Object.keys(this.groupedTransactions).forEach((merchantName, index) => {
					var count = this.groupedTransactions[merchantName].transactions.length;
					this.categoryWellData.push({
						order: index + 1,
						label: merchantName,
						value: this.formatter.format(Math.abs(Math.round(this.groupedTransactions[merchantName].totalAmount))),
						supplement: (count > 1 ? ' (x' + count + ')': '')
					});
				});

			}, true);
			var selectedClassificationPlan = this.spendingPlans.plans.find(plan => plan.classification.id === classification.id);
			if (selectedClassificationPlan) {
				this.selectedClassificationPlanAmount = selectedClassificationPlan.plan.amount;
			}
			var categoryArray = [];
			if (grandParentClassification === GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND) {
				categoryArray = this.allEveryDaySpend;
			} else if (grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING) {
				categoryArray = this.allFuturePlans;
			} else if (grandParentClassification === GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS) {
				categoryArray = this.allCommittedCosts;
			} else if (grandParentClassification === GRANDPARENT_CLASSIFICATIONS.INCOME) {
				categoryArray = this.allIncome;
			}
			var selectedClassification = this.selectedCategory(categoryArray, grandParentClassification).groupedAnalysis.find(g => g.transactionClassification.id === classification.id);
			console.log('selectedClassification', selectedClassification);
			if (grandParentClassification === GRANDPARENT_CLASSIFICATIONS.INCOME) {
				this.selectedClassificationIncome = selectedClassification.amountIn;
				this.selectedClassificationSpend = 0;
			}
			else {
				this.selectedClassificationSpend = selectedClassification.amountOut;
				this.selectedClassificationIncome = 0;
			}
			this.selectedClassification = classification;

			const classificationColours = {
				[GRANDPARENT_CLASSIFICATIONS.EVERYDAY_SPEND]: '#EEF0FF',
				[GRANDPARENT_CLASSIFICATIONS.FUTURE_PLANNING]: '#EFE8E6',
				[GRANDPARENT_CLASSIFICATIONS.FIXED_COSTS]: '#413655',
				[GRANDPARENT_CLASSIFICATIONS.INCOME]: '#B4D8D7'
			};
			this.selectedClassification.bgColour = classificationColours[this.selectedClassification.grandParentClassification];
			this.$refs.transactionDrawer.openDrawer();
		},
	}
}