<template>
	<header-block title="Activity"></header-block>
	<CustomerLoadWait :customer="customer && bankInfo" />

	<!-- <Transition>
		<BarArrowButton :large="true"
		:href="'/#/SpendingAnalysis'" 
		title="All Transactions & Payments" 
		:formatter="formatter"
		/>
	</Transition> -->

	<transition>
		<div v-if="bankInfo && bankInfo.accounts.length == 0">
			<v-row class="financialFitnessRow">
				<v-col>
					<AdButton class="linkAccounts" title="Link Your Bank Accounts" href="/#/aiop?add=true"
						subtitle="Money Guided is designed to work with insights into your personal finances. Link your bank accounts to benefit from the power shown in the examples below!"
						:topicon="require('@/assets/coins.png')" :bottomrighticon="require('@/assets/go.png')" />
				</v-col>
			</v-row>

			<v-row class="mt-2" style="opacity: 0.7; filter: blur(1px);">
				<v-col>
					<AnalysisWidget titleSize="small" :totals="fakeTotals" :formatter="formatter" :title="true"
						titleLabel="Spending Analysis" :daysRemaining="10" />
				</v-col>
			</v-row>

			<v-row class="mt-2" style="opacity: 0.7; filter: blur(1px);">
				<v-col>
					<InfoPanel :icon="require('@/assets/coins2.png')" title="Tag Transactions"
						subtitle="You have transactions to tag in your current pay cycle" valueTitle="Count"
						:value="7" />
				</v-col>
			</v-row>

			<v-row class="mt-2" style="opacity: 0.7; filter: blur(1px);">
				<v-col>
					<InfoPanel :icon="require('@/assets/coins.png')" title="Recurring Payments"
						subtitle="Stay on top of your recurring payments" valueTitle="Count" :value="13" />
				</v-col>
			</v-row>

			<v-row style="opacity: 0.7; filter: blur(1px);">
				<v-col>
					<PlanInfoPanel :plan="400" :spent="234" title="Groceries Budget"
						:formatter="formatter" />
				</v-col>
			</v-row>
		
			<v-row style="opacity: 0.7; filter: blur(1px);">
				<v-col>
					<GoalInfoPanel :goal="300"
						:saved="80" title="Sarah's New Bike" :formatter="formatter" />
				</v-col>
			</v-row>

		</div>
	</transition>


	<transition>
		<v-row class="mt-2"
			v-if="bankInfo && bankInfo.accounts.length > 0 && bankInfo.untaggedTransactionsCurrentCycleCount > 0">
			<v-col>
				<InfoPanel
					:href="'/#/Transaction/Bulk?from=' + bankInfo.currentCycleStartDate + '&to=' + bankInfo.today + '&includeNegative=true&includePositive=true'"
					:valueHref="'/#/Transaction/Bulk?from=' + bankInfo.currentCycleStartDate + '&to=' + bankInfo.today + '&includeNegative=true&includePositive=true'"
					:icon="require('@/assets/coins2.png')" title="Tag Transactions"
					subtitle="You have transactions to tag in your current pay cycle" valueTitle="Count"
					:value="bankInfo.untaggedTransactionsCurrentCycleCount" />
			</v-col>
		</v-row>
	</transition>

	<transition>
		<v-row v-if="bankInfo && bankInfo.accounts.length > 0" class="mt-2">
			<v-col>
				<AnalysisWidget titleSize="small" :totals="selectedTotals" :formatter="formatter" :title="true"
					titleLabel="Spending Analysis"
					:daysRemaining="spendingAnalysis ? spendingAnalysis.daysTillCycleResets : 0"
					@click="navigateToSpendingAnalysis()" />
			</v-col>
		</v-row>
	</transition>
	
	<transition>
		<v-row v-if="bankInfo && bankInfo.accounts.length > 0 && spendingPlans && planReady">
			<v-col>
				<PlanInfoPanel @click="navigateToPlan()" :plan=totalPlan :spent=totalSpend title="Spending plan"
					:formatter="formatter" />
			</v-col>
		</v-row>
	</transition>

	<transition>
		<v-row class="mt-2" v-if="recurringPayments && recurringPayments.payments.length > 0">
			<v-col>
				<InfoPanel :href="'/#/RecurringPayments'" :valueHref="'/#/RecurringPayments'"
					:icon="require('@/assets/coins.png')" title="Recurring Payments"
					subtitle="Stay on top of your recurring payments" valueTitle="Count"
					:value="recurringPayments.payments.length" />
			</v-col>
		</v-row>
	</transition>

	<transition>
		<v-row v-if="bankInfo && bankInfo.accounts.length > 0 && goalsReady" class="mt-2">
			<v-col v-if="totalGoals > 0">
				<GoalInfoPanel @click="navigateToGoals()" :goal="this.totalSavingGoals"
				:saved="this.totalSavingGoalsSaved" :title="totalGoals + ' Savings Goal' + (totalGoals > 1 ? 's' : '')" :formatter="formatter" />
			</v-col>
			<v-col v-if="totalGoals	== 0">
				<InfoPanel :href="'/#/Goals'" :valueHref="'/#/Goals'"
					:icon="require('@/assets/coins2.png')" title="Saving Goals"
					subtitle="Save for the important things in life"
					/>
			</v-col>
		</v-row>
	</transition>
	
	<transition>
		<BarArrowButton href="/#/aiop?show=true" class="mt-6" v-if="bankInfo && bankInfo.recentTransactions && bankInfo.recentTransactions.length > 0" :large="true" title="Recent">
			<TransactionsList class="mt-4" :search="false" :showDate="false" :transactions="bankInfo.recentTransactions.slice(0,1)" :formatter="formatter" :today="today" :yesterday="yesterday" />
		</BarArrowButton>
	</transition>

</template>

<script>
import Services from '../mixins/Services';
import AnalysisWidget from './elements/AnalysisWidget.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import Helpers from '../mixins/Helpers';
import SpendingAnalysis from '../mixins/SpendingAnalysis';
import PlanInfoPanel from './elements/PlanInfoPanel.vue';
import InfoPanel from './elements/InfoPanel.vue';
import GoalInfoPanel from './elements/GoalInfoPanel.vue';
import AdButton from './elements/AdButton.vue';
import BarArrowButton from './elements/BarArrowButton.vue';
import TransactionsList from './elements/TransactionsList.vue';

export default {
	mixins: [Services, Helpers, SpendingAnalysis],
	components: { CustomerLoadWait, HeaderBlock, AnalysisWidget, PlanInfoPanel, InfoPanel, GoalInfoPanel, AdButton, BarArrowButton, TransactionsList },
	data() {
		return {
			fakeTotals: this._getMockSpendingTotals(),
			selectedTotals: null,
			goalsReady: false,
			totalSavingGoals: null,
			totalSavingGoalsSaved: null,
			totalGoals: null,
			recurringPayments: null,
			totalPlan: 0,
			totalSpend: 0,
			planReady: false
		}
	},
	computed: {
		transactionAccounts() {
			var ret = [];
			if (this.bankInfo == null) {
				return ret;
			}
			this.bankInfo.accounts.forEach(a => {
				if (a.accountType.prettyName.toLowerCase() == "current") {
					ret.push(a);
				}
			});
			return ret;
		},
	},
	methods: {

		onLoad: async function () {
			this._getAppConfig();
			this._getCustomer(() => {
				this._getBankingInfo(() => {
					if (this.bankInfo.spendingCycle.id == "00000000-0000-0000-0000-000000000000") {
						this.$router.replace('/setupspendinganalysis');
						return;
					}
					this._getRecurringPayments((res) => {
						this.recurringPayments = res;
					});
					this._getSpendingAnalysis(() => {
						this.selectedTotals = this._getLatestMonthsSpendingTotals(),
							this._getSpendingPlansLite(() => {
								this.getTotals();
							});
					});
					this._getSavingGoals(() => {
						this.goalsReady = true;
						this.createGoalsSummary();
						console.log('savingsGoals', this.savingGoals);
					});
				});
			});
		},
		getTotals() {
			this.spendingPlans.plans.forEach(plan => {
				var spend = this.spendingAnalysis.cycledAnalysis[0].groupedAnalysis.find(x => x.transactionClassification.id == plan.classification.id);
				if (spend) {
					plan.spend = spend.amountNet;
				}
			}, false);
			this.totalSpend = Math.round(Math.abs(this.spendingPlans.plans.reduce((a, b) => a + (b.spend || 0), 0)));
			this.totalPlan = Math.round(Math.abs(this.spendingPlans.plans.reduce((a, b) => a + b.plan.amount, 0)));
			this.planReady = true;
		},
		createGoalsSummary() {
			let totalTargetAmount = 0;
			if (this.savingGoals && this.savingGoals.length > 0) {
				this.savingGoals.forEach(goal => {
					totalTargetAmount += goal.targetAmount;
				});
			}
			this.totalSavingGoals = totalTargetAmount;

			let totalCurrentBalance = 0;
			if (this.savingGoals && this.savingGoals.length > 0) {
				this.savingGoals.forEach(goal => {
					totalCurrentBalance += goal.currentBalance;
				});
			}
			this.totalSavingGoalsSaved = totalCurrentBalance;

			this.totalGoals = this.savingGoals.length;
		},
		navigateToSpendingAnalysis() {
			this.$router.push('/SpendingAnalysis');
			//this._openV1('/Moves/Spending', 'Activity');
		},
		navigateToPlan() {
			this.$router.push('/SpendingPlan');
			//this._openV1('/Moves/Spending/Plan', 'Activity');
		},
		navigateToGoals() {
			this.$router.push('/Goals');
			//this._openV1('appscreen/Goals', 'Activity');
		}

	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
.linkAccounts {
	padding-top: 10px;
}

.moves-title-row {
	margin-top: 10px;
	padding-top: 10px;

	.v-col {
		padding-bottom: 5px;

		a {
			text-decoration: underline;
		}
	}
}

.moves-widget-col {
	padding-top: 0px;
	height: 275px;
}
</style>
