<template>
	<div class="drawerContent" v-if="!managePlan">
		<v-row class="createPlanHeader">
			<v-col class="text-right">
				Avg. monthly spend
			</v-col>
		</v-row>
		<v-row v-for="plan in categoriesPanel" :key="plan.classification.id">
			<v-col class="verticalCenter" cols="2">
				<img v-if="plan.classification.iconPath" 
					:src="require('@/assets/transactions/' + plan.classification.iconPath.toLowerCase().replace('.svg', '.png'))" 
					class="icon" :style="{ background: themeColour }"
				/> 
			</v-col>
			<v-col class="verticalCenter">
				{{ plan.classification.fullName }}
			</v-col>
			<v-col class="verticalCenter right">
				{{ formatter.format(Math.round(plan.avgNetSpend)) }}
			</v-col>
			<v-col class="verticalCenter plusCol" cols="2">
				<img v-if="!plan.plan" class="plusIcon" src="../../assets/plus.png" @click="handleCreateClick(plan)" />
				<img v-else class="plusIcon" src="../../assets/bullet-tick.png" @click="handleCreateClick(plan)" />
			</v-col>
		</v-row>
	</div>
	<div v-else class="simplePage">
		<h1>{{ selectedPlan.classification.fullName }}</h1>
		<img v-if="selectedPlan.classification.iconPath" 
					:src="require('@/assets/transactions/' + selectedPlan.classification.iconPath.toLowerCase().replace('.svg', '.png'))" 
					class="smallHeroImg"> 
		<p>On average you spend {{ formatter.format(Math.round(selectedPlan.avgNetSpend))}} per month on '{{ selectedPlan.classification.fullName }}'. What target would you like to set yourself?</p>
		<p>
			<InputSections class="inputAmount" ref="inputSections" :sections="sections" theme="light"/>
		</p>
		<MGButton :disabled="this._findFieldValue('Plan amount') <= 0" text="Set Plan" @click="handleUpdatePlan(selectedPlan)"></MGButton>
		<MGButton v-if="selectedPlan.plan && selectedPlan.plan.amount > 0" :outline="true" text="Delete Plan" @click="handleDeletePlan(selectedPlan)"></MGButton>
	</div>	
</template>

<script>
import Helpers from '../../mixins/Helpers';
import InputSections from './InputSections.vue';
import MGButton from './MGButton.vue';

export default {
	name: "SpendingPlanManagement",
	props: ['managePlan','selectedPlan','categoriesPanel','themeColour','formatter', 'sections'],
	emits: ['handleCreateClick', 'handleUpdatePlan', 'handleDeletePlan'],
	mixins: [Helpers],
	components: {MGButton, InputSections},
		data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		},
		handleCreateClick(plan) {
			this.$emit('createPlan', plan);
		},
		handleUpdatePlan(plan) {
			if (plan.plan === null) plan.plan = {};
			plan.plan.amount = this._findFieldValue('Plan amount');
			this.$emit('updatePlan', plan);
		},
		handleDeletePlan(plan) {
			this.$emit('deletePlan', plan);
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>


.createNew {
	margin-top: 15px;
}

.createPlanHeader {
	font-weight: bold;
	padding: 5px;

}

.icon {
	width: 35px;
	background-color: #E7ECF7;
	border-radius: 90px;
}

.plusCol {

}

.plusIcon {
	width: 30px;
}

.backIcon {
	width: 20px;
}


.simplePage {
	h1 {
		font-size: 28px;
	}

.smallHeroImg {
	width: 70px;
	background-color: var(--text-color);
	border-radius: 50%;
}
.v-text-field {
	width: 60%;
	margin-left: auto;
	margin-right: auto;
}
}

.inputAmount {
	width: 60%;
	margin-left: auto;
	margin-right: auto;
}

.everydaySpendProgress {
	margin-top: 10px;
	margin-bottom: 10px;
}

.everydaySpendExceeded {
	margin-top: 10px;
	margin-bottom: 10px;
}

.committedCostsProgress {
	margin-top: 10px;
	margin-bottom: 10px;
}

.committedCostsExceeded {
	margin-top: 10px;
	margin-bottom: 10px;
}

.futurePlansProgress {
	margin-top: 10px;
	margin-bottom: 10px;
}

.futurePlansExceeded {
	margin-top: 10px;
	margin-bottom: 10px;
}

.classificationLabel {
	margin-top: 0px;
}

.everydaySpendProgress :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.everydaySpendExceeded :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #FFA726 3.1%, #9EA2D0 95.08%);
}

.committedCostsProgress :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.committedCostsExceeded :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #FFA726 3.1%, #9EA2D0 95.08%);
}

.futurePlansProgress :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #9EA2D0 3.1%, #61678D 95.08%);
}

.futurePlansExceeded :deep(.v-progress-linear__determinate) {
	background: linear-gradient(215.75deg, #FFA726 3.1%, #9EA2D0 95.08%);
}

.everydaySpendProgress :deep(.v-progress-linear__background.bg-primary) {
	background-color: white !important;
	opacity: 1 !important
}

.committedCostsProgress :deep(.v-progress-linear__background.bg-primary) {
	background-color: white !important;
	opacity: 1 !important
}

.futurePlansProgress :deep(.v-progress-linear__background.bg-primary) {
	background-color: white !important;
	opacity: 1 !important
}

</style>