<template>
	
		<div class="article-list" :style="'background-image:url(' + bottomrighticon + ')'" @click="event => {event.stopPropagation()}">
			<div v-for="article in articles" :key="article.id" class="article-wrapper" @click="$router.push('/articles/' + article.id)">
				<v-row>
					<v-col class="v-col-9 title-col">
						{{ article.title }}
					</v-col>
					<v-col class="v-col-3 thumb-col">
						<img :src="article.iconImage">
					</v-col>
				</v-row>
				<v-row>
					<v-col class="description-col">{{ article.shortSummary }}</v-col>
				</v-row>
				<v-row>
					<v-col class="v-col-9 tags-col">
						<v-chip class="articleTag" variant="outlined" size="x-small">{{ article.tagsArray[0] }}</v-chip>
					</v-col>
					<v-col class="">
						
					</v-col>
					<v-col class="arrow-col">
						<img class="arrow" src="../../assets/arrow-right.png">
					</v-col>
				</v-row>
			</div>
		</div>
	
</template>

<script>
export default {
	name: "ArticleList",
	props: ['articles'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
			
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

/* .article-list {
	overflow-y: scroll;
	overflow-x: hidden;
	max-height: 90%;
} */

.article-wrapper {
	margin-top: 5px;
	padding-top: 20px;
	padding-bottom: 20px; 
	border-bottom: 1px solid var(--border-color)
}

.title-col {
	font-size: 18px;
	font-weight: bold;
}

.thumb-col {
	img {
		height: 40px;
		border-radius: 5px;
	}
	text-align: right;
}

.description-col {
	padding-top: 0px;
	font-weight: lighter;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
}

.arrow {
	width: 18px;
}

.articleTag {
	border-radius: 100px;
	border-color: transparent;
	background: linear-gradient(358.65deg, #F7F4F0 0.56%, #FAF8F5 101.36%);
}

</style>