<template>
	<header-block :title="(this.policy ? this.policy.policyType.name : 'Insurance') + ' Policy'" :del="true" @delete="deleteAccount" :edit="'/Policies/Insurance/Edit/' + this.$route.params.id"></header-block>
	<CustomerLoadWait :customer="policy" />
	<transition>
		<div v-if="policy">
			
			<WellPanel 
				:title="policy.provider.name" 
				subTitle="Provider"
				:avatarLetter="policy.provider.name.charAt(0)"
				:data="wellData"
			/>
			
		</div>
	</transition>
	<DrawerPanel bottom="-18px" openHeight="250px" title="Saved documents" backgroundColor="white" openBackgroundColor="white">
		<div class="docsPanel" v-if="documents">
			<a v-for="d in documents" :key="d.id" :href="d.url">
				<v-row>
					<v-col cols="2" class="docIconCol"><v-icon class="docIcon" icon="mdi-file-document-outline"></v-icon></v-col>
					<v-col class="docCol">{{ d.name }}<br/><span>{{ d.createDateTimeString }}</span></v-col>
					<v-col cols="1" class="arrowCol"><img class="arrow" src="../assets/arrow-right.png" /></v-col>
				</v-row>
			</a>
		</div>
	</DrawerPanel>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import WellPanel from './elements/WellPanel.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, WellPanel, DrawerPanel},
	data() {
		return {
			policyId: null,
			wellData: []
		}
	},
	methods: {
		onLoad: async function() {
			this.policyId = this.$route.params.id;
			this._getCustomer();
			this._getPolicy(this.policyId, () => {
				this.wellData = [
					{order: 1, label: 'Policy number', value: this.policy.policyNumber},
					{order: 2, label: 'Pay frequency', value: this.policy.payFrequency},
					{order: 20, label: 'Start date', value: this.policy.startDateString},
					{order: 21, label: `Renewal in ${this._calculateMonthsRemaining(this.policy.endDate)} months`, value: this.policy.endDateString, progress: this._calculateDateProgressPercentage(this.policy.startDate, this.policy.endDate)},
				];

				if (this.policy.policyType.name === 'Contents' || this.policy.policyType.name === 'Buildings' || this.policy.policyType.name === 'Buildings & Contents') {
					this.wellData.push(
						{order: 3, label: 'Annual premium', value: this.formatter.format(this.policy.annualPremium)},
						{order: 4, label: 'Address', value: [this.policy.insuredItems[0].addressInsured.line1, this.policy.insuredItems[0].addressInsured.line2, this.policy.insuredItems[0].addressInsured.postCode].filter(Boolean).join(', ')},
						{order: 5, label: 'Value insured', value: this.formatter.format(this.policy.insuredItems[0].valueInsured)}
					);
				}

				if (this.policy.policyType.name === 'Vehicle') {
					this.wellData.push(
						{order: 3, label: 'Monthly premium', value: this.formatter.format(this.policy.monthlyPremium)},
						{order: 4, label: 'Vehicle', value: this.policy.insuredItems[0].vehicleModel}, 
						{order: 5, label: 'Registration', value: this.policy.insuredItems[0].vehicleRegistration}, 
						{order: 6, label: 'Excess', value: this.formatter.format(this.policy.excess)},
					);
				}

				this._getDocumentsByHubId(this.policy.id);
			});
		},		
		deleteAccount: function(){
			this._deletePolicy(this.$route.params.id, () => {
				this._getInsuranceInfo(/*onSuccess*/ null, /*refreshCache*/ true);
				this.$router.push('/aiop');
			});
		},
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.docsPanel {
	padding-right: 20px;
}

.docIconCol {
	padding-top: 17px;
	font-size: 18px;
}

.docIcon {
	c--olor: var(--grey-text);
}

.docCol {
	font-size: 14px;
	c--olor: var(--grey-text);
}

.arrowCol {
	padding-top: 22px;
}

.arrow {
	width: 18px;
	
}

</style>
