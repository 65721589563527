<template>
	<header-block title="Add offline account" bgcolor="white" border="0"></header-block>
	<CustomerLoadWait :customer="customer"/>
	<div class="progress-container">
		<v-progress-linear :model-value="progressValue" rounded></v-progress-linear>
	</div>		

	<transition>
		<div v-if="customer">
			<div class="personalDetails">
				<InputSections ref="inputSections" :sections="sections" theme="light" :editMode="editMode"/>
				<div v-if="validationText" class="well">
					<p>{{ validationText }}</p>
				</div>
				<div class="anchor-bottom submitControls">
					<v-row>
						<v-col cols="5" v-if="currentPageNumber > 0"><MGButton small="true" :outline="true" @click="_inputControl_GoBack()" text="Back" /></v-col>
						<v-col><MGButton :small="currentPageNumber > 0" :alt="true" text="Next" :arrow="true" @click="_inputControl_GoNext(addAccount)"/></v-col>
					</v-row>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Helpers from '../mixins/Helpers';
import Services from '../mixins/Services';
import AddOfflineBankAccountSections from './AddOfflineBankAccountSections';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services, Helpers, AddOfflineBankAccountSections],
	components: {HeaderBlock, InputSections, MGButton, CustomerLoadWait},
	data() {
		return {
			validationText: null,
			sectionNo: 0,
			currentPageNumber: 0
		}
	},
	computed: {
		progressValue() {
			var insuranceType = this._findFieldValue('Policy type');
			var totalPages = this.sectionsAvailableCount(insuranceType);
			if (totalPages === 0) return 0;
			return ((this.currentPageNumber + 1) / totalPages) * 100;
		},
		editMode() {
			return this.$route.params.id ? true : false;
		}
	},
	methods: {
		onLoad: async function() {
			if (this.$route.params.id) {
				this._getBankAccount(this.$route.params.id, (account) => {
					this.populateFields(account);
				});
			}

			this._getCustomer();
			this._listBanks((res) => {
				var bankField = this._findField('Name of bank');
				bankField.items = res.map(provider => {
					return provider.name.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
		},
		populateFields(account) {
			const fieldMappings = {
				'Name of bank': account.bank.name,
				'Nickname (e.g personal account)': account.bank.pretyName,
				'Balance': account.balance,
				'Ownership': account.jointStatus === 2 ? 'Joint' : account.jointStatus === 1 ? 'Sole' : null,
				'Account number': account.accountNumber,
				'Sort code': account.sortCode,
			};

			this._loadFieldsFromObject(fieldMappings);
		},
		addAccount() {
			var account = {
				accountType: "Transaction",
				jointStatus: this._findFieldValue('Ownership') === 'Joint' ? 2 : this._findFieldValue('Ownership') === 'Sole' ? 1 : null,
				customerID: this.customer.id,
				bankName: this._findFieldValue('Name of bank') || null,
				displayName: this._findFieldValue('Nickname (e.g personal account)') || null,
				balance: this._findFieldValue('Balance') || null,
				accountNumber: this._findFieldValue('Account number') || null,
				sortCode: this._findFieldValue('Sort code') || null,
			}

			if (this.$route.params.id) {
				account.id = this.$route.params.id;
				var accountType = {
					name: "Transaction"
				}
				account.accountType = accountType;
			}

			const handleResponse = (res) => {
				if (res) {
					if (this.$route.params.id) {
						this._getBankAccount(this.$route.params.id, /*onSuccess*/ null, /*lite*/ true, /*refreshCache*/ true);
					}
					this._getBankingInfo(/*onSuccess*/ null, /*refreshCache*/ true);
					this.$router.push({ path: '/policysuccess', query: { type: 'account' } });
				}
			};

			if (!this.$route.params.id) {
				this._addOfflineBankAccount(account, handleResponse);
			} else {
				this._updateOfflineBankAccount(account, handleResponse);
			}

			// this._addOfflineBankAccount(account, (res) => {
			// 	if (res) {
			// 		this._getBankingInfo(/*onSuccess*/ null, /*refreshCache*/ true);
			// 		this.$router.push({ path: '/policysuccess', query: { type: 'offline account' } });
			// 	}
			// });
		},
		sectionsAvailableCount: function(insuranceType) {
			var sectionsNoRule = this.sections.filter(section => !section.rule);
			var sectionsMatchingRule = this.sections.filter(section => section.rule && section.rule.includes(insuranceType));
			return sectionsNoRule.length + sectionsMatchingRule.length;
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>


:deep(.check .v-label) {
	color: white !important;
}

.well {
	text-align: center;
	background-color: #3328470D;
}

.progress-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

:deep(.v-progress-linear__background) {
  background-color: var(--border-color) !important;
}

</style>
