<template>
	<header-block :title="'Uncategorised - ' + groupedTransactions.length + ' left'" class="title"></header-block>
	<CustomerLoadWait :customer="((transaction && bankAccount) || firstTransaction) && classificationOptions" :cardBlocks="true" :withTopCard="true" />
	<v-slide-group v-if="groupedTransactions && bankInfo" class="transactionGroup" center-active>
		<v-slide-group-item v-for="g in this.groupedTransactions" :key="g" :selected="g.value[0].selected">
			<TransactionGroupCard 
			@click="onTransactionSelected(g.value[0])"
			:account="getBankAccountByAccountId(g.value[0].bankAccountID)" 
			:merchant="g.value[0].merchantName"
			:amount="getTransactionGroupAmount(g.value)"
			:date="g.value[0].timestampFormatted"
			:rawDate="g.value[0].timestamp"
			:count="g.value.length"
			:selected="g.value[0].selected"
			:formatter="formatter"
			:transactionId="g.value[0].id" />
		</v-slide-group-item>
	</v-slide-group>
	<div class="bulkTagSelector" v-if="firstTransaction">
		<TransactionTagging v-if="suggestedClassificationReady" :selectAlways="true" :transaction="firstTransaction" :categories="transactionClassifications" :currentSelectionId="selectedClassificationId"
				:suggestedClassification="suggestedClassification"
				@tag="_onTagUpdated" @chosen="_scrollToBottom" />
	</div>
</template>


<script>

import Services from '../mixins/Services';
import SpendingAnalysis from '../mixins/SpendingAnalysis';
import Helpers from '../mixins/Helpers';
import Transaction from '../mixins/Transaction';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import TransactionTagging from './elements/TransactionTagging.vue';
import TransactionGroupCard from './elements/TransactionGroupCard.vue';

export default {
	mixins: [Services, Helpers, SpendingAnalysis, Transaction],
	components: { CustomerLoadWait, HeaderBlock, TransactionTagging, TransactionGroupCard },
	data() {
		return {
			classificationOptions: [],
			classifications: [],
			selectedClassificationId: null,
			selectedClassification: null,
			firstTransaction: null,
			selectedTransactionGroup: [],
			fromDate: null,
			toDate: null,
			groupedTransactions: [],
			includeNegative: null, 
			inclidePositive: null,
			bulkClassificationId: null,
			suggestedClassificationReady: false,
			groupSize: null
		}
	},
	methods: {
		onLoad: async function() {
			const queryParams = this.$route.query;
			this.fromDate = queryParams.from || null;
			this.toDate = queryParams.to || null;
			this.includeNegative = queryParams.includeNegative || null;
			this.includePositive = queryParams.includePositive || null;
			this.fetchUncategorizedClassificationsAndTransactions();
		},
		fetchUncategorizedClassificationsAndTransactions() {
			this._getTransactionClassifications((result) => {
				this.bulkClassificationId = this._findClassificationByName('Uncategorized', result).mgClassification.id;
				this.transactionClassifications = result;
				this.classifications = result;
				this.classificationOptions = result.map(c => ({id: c.mgClassification.id, title: c.mgClassification.fullName}));
				this.getTransactions(this.bulkClassificationId);
			});
		},
		getTransactions: function(id){
			this._getTransactions(id, this.fromDate, this.toDate, () => 
			{
				this.suggestedClassificationReady = false;

				this._getSuggestedClassification(this.transactions.transactions[0].merchantName, () => {
					this.suggestedClassificationReady = true;
				});

				// remove negative transactions if includeNegative is false
				if (!this.includeNegative) {
					this.transactions.transactions = this.transactions.transactions.filter(t => t.amount > 0);
				}
				// remove positive transactions if includePositive is false
				if (!this.includePositive) {
					this.transactions.transactions = this.transactions.transactions.filter(t => t.amount < 0);
				}

				this.groupTransactionsByMerchantName();
				this.groupedTransactions[0].value[0].selected = true;
				this.transaction = this.groupedTransactions[0].value[0];
				this.firstTransaction = this.groupedTransactions[0].value[0];
				this.selectedTransactionGroup = this.groupedTransactions[0];
				this._getBankingInfo();
			});
		},
		getBankAccountByAccountId: function(id) {
			var bank = this.bankInfo.accounts.find(ba => ba.id === id);
			return bank;
		},
		groupTransactionsByMerchantName: function() {
			this.groupedTransactions = this.transactions.transactions.reduce((acc, transaction) => {
				const merchantName = transaction.merchantName || 'Unknown';
				if (!acc[merchantName]) {
					acc[merchantName] = [];
				}
				acc[merchantName].push(transaction);
				return acc;
			}, {});

			this.groupedTransactions = Object.entries(this.groupedTransactions)
				.map(([key, value]) => ({ key, value }))
				.sort((a, b) => new Date(b.value[0].timestamp) - new Date(a.value[0].timestamp));
		},
		getClassificationNameByClassiciationId: function(id) {
			const classification = this._findClassificationById(id, this.classifications);
			if (classification) {
				const name = classification.mgClassification.mgSubClassification || classification.mgClassification.fullName;
				return name.length > 15 ? name.substring(0, 10) + '...' : name;
			}
			return null;
		},
		getIconByClassiciationId: function(id) {
			const classification = this._findClassificationById(id, this.classifications, true);
			if (classification) {
				return classification.mgClassification.iconPath;
			}
			return null;
		},
		updateClassification: function(transaction) {
			if (transaction.alwaysApplyTag || transaction.alwaysApplyRule) {
				this._createRule(transaction);
			}
			else if (this.selectedTransactionGroup && this.selectedTransactionGroup.value.length > 1) {
				// update a group of transcations
				this.updateGroupTransactions(transaction);
			}
			else {
				// update single transcation
				this._updateSingleTransaction(transaction);
			}

			if (this.groupedTransactions) {
				this.removeTransactionFromGroup(transaction);
				if (this.groupedTransactions.length === 0) {
					this.$router.replace({ path: '/bulktaggingcomplete' });
				}
			}
		},
		removeTransactionFromGroup: function(transaction) {
			const index = this.groupedTransactions.findIndex(g => g.value[0].selected === true);
			this.groupedTransactions = this.groupedTransactions.filter(g => g.value[0].id !== transaction.id);
			if (index >= 0 && index < this.groupedTransactions.length) {
				this.groupedTransactions[index].value[0].selected = true;
				this.firstTransaction = this.groupedTransactions[index].value[0];
			} else if (this.groupedTransactions.length > 0) {
				this.groupedTransactions[this.groupedTransactions.length - 1].value[0].selected = true;
				this.firstTransaction = this.groupedTransactions[this.groupedTransactions.length - 1].value[0];
			}
			if (this.groupedTransactions.length > 0) {
				this.suggestedClassificationReady = false;
				this._getSuggestedClassification(this.groupedTransactions[0].value[0].merchantName, () => {
					this.suggestedClassificationReady = true;
				});
			}
		},

		updateGroupTransactions: function(transaction) {
			var customerClassification = transaction.customerClassification;
			this._updateBulkTransactionClassifications(this.selectedTransactionGroup, customerClassification, () => {
				this.refresh();
			});
		},

		refresh: function() {
			this._getTransactions(this.bulkClassificationId, this.fromDate, this.toDate, () => {}, /*refreshCache: */ false, /* clearCache */ true);
			this._getBankAccount(this.transaction.bankAccountID, null, /* lite: */ false, /*refreshCache: */ true); //Force cache refresh of bank transactions to reflect new classification
			this._getSpendingAnalysis(null, /*refreshCache: */ false, /* clearCache */ true);	
			this._clearBankingInfo();
		},

		onTransactionSelected: function(transaction) {
			this.firstTransaction = transaction;
			this.transaction = transaction;
			this.groupedTransactions.forEach(element => {
				element.value[0].selected = false;
			});
			this.selectedTransactionGroup = this.groupedTransactions.find(g => g.value[0].id === transaction.id);
			this.suggestedClassificationReady = false;

			this._getSuggestedClassification(this.transaction.merchantName, () => {
				this.suggestedClassificationReady = true;
			});

			transaction.selected = true;
		},
		getTransactionGroupAmount(group) {
			var total = 0;
			// foreach group.value, sum the amount
			group.forEach(t => total += t.amount);
			return total;
		},
		getTransactionCount(group) {
			this.groupSize = group.length;
			return group.length;
		}
	},
	mounted: function () {
		this.onLoad();
	},
}

</script>

<style scoped>


.transactionHeader {
	color: var(--text-color);
	font-size: 20px;
	text-align: center;
	padding-top: 30px;
	font-weight: bold;
	p{
		font-size: 20px;
		font-family: 'Gotham-Heavy';
		margin-top: 0px;
	};
	.small {
		font-size: 10px;
		margin-top: -40px;
	}
}

.title {
	font-size: 14px;
}

/* .well {
	background-color: white;
	margin-left: 20px;
	width: calc(100vw - 70px);
	transition: transform 0.3s ease, box-shadow 0.3s ease;
	opacity: 0.7;
	padding: 
	img {
		width: 30px;
		border-radius: 50%;
	}
}	 
*/

/* .well.selected {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
	opacity: 1;
}

.selected {
	border: 1px solid var(--text-color) !important;
} */

.transactionGroup {
	height: 100%;
	margin-left: -30px;
	margin-right: -30px;
}

.bulkTagSelector {
	margin-right: -30px;
	margin-left: -30px;
	background-color: white;
	padding: 20px;
	padding-top: 5px;
	overflow-y: scroll;
}

</style>
