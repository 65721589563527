export default {

	data() {
		return {
			sections: 
			[
				{
					title: null,
					subtitle: null,
					fields: [
						{
							validationText: 'Please complete all fields',
							label: 'Ownership',
							type: 'dropdown',
							items: ['Sole', 'Joint'],
							value: null,
							required: true,
							readOnly: true
						},
						{
							validationText: 'Please complete all fields',
							label: 'Name of bank',
							type: 'dropdown',
							items: this.banks,
							value: null,
							required: true,
							readOnly: true
						},
						{
							label: 'Nickname (e.g personal account)',
							type: 'text',
							required: false,
							value: null,
							readOnly: true
						}
					]
				},
				{
					title: null,
					subtitle: null,
					fields: [
						{
							validationText: 'Please complete all fields',
							label: 'Balance',
							type: 'currency',
							required: true,
							value: null
						}
					]
				},
				{
					title: null,
					subtitle: null,
					fields: [
						{	
							label: 'Sort code',
							type: 'sortcode',
							required: false,
							value: null,
							readOnly: true
						},
						{
							label: 'Account number',
							type: 'accountnumber',
							required: false,
							value: null,
							readOnly: true
						}
					]
				}
			]
		}
	},
}