<template>
	<a :href="href != null ? href : $router.path">
		<div class="account-summary-button" :style="'background: ' + background ?? 'white'">
			<v-row>
				<v-col class="title-col">
					{{ title }}
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<img class="icon" :src="icon" />
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col>
					{{ count }} {{ word??'account' }}(s)
				</v-col>
			</v-row>
			<v-row class="mt-0">
				<v-col class="v-col-7 totalCol">
					<span class="totalLabel">{{total}}</span>
				</v-col>
				<v-col class="arrow-col">
					<img class="arrow" src="../../assets/arrow-right.png">
				</v-col>
			</v-row>
		</div>
	</a>
</template>

<script>
export default {
	name: "AccountSummaryButton",
	props: ['title', 'icon', 'count', 'total', 'word', 'href', 'background'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.account-summary-button {
	border-radius: 8px;
	padding: 18px;
	border-color: var(--border-color);
	border: 0px solid var(--border-color);
	height: 100%;
	background-color: var(--background-color);
	width: 100%;
	box-shadow: 0px 4px 8px 0px rgba(51, 40, 71, 0.05);
}

.title-col {
	font-size: 13px;
}

.icon {
	width: 40px;
	height: 40px;
}

.arrow-col {
	text-align: right;
	padding-left: 0px;
}

.arrow {
	width: 18px;
}

.totalCol {
	padding-right: 0px;
}

.totalLabel {
	overflow: hidden;
	text-wrap: nowrap;
	max-width: 100%;
	display: block;
	text-overflow: ellipsis
}



</style>