<template>
	<HeaderBlock title="" bgcolor="transparent" border="0" />
	<CustomerLoadWait :customer="product" />
	<transition>
		<div v-if="product" class="product">
			<div class="productImg" :style="'background-image: url(' + product.backgroundImage + ')'"></div>
			<h2>{{ product.title }}</h2>
			<!-- <img :src="product.image" /> -->
			<h1>{{ product.subTitle }}</h1>
			<h2>{{ product.intro }}</h2>
			<p v-html="product.description.replaceAll('</h2>\n\n', '</h2>\n').replaceAll('\n', '<br/>')" />
			<div class="well" v-if="product.bodyListItems && product.bodyListItems.length > 0">
				<div class="tick" v-for="t in product.bodyListItems.split(',')" :key="t">{{ t }}</div>
			</div>
			<div v-for="p in product.paragraphs" :key="p">
				<h2>{{ p.title }}</h2>
				<p>{{p.body}}</p>
			</div>
			<MGButton :fullWidth="true" :text="product.actionButtonText" @click="nav" />
		</div>	
	</transition>
</template>

<script>
import Services from '../mixins/Services';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, HeaderBlock, MGButton},
	data() {
		return {
			product:null
		}
	},
	methods: {
		onLoad: async function() {
			this._getProduct(this.$route.params.id, (p) => {
				this.product = p;
			});
		},
		nav: function() {
			window.location = this.product.websiteUrl;
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

.product {
	.productImg {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 300px;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -80px;
		margin-bottom: 20px;
	}
	img {
		width: 100%;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -30px;
		/* width: 100vw;
		margin-left: -30px;
		margin-right: -30px;
		margin-top: -30px; */
		margin-bottom: 15px;
	}
	h1 {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	h2 {
		font-size: 16px;;
	}
	.v-chip {
		margin-bottom: 15px;
	}
	p {
		margin-top: 15px;
		margin-bottom: 15px;
	}
	.well {
		background-color: var(--background-color-alt);
		.tick {
			height: 33px;
			margin-bottom: 7px;
			background-image: url('../assets/tip.png');
			background-size: 30px;
			background-repeat: no-repeat;
			background-position: 0px 0px;
			padding-left: 45px;
			padding-top: 4px;
			font-size: 14px;
			font-weight: bold;
		}
		:last-child {
			margin-bottom: 0px;
		}
	}
}


</style>
