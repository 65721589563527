
export default {

	data() {
		return {
			breadCrumb: [],
		}
	},

	created: function() {
		this.bounce();
	},

	methods: {

		bounce() {
			if (this.$route.meta.bounce) {
				var bounced = 0;
				if (localStorage.getItem('bounce')) {
					bounced = parseInt(localStorage.getItem('bounce'));
					bounced ++;
				}
				else {
					localStorage.setItem('bounce', 1);
				}
				if (bounced < 10) {
					localStorage.setItem('bounce', bounced);
					this.$nextTick(() => {
						setTimeout(() => {
							const contentHeight = document.getElementById('body').clientHeight;
							const windowHeight = window.innerHeight;
							const scrollableHeight = contentHeight - windowHeight;
							if (scrollableHeight > 150) {
								window.scrollTo({ top: scrollableHeight, behavior: 'smooth' });
								setTimeout(() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
								}, 500);
							}
						}, 500);
					});
				}
			}
		},
		
		_getBeginningOfPreviousYear() {
			const now = new Date();
			const previousYear = now.getFullYear() - 1;
			return new Date(previousYear, 0, 1);
		},

		_mergeAndSortArrays(monthsArray, yearsArray, sortBy) {
			const mergedArray = [...monthsArray, ...yearsArray];
			mergedArray.sort((a, b) => new Date(a[sortBy]) - new Date(b[sortBy]));
			return mergedArray;
		},

		_getMonthName(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString('default', { month: 'long' });
		},

		_getMonthShortName(dateString) {
			const date = new Date(dateString);
			return date.toLocaleString('default', { month: 'short' });
		},

		_getYear(dateString) {
			const date = new Date(dateString);
			return date.getFullYear();
		},

		_inputControl_GoNext(onFinished = null, before = null) {
			this.validationText = '';
			var allOK = true;
			if (before) {
				if (!before()){
					return;
				}
			}
			for (const field of this.sections[this.sectionNo].fields) {
				if (field.required && (!field.value || field.value === "")) {
					this.validationText = field.validationText;
					allOK = false;
					break;
				}
			}
			if (allOK) {
				var allowed = false;
				this.breadCrumb.push({ sectionNo: this.sectionNo });
				while (!allowed)
				{
					this.validationText = null;
					this.sectionNo++;
					if (!this.sections[this.sectionNo]) {
						if (onFinished) {
							onFinished();
							return;
						}
					}
					else {
						allowed = this._sectionAllowed(this.sections[this.sectionNo]);
					}
				}
				this.currentPageNumber++;
				this.$refs.inputSections.next(this.sectionNo);
			}
		},

		_inputControl_GoBack() {
			var breadCrumb = this.breadCrumb.pop();
			this.sectionNo = breadCrumb.sectionNo;
			this.validationText = null;
			this.currentPageNumber--;
			this.$refs.inputSections.back(this.sectionNo);
		},

		_sectionAllowed(section) {
			if (!section.rule) {
				return true;
			}
			const [ruleKey, ruleValue] = section.rule.split(':').map(s => s.trim());
			const ruleValues = ruleValue.split(',').map(s => s.trim());
			for (let i = 0; i < this.sectionNo; i++) {
				const section = this.sections[i];
				for (const field of section.fields) {
					if (field.id === ruleKey && ruleValues.includes(field.value)) {
						return true;
					}
				}
			}
			return false;
		},

		_preValidate() {
			this.validationText = null;
			const now = new Date();

			this.sections[this.sectionNo].fields.forEach(field => {
				if (field.type === 'datetext' && field.value) {
					const [day, month, year] = field.value.split('/');
					const date = new Date(year, month - 1, day);

					if (field.future && date < now) {
						this.validationText = `${field.label} must be in the future`;
						return false;
					}

					if (field.past && date > now) {
						this.validationText = `${field.label} must be in the past`;
						return false;
					}
				}
			});

			return !this.validationText;
		},

		_stringToDate(dateString) {
			if (!dateString) {
				return null;
			}
			const [day, month, year] = dateString.split('/');
			const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
			return formattedDate;
		},

		_dateToString(dateString) {
			const date = new Date(dateString);
			const year = date.getFullYear();
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const day = String(date.getDate()).padStart(2, '0');
			return `${day}/${month}/${year}`;
		},

		_findFieldValue(fieldName) {
			for (const section of this.sections) {
				for (const field of section.fields) {
					if (field.label === fieldName && field.value != null) {
						return field.value;
					}
				}
			}
			return null;
		},

		_setFieldValue(fieldName, value) {
			for (const section of this.sections) {
				for (const field of section.fields) {
					if (field.label === fieldName) {
						field.value = value;
					}
				}
			}
		},

		_findField(fieldName) {
			for (const section of this.sections) {
				for (const field of section.fields) {
					if (field.label === fieldName) {
						return field;
					}
				}
			}
			return null;
		},

		_removeNullProperties(obj) {
			Object.keys(obj).forEach(key => obj[key] == null && delete obj[key]);
			return obj;
		},
		
		_loadFieldsFromObject(fieldMappings) {
			Object.keys(fieldMappings).forEach(fieldName => {
				this.sections.forEach(section => {
					section.fields.forEach(field => {
						if (field.label === fieldName) {
							field.value = fieldMappings[fieldName];
						}
					});
				});
			});
		},

		_daysBetweenDates(date1, date2) {
			const oneDay = 24 * 60 * 60 * 1000;
			const firstDate = new Date(date1);
			const secondDate = new Date(date2);
			return Math.round((secondDate - firstDate) / oneDay);
		},

		_preventNegative: function(event) {
			if (event.key === '-' || event.key === 'e') {
				event.preventDefault();
			}
		}

	}
}