<template>
	<header-block title="Add pension details" bgcolor="white" border="0"></header-block>
	<CustomerLoadWait :customer="customer && policyTypes && investmentProviders"/>
	<div class="progress-container">
		<v-progress-linear :model-value="progressValue" rounded></v-progress-linear>
	</div>
	<transition>
		<div v-if="customer && policyTypes && investmentProviders && (investmentAccount || !this.$route.params.id)">
			<!-- {{ sectionsAvailableCount(sections[0].fields[0].value) }} -->
			<p class="centered small">Don't forget you can whatsapp us your documents and we will add you investments for you!</p>
			<div class="personalDetails">
				<InputSections ref="inputSections" :sections="sections" theme="light"/>
				<div v-if="validationText" class="well">
					<p>{{ validationText }}</p>
				</div>

				<div class="anchor-bottom submitControls">
					<v-row>
						<v-col cols="5" v-if="currentPageNumber > 0"><MGButton small="true" :outline="true" @click="_inputControl_GoBack()" text="Back" /></v-col>
						<v-col><MGButton :small="currentPageNumber > 0" :alt="true" text="Next" :arrow="true" @click="_inputControl_GoNext(addInvestment, _preValidate)"/></v-col>
					</v-row>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Helpers from '../mixins/Helpers';
import Services from '../mixins/Services';
import AddPensionPageSections from './AddPensionPageSections';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services, Helpers, AddPensionPageSections],
	components: {HeaderBlock, InputSections, MGButton, CustomerLoadWait},
	data() {
		return {
			validationText: null,
			sectionNo: 0,
			currentPageNumber: 0,
			enableWhatsApp: false,
			allowBack: true,
		}
	},
	computed: {	
		progressValue() {
			var insuranceType = this._findFieldValue('Type');
			var totalPages = this.sectionsAvailableCount(insuranceType);
			if (totalPages === 0) return 0;
			return ((this.currentPageNumber + 1) / totalPages) * 100;
		},
	},
	methods: {
		onLoad: async function() {
			if (this.$route.params.id) {
				console.log('onLoad', this.$route.params.id);
				this._getInvestmentAccount(this.$route.params.id, (policy) => {
					this.populateFields(policy);
				});
			}
			this._getCustomer();
			this._getInvestmentProviders((res) => {
				var providerField = this._findField('Provider');
				providerField.items = res.map(provider => {
					return provider.name.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
			this._getInvestmentTypes((res) => {
				res = res.filter(item => item.name === "Pension");
				var typeField = this._findField('Type');
				typeField.items = res.map(investmentType => {
					return investmentType.subType.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
		},
		populateFields(policy) {
			console.log('populateFields', policy);
			const fieldMappings = {
				'Type':  policy.accountType.subType,
				'Provider': policy.provider.name,
				'Account number': policy.accountNumber,
				'Estimated Value': policy.balance,
				'Personal monthly contribution': policy.personalMonthlyContribution,
				'Employer': policy.employer,
				'Retirement age': policy.retirementAge,
				'Current employer scheme': policy.currentEmployerScheme,
			};

			this._loadFieldsFromObject(fieldMappings);
		},
		addInvestment() {
			console.log('addInvestment');
			const accountType = {
				name: "Pension",
				subType: this._findFieldValue('Type') || null,
			};
			const provider = {
				name: this._findFieldValue('Provider') || null,
			};

			const pension = ({
				accountType: accountType,
				accountNumber: this._findFieldValue('Account number') || null,
				provider: provider,
				balance: this._findFieldValue('Estimated Value') || null,
				personalMonthlyContribution: this._findFieldValue('Personal monthly contribution') || null,
				employer: this._findFieldValue('Employer') || null,
				retirementAge: this._findFieldValue('Retirement age') || null,
				currentEmployerScheme: !!this._findFieldValue('Current employer scheme'),
			});

			console.log('pension', pension);

			this._removeNullProperties(pension);

			if (this.$route.params.id) {
				pension.id = this.$route.params.id;
			}

			const handleResponse = (res) => {
				if (res) {
					if (this.$route.params.id) {
						this._getInvestmentAccount(this.$route.params.id, /*onSuccess*/ null, /*refreshCache*/ true);
					}
					this._getInvestmentInfo(/*onSuccess*/ null, /*refreshCache*/ true);
					this.$router.push({ path: '/policysuccess', query: { type: 'pension' } });
				}
			};

			if (!this.$route.params.id) {
				this._addInvestment(pension, handleResponse);
			} else {
				this._updateInvestment(pension, handleResponse);
			}
		},
		sectionsAvailableCount(insuranceType) {
			var sectionsNoRule = this.sections.filter(section => !section.rule);
			var sectionsMatchingRule = this.sections.filter(section => section.rule && section.rule.includes(insuranceType));
			return sectionsNoRule.length + sectionsMatchingRule.length;
		},
	},
	
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>


:deep(.check .v-label) {
	color: white !important;
}

.well {
	text-align: center;
	background-color: #3328470D;
}

.submitControls {
	position: absolute;
	bottom:20px;
	width: calc(100vw - 60px);
}

.progress-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

:deep(.v-progress-linear__background) {
  background-color: var(--border-color) !important;
}
</style>
