export default {

	data() {
		return {
			sections: 
			[
				{
					fields: [
						{
							id: 'policyType',
							validationText: 'Please choose an insurance type',
							label: 'Policy type',
							type: 'dropdown',
							items: this.policyTypes,
							value: null,
							required: true
						}
					]
				},
				{
					id: 'policyDetails',
					fields: [
						{
							validationText: 'Please select a provider',
							label: 'Provider',
							type: 'dropdown',
							items: this.insuranceProviders,
							value: null,
							required: true
						},
						{
							label: 'Policy number',
							type: 'text',
							value: null,
							required: false
						},
						{
							validationText: 'Please enter a start date',
							label: 'Start date',
							type: 'datetext',
							value: null,
							required: true,
							past: true
						},
						{
							validationText: 'Please enter a renewal date',
							label: 'Renewal date',
							type: 'datetext',
							value: null,
							required: true,
							future: true
						}
					]
				},
				{
					fields: [
						{
							validationText: 'Please select how you pay',
							label: 'How do you pay',
							type: 'select',
							items: ['Annually', 'Monthly'],
							value: null,
							required: true
						},
						{
							label: 'Premium',
							type: 'currency',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Contents, Buildings, Buildings & Contents',
					fields: [
						{
							label: 'Excess',
							type: 'currency',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Contents, Buildings, Buildings & Contents',
					fields: [
						{
							label: 'Address',
							type: 'address',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Contents, Buildings, Buildings & Contents',
					fields: [
						{
							label: 'Value insured',
							type: 'currency',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Critical Illness, Life',
					fields: [
						{
							label: 'Sole or joint',
							type: 'select',
							items: ['Sole', 'Joint'],
							required: false
						},
						{
							label: 'Sum assured',
							type: 'currency',
							value: null,
							required: false
						},
						{
							label: 'Cover type',
							type: 'radio',
							items: ['Level', 'Decreasing'],
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Income Protection',
					fields: [
						{
							label: 'Sum assured',
							type: 'currency',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Critical Illness, Income Protection, Life, Life & Critical Illness, Medical, Other',
					fields: [
						{
							label: 'Name',
							type: 'text',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Pet',
					fields: [
						{
							label: 'Name',
							type: 'text',
							value: null,
							required: false
						},
						{
							label: 'Breed',
							type: 'text',
							value: null,
							required: false
						},
						{
							label: 'Date of birth',
							type: 'datetext',
							value: null,
							required: false,
							past: true
						}
					]
				},
				{
					rule: 'policyType: Phone',
					fields: [
						{
							label: 'Name',
							type: 'text',
							value: null,
							required: false
						},
						{
							label: 'Device Type',
							type: 'text',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Travel',
					fields: [
						{
							label: 'Type',
							type: 'radio',
							items: ['Single Trip', 'Annual Cover'],
							value: null,
							required: true
						},
						{
							label: 'Coverage',
							type: 'radio',
							items: ['Business', 'Personal'],
							value: null,
							required: true
						}
					]
				},
				{
					rule: 'policyType: Vehicle',
					fields: [
						{
							label: 'Excess',
							type: 'currency',
							value: null,
							required: false
						},
						{
							label: 'No claims (years)',
							type: 'number',
							value: null,
							required: false
						}
					]
				},
				{
					rule: 'policyType: Vehicle',
					fields: [
						{
							label: 'Make',
							type: 'text',
							value: null,
							required: false
						},
						{
							label: 'Model',
							type: 'text',
							value: null,
							required: false
						},
						{
							label: 'Registration',
							type: 'text',
							value: null,
							required: false
						}
					]
				}
			]
		}
	},
}