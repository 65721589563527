<template>
	<header-block :title="insuranceHeader" bgcolor="white" border="0"></header-block>
	<CustomerLoadWait :customer="customer && policyTypes"/>
	<div class="progress-container">
		<v-progress-linear :model-value="progressValue" rounded></v-progress-linear>
	</div>		

	<transition>
		<div v-if="customer && policyTypes && (policy || !this.$route.params.id) ">
			<p class="centered small">Don't forget you can whatsapp us your documents and we will add you policies for you!</p>
			<div class="personalDetails">
				<InputSections ref="inputSections" :sections="sections" theme="light"/>
				<div v-if="validationText" class="well">
					<p>{{ validationText }}</p>
				</div>
				<div class="anchor-bottom submitControls">
					<v-row>
						<v-col cols="5" v-if="currentPageNumber > 0"><MGButton small="true" :outline="true" @click="_inputControl_GoBack()" text="Back" /></v-col>
						<v-col><MGButton :small="currentPageNumber > 0" :alt="true" text="Next" :arrow="true" @click="_inputControl_GoNext(addPolicy, _preValidate)"/></v-col>
					</v-row>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import Helpers from '../mixins/Helpers';
import Services from '../mixins/Services';
import AddInsurancePageSections from './AddInsurancePageSections';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import InputSections from './elements/InputSections.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services, Helpers, AddInsurancePageSections],
	components: {HeaderBlock, InputSections, MGButton, CustomerLoadWait},
	data() {
		return {
			validationText: null,
			sectionNo: 0,
			currentPageNumber: 0
		}
	},
	computed: {
		insuranceHeader() {
			if (!this.sections[0].fields[0].value) {
				return " Insurance details";
			}
			var title = " " + this.sections[0].fields[0].value + " Insurance details";
			title=(this.$route.params.id ? 'Edit' : 'Add') + title;
			return title;
		},
		progressValue() {
			var insuranceType = this._findFieldValue('Policy type');
			var totalPages = this.sectionsAvailableCount(insuranceType);
			if (totalPages === 0) return 0;
			return ((this.currentPageNumber + 1) / totalPages) * 100;
		},
	},
	methods: {
		onLoad: async function() {
			if (this.$route.params.id) {
				this._getPolicy(this.$route.params.id, (policy) => {
					this.policy = policy;
					this.populateFields();
				});
			}
			this._getCustomer();
			this._getInsuranceProviders((res) => {
				var providerField = this._findField('Provider');
				providerField.items = res.map(provider => {
					return provider.name.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
			this._getPolicyTypes((res) => {
				var policyTypeField = this._findField('Policy type');
				policyTypeField.items = res.map(policyType => {
					return policyType.name.replace(/\w\S*/g, (txt) => {
						return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
					});
				});
			});
		},
		populateFields() {
			const fieldMappings = {
				'Policy type': this.policy.policyType.name,
				'Provider': this.policy.provider.name,
				'Policy number': this.policy.policyNumber,
				'Start date': this._dateToString(this.policy.startDate),
				'Renewal date': this._dateToString(this.policy.endDate),
				'Premium': this.policy.annualPremium || this.policy.monthlyPremium,
				'Excess': this.policy.excess,
				'No claims (years)': this.policy.noClaimsYears,
				'Sole or joint': this.policy.soleOrJoint,
				'Sum assured': this.policy.sumAssured,
				'Cover type': this.policy.coverType,
				'Type': this.policy.travelCoverType,
				'Coverage': this.policy.travelCoverage,
				'How do you pay': this.policy.annualPremium ? 'Annually' : 'Monthly',
				'Address': this.policy.insuredItems[0].addressInsured,
				'Make': this.policy.insuredItems[0].vehicleMake,
				'Model': this.policy.insuredItems[0].vehicleModel,
				'Registration': this.policy.insuredItems[0].vehicleRegistration,
				'Name': this.policy.insuredItems[0].name,
				'Value insured': this.policy.insuredItems[0].valueInsured,
				'Breed': this.policy.insuredItems[0].petBreed,
				'Date of birth': this._dateToString(this.policy.insuredItems[0].petBirthdate),
				'Device Type': this.policy.insuredItems[0].itemType
			};

			this._loadFieldsFromObject(fieldMappings);
		},
		addPolicy() {
			const policyType = {
				name: this._findFieldValue('Policy type') || null,
			};
			const provider = {
				name: this._findFieldValue('Provider') || null,
			};
			var addressObject = this._findFieldValue('Address') || null;
			const addressInsured = {
				line1: addressObject?.line1 || null,
				line2: addressObject?.line2 || null,
				line3: addressObject?.line3 || null,
				town: addressObject?.town || null,
				postCode: addressObject?.postCode || null,
			};
			const insuredItems = [];
			const insuredItem = ({
				itemType: this._findFieldValue('Policy type') || null,
				addressInsured: addressInsured || null,
				name: this._findFieldValue('Name') || null,
				petBreed: this._findFieldValue('Breed') || null,
				petBirthDate: this._stringToDate(this._findFieldValue('Date of birth')) || null,
				vehicleMake: this._findFieldValue('Make') || null,
				vehicleModel: this._findFieldValue('Model') || null,
				vehicleRegistration: this._findFieldValue('Registration') || null,
				valueInsured: this._findFieldValue('Value insured') || 0,
			});
			insuredItems.push(insuredItem);
			const policy = ({
				policyType: policyType,
				policyNumber: this._findFieldValue('Policy number') || null,
				provider: provider,
				insuredItems: insuredItems,
				payFrequency: this._findFieldValue('How do you pay') || null,
				startDate: this._stringToDate(this._findFieldValue('Start date')) || null,
				endDate: this._stringToDate(this._findFieldValue('Renewal date')) || null,
				annualPremium: this._findFieldValue('How do you pay') === 'Annually' ? this._findFieldValue('Premium') : null,
				monthlyPremium: this._findFieldValue('How do you pay') === 'Monthly' ? this._findFieldValue('Premium') : null,
				excess: this._findFieldValue('Excess') || null,
				noClaimsYears: this._findFieldValue('No claims (years)') || null,
				soleOrJoint: this._findFieldValue('Sole or joint') || null,
				sumAssured: this._findFieldValue('Sum assured') || null,
				coverType: this._findFieldValue('Cover type') || null,
				travelCoverType: this._findFieldValue('Type') || null,
				travelCoverage: this._findFieldValue('Coverage') || null,
			});

			if (this.$route.params.id) {
				policy.id = this.$route.params.id;
			}

			const handleResponse = (res) => {
				if (res) {
					if (this.$route.params.id) {
						this._getPolicy(this.$route.params.id, /*onSuccess*/ null, /*refreshCache*/ true);
					}
					this._getInsuranceInfo(/*onSuccess*/ null, /*refreshCache*/ true);
					this.$router.push({ path: '/policysuccess', query: { type: 'investment' } });
				}
			};

			if (!this.$route.params.id) {
				this._addPolicy(policy, handleResponse);
			} else {
				this._updatePolicy(policy, handleResponse);
			}
		},
		sectionsAvailableCount: function(insuranceType) {
			var sectionsNoRule = this.sections.filter(section => !section.rule);
			var sectionsMatchingRule = this.sections.filter(section => section.rule && section.rule.includes(insuranceType));
			return sectionsNoRule.length + sectionsMatchingRule.length;
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>


:deep(.check .v-label) {
	color: white !important;
}

.well {
	text-align: center;
	background-color: #3328470D;
}

.progress-container {
	display: flex;
	justify-content: center;
	margin: 20px 0;
}

:deep(.v-progress-linear__background) {
  background-color: var(--border-color) !important;
}

</style>
