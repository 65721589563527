<template>
	<BarArrowButton :large="true" :title="title" :href="href">
		<div class="barArrowButtonSlotContent">
			<v-row  v-if="goal && goal > 0" class="mt-1">
				<v-col class="progressCol">
					<svg style="width: 0; height: 0; position: absolute;" aria-hidden="true" focusable="false">
						<linearGradient id="progress-gradient" gradientTransform="rotate(215.75)">
							<stop offset="3.1%" />
							<stop offset="95.08%" />
						</linearGradient>
					</svg>
					<v-progress-circular width="4" :model-value="goalProgressPercent" :size="small ? 100 : 120" bg-color="#f7faf7" color="#61678D" class="progress-circle">
						<div class="progress-content">
							<div class="goal">{{formatter.format(goal)}}</div>
							<div class="saved">/{{ formatter.format(saved) }}</div>
						</div>
					</v-progress-circular>
					<p class="mt-2" v-if="instalment">{{ formatter.format(instalment) }} a month</p>
				</v-col>
			</v-row>
		</div>
	</BarArrowButton>
</template>

<script>
import BarArrowButton from './BarArrowButton.vue';

export default {
	name: "GoalInfoPanel",
	props: ['title','href','goal', 'saved', 'instalment', 'formatter', 'small' ],
	components: {BarArrowButton},
	data() {
		return{
			animatedProgress: 0,
		};
	},
	computed: {
		goalProgressPercent() {
			return Math.min(100, Math.max(0, Math.round((this.saved / this.goal) * 100)));
		},
	},
	methods: {
		onLoad() {
			this.animateProgress(this.goalProgressPercent);
		},
		animateProgress(targetValue) {
			this.animatedProgress = 0;
			const increment = targetValue / 50;
			const interval = setInterval(() => {
				if (this.animatedProgress < targetValue) {
					this.animatedProgress += increment;
				} else {
					this.animatedProgress = targetValue;
					clearInterval(interval);
				}
			}, 10);
		},
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

:deep(.v-progress-circular__overlay) {
	stroke: url(#progress-gradient);
}

:deep(#progress-gradient stop:first-child) {
	stop-color: #9EA2D0;
}

:deep(#progress-gradient stop:last-child) {
	stop-color: #61678D;
}

.progressCol {
	padding-top: 0px;
	padding-bottom: 0px;
	text-align: center;
}

.barArrowButtonSlotContent {
	padding: 20px;
	padding-top: 15px;
}

.progress-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.goal {
	font-size: 18px;
	font-weight: bold;
	text-align: center;
}

.saved {
	font-size: 12px;
	text-align: center;
}

</style>