<template>
	
		<HeaderBlock bgcolor="#00000000" border="0" />
	
		<div class="simplePage">
			<img class="heroImg" src="../assets/Frame.png"/>
			<h1>The first step in your financial journey</h1>
			<p>Taking control and organising your finances can be daunting, but it's a great first step towards financial stability.</p>

			<p></p>
			<div class="anchor-bottom">
				<MGButton :alt="true" text="continue" :arrow="true" @click="startSurvey"/>
			</div>
		</div>
	
</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, MGButton},
	data() {
		return {
			
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
		},
		startSurvey: function() {
			this._getQuestionnaireByKey('OB_FINAN', (q) => {
				console.log(q, 'Found this questionnaire');
				this.$router.push({path: '/Questionnaire/' + q.id, query: {theme: 'dark', allowBack: false}});
			});	
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>
.simplePage {
	color: white;
}

</style>
