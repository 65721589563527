<template>
	<header-block title="Transaction" class="title"></header-block>
	<CustomerLoadWait :customer="((transaction && bankAccount) || transaction) && classificationOptions" :cardBlocks="true" :withTopCard="true" />

	<div v-if="transaction && bankAccount && classificationOptions && classifications">
		<div class="transactionHeader">
			<span>{{ transaction.merchantName }}</span>
			<p>
				<span v-if="transaction.amount < 0">
					{{ formatter.format(transaction.amount * -1) }}
				</span>
				<span v-else>
					{{ formatter.format(transaction.amount) }}
				</span>
			</p> 
		</div>
			<div v-if="selectedClassificationId" class="centered">
				<MGButton @click="openDrawer()" 
					:text="_getClassificationNameByClassiciationId(selectedClassificationId)" 
					:arrow="false" 
					:open="true"
					:icon="_getIconByClassiciationId(selectedClassificationId) ? require('@/assets/transactions/' + _getIconByClassiciationId(selectedClassificationId).toLowerCase().replace('.svg', '.png')) : null"
					>
				</MGButton>
			</div>	
		<div style="clear:both; margin-bottom: 30px;"></div>
	</div>
	<div v-if="wellData">
		<WellPanel :data="wellData"/>
	</div>
	<DrawerPanel v-if="transaction && transactionClassifications" 
		ref="drawer" 
		openHeight="90%"
		bottom="0px" title="Tag Transaction" backgroundColor="white" 
		openBackgroundColor="white" 
	>
		<TransactionTagging v-if="suggestedClassificationReady && selectedClassificationId" :transaction="transaction" :categories="transactionClassifications" :currentSelectionId="selectedClassificationId"
				:suggestedClassification="suggestedClassification"
				@tag="onTagUpdated" @chosen="_scrollToBottom" />
	</DrawerPanel>
</template>

<script>

import Services from '../mixins/Services';
import SpendingAnalysis from '../mixins/SpendingAnalysis';
import Helpers from '../mixins/Helpers';
import Transaction from '../mixins/Transaction';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';
import HeaderBlock from './elements/HeaderBlock.vue';
import WellPanel from './elements/WellPanel.vue';
import DrawerPanel from './elements/DrawerPanel.vue';
import TransactionTagging from './elements/TransactionTagging.vue';
import MGButton from './elements/MGButton.vue';

export default {
	// props: ['formatter'],
	mixins: [Services, Helpers, SpendingAnalysis, Transaction],
	components: { CustomerLoadWait, HeaderBlock, WellPanel, DrawerPanel, TransactionTagging, MGButton },
	data() {
		return {
			classificationOptions: [],
			classifications: [],
			selectedClassificationId: null,
			selectedClassification: null,
			selectedTransactionGroup: [],
			suggestedClassificationReady: false,
			wellData: null,
			groupSize: null,
		}
	},
	methods: {
		onLoad: async function() {
			this.fetchClassificationsAndTransactions();
		},
		fetchClassificationsAndTransactions() {
			this._getTransactionClassifications((result) => {
				this.classifications = result;
				this.classificationOptions = result.map(c => ({id: c.mgClassification.id, title: c.mgClassification.fullName}));
				this.getTransaction(this.$route.params.id);
			});
		},
		getTransaction: function(id){
			this._getTransaction(id, () => {
				if (this.transaction && (this.transaction.effectiveClassification === null || this.transaction.effectiveClassification.mgClassification === "Uncategorised" || this.transaction.effectiveClassification.mgClassification === "Uncategorized") && this.transaction.merchantName) {
					this.suggestedClassificationReady = false;

					this._getSuggestedClassification(this.transaction.merchantName, () => {
						this.suggestedClassificationReady = true;
					});
				}
				else {
					this.suggestedClassificationReady = true;
				}
				this._getBankAccount(this.transaction.bankAccountID, () => {
					this.selectedClassificationId = this.transaction.effectiveClassification.id
					this.selectedClassificationName = this.transaction.effectiveClassification.mgSubClassification;
					var temp = [
						{order: 1, label: 'Date', value: this.transaction.timestampFormatted},
						{order: 3, label: 'Account', value: this.bankAccount.bank.name},
						{order: 4, label: 'Transaction', value: this.transaction.type === 'DEBIT' ? 'Money Out' : 'Money In'},
						{order: 5, label: 'Spending Analysis', value: this.transaction.excludeFromAnalysis ? 'Excluded' : 'Included'},
						{order: 6, label: 'Description', value: this.transaction.description},
					];
					if (this.transaction.timestamp.split('T')[1].split('.')[0] != "00:00:00")
					{
						temp.push({order: 2, label: 'Time', value: this.transaction.timestamp.split('T')[1].split('.')[0]});
					}
					this.wellData = temp;
				}, true);
			})
		},

		updateClassification: function(transaction) {
			if (transaction.alwaysApplyTag || transaction.alwaysApplyRule) {
				this._createRule(transaction);
			}
			else {
				// update single transcation
				this._updateSingleTransaction(transaction);
			}
		},

		refresh: function() {
			this.getTransaction(this.transaction.id);
			this._getBankAccount(this.transaction.bankAccountID, null, /* lite: */ false, /*refreshCache: */ true); //Force cache refresh of bank transactions to reflect new classification
			this._getSpendingAnalysis(null, /*refreshCache: */ false, /* clearCache */ true);	
			this._clearBankingInfo();
		},

		onTagUpdated: function(transaction) {
			this.$refs.drawer.closeDrawer();
			this._onTagUpdated(transaction);
		},

		openDrawer: function() {
			this.$refs.drawer.openDrawer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}

</script>

<style scoped>


.transactionHeader {
	color: var(--text-color);
	font-size: 20px;
	text-align: center;
	padding-top: 30px;
	font-weight: bold;
	p{
		font-size: 20px;
		font-family: 'Gotham-Heavy';
		margin-top: 0px;
	};
	.small {
		font-size: 10px;
		margin-top: -40px;
	}
}

.title {
	font-size: 14px;
}


</style>
