<template>
	
		<HeaderBlock bgcolor="#00000000" border="0" />
	
		<div class="simplePage" v-if="!about">
			<h1>See your spending in a whole new way</h1>
			<img class="largeHeroImg" src="../assets/SpendingAnalysisImage.png"/>

			<p>Get insights into your daily spending and curate a personalised spending plan.</p>

			<p></p>
			<div class="anchor-bottom">
				<MGButton :alt="false" text="continue" :arrow="true" @click="finish"/>
			</div>
		</div>
	
</template>

<script>
import Services from '../mixins/Services';
import HeaderBlock from './elements/HeaderBlock.vue';
import MGButton from './elements/MGButton.vue';

export default {
	mixins: [Services],
	components: {HeaderBlock, MGButton},
	data() {
		return {
			about: false
		}
	},
	methods: {
		onLoad: async function() {
			this._getCustomer();
		},
		finish: function() {
			this.$router.replace({path: '/linkopenbankingaccount'});
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>



</style>
